import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENTO_MARKETING_PRELIMINAL } from '../model/Mongo';
import { Respuesta } from '../model/Indesad';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WMSService {

  constructor(   
    private _http: HttpClient,
    private _cookieService: CookieService,
    ) { }


  async GetPicking(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetPickingAll', body).toPromise();
  }
  
  async AddPicking(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetPickingAll', body).toPromise();
  }

  async GetGrupo(grupo) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: {
        grupo:grupo
      }
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetGrupo', body).toPromise();
  }
  
  async AgregarEditarGrupos(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/AgregarEditarGrupos', body).toPromise();
  }


  
  async GenerarDespacho(DocEntry) {
    const body =
    {
      estado: true,
      TOKEN: environment.TOKEN,
      objeto: {
          DocEntry:DocEntry          
      }
    }
    
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GenerarDespacho', body).toPromise();
  }

  async CambiarEstado(resultado) {
    const body =
    {
      "estado": true,
      "mensaje": "",
      "TOKEN": "INDESAD",
      "resultado": {
              "DocEntry": resultado.DocEntry,
              "ObjType": resultado.ObjType,
              "estado": resultado.estado
          }
  }
    
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/CambiarEstado', body).toPromise();
  }

  async generaDocumentoDesdePicking(objeto) {
    const body =
    {
      "estado": true,
      "mensaje": "",
      "TOKEN": "INDESAD",
      "resultado": {
              "DocEntry": objeto.DocEntry,
              "ObjType": objeto.ObjType,
              "ObjTypeDestino": objeto.ObjTypeDestino
          }
  }
    
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/generaDocumentoDesdePicking', body).toPromise();
  }
  

  async AsignarResponsable(data) {
    const body =
    {
      "estado": true,
      "mensaje": "",
      "TOKEN": "INDESAD",
      "resultado": data
  }
  
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/AsignarResponsable', body).toPromise();
  }

  async AbrirPicking(data) {
    const body =
    {
      "estado": true,
      "mensaje": "",
      "TOKEN": "INDESAD",
      "resultado": data
  }
  
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/abrirPicking', body).toPromise();
  }

  async actualizaPicking(data) {
    const body =
    {
      "estado": true,
      "mensaje": "",
      "TOKEN": "INDESAD",
      "resultado": data
  }
  
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/actualizaPicking', body).toPromise();
  }


  async calculaDatoPickeado(data:any)
  {
    
    data.DETALLLE.forEach(row => {
      //setea valores en 0
      row.LOTE = [];
      row.SERIE = [];
      row.UBICACION = [];
      
      var CantidadPickeada = 0;
      //sin ubicacion sin lote sin serie
      if (!row.BinLocationActive && !row.ManBtchNum && !row.ManSerNum) {
        row.Quantity = row.Original;
        row.Quantity = row.Quantity - row.Pickeada;
        CantidadPickeada = row.Pickeada;
      }
      //cuando no tenga ubicacion pero si serie o lote
      if (!row.BinLocationActive && (row.ManBtchNum || row.ManSerNum)) {
        //lote
        if (row.ManBtchNum) {
          row.LOTE_OnHand.forEach(item => {
            item.Quantity = item.Original;
            if (item.Pickeada > 0) {
              item.Quantity = item.Quantity - item.Pickeada;
              CantidadPickeada = CantidadPickeada + item.Pickeada;
              var copiaLoteSerie  = {...item};
              row.LOTE.push(copiaLoteSerie);
            }
          });
        }
        //serie
        if (row.ManSerNum) {
       
          row.SERIE_OnHand.forEach(item => {
            item.Quantity = item.Original;
            item.Pickeada = 0;
            if (item.Checked) {
              CantidadPickeada = CantidadPickeada + 1;
              item.Quantity = 0;
              item.Pickeada = 1;
              var copiaLoteSerie  = {...item};
              row.SERIE.push(copiaLoteSerie);
            }
          });

          row.Pickeada = CantidadPickeada;
        }
        
      }

      if (row.BinLocationActive) {

        //sin lote ni serie
      
          row.UBICACION_OnHand.forEach(item => {
            item.Quantity = item.Original;
            if (item.Pickeada > 0) {
              CantidadPickeada = CantidadPickeada + item.Pickeada;
              item.Quantity =  item.Quantity - item.Pickeada;
              var copiUbicacion  = {...item};
              copiUbicacion.LOTE = [];
              copiUbicacion.SERIE = [];


              if (row.ManBtchNum) {
                item.LOTE.forEach(itemLoteSerie => {
                  itemLoteSerie.Quantity = itemLoteSerie.Original;
                  if (itemLoteSerie.Pickeada > 0) {
                    itemLoteSerie.Quantity = itemLoteSerie.Quantity - itemLoteSerie.Pickeada;
                    CantidadPickeada = CantidadPickeada + itemLoteSerie.Pickeada;
                    var copiaLoteSerie  = {...itemLoteSerie};
                  
                    copiUbicacion.LOTE.push(copiaLoteSerie);
                  }
                });
              }

              if (row.ManSerNum) {
                item.SERIE.forEach(itemLoteSerie => {
                  itemLoteSerie.Quantity = 1;
                  if (itemLoteSerie.Checked) {
                    itemLoteSerie.Quantity = 0;
                    itemLoteSerie.Pickeada = 1;
           
      
                    var copiaLoteSerie  = {...itemLoteSerie};
                  
                    copiUbicacion.SERIE.push(copiaLoteSerie);
                  }
                });
              }

              row.UBICACION.push(copiUbicacion);
            }
          });
        
        
      }


      row.Pickeada = CantidadPickeada;
    });
    
    return data;
  }
  async calculaDatoPickeadoDetalle(data:any)
  {
    

    //setea valores en 0
    data.LOTE = [];
    data.SERIE = [];
    data.UBICACION = [];
    
    var CantidadPickeada = 0;
    //sin ubicacion sin lote sin serie
    if (!data.BinLocationActive && !data.ManBtchNum && !data.ManSerNum) {
      //data.Quantity = data.Original;
      data.Quantity = data.OnHand - data.Pickeada;
      CantidadPickeada = data.Pickeada;
    }
    //cuando no tenga ubicacion pero si serie o lote
    if (!data.BinLocationActive && (data.ManBtchNum || data.ManSerNum)) {
      //lote
      if (data.ManBtchNum) {
        data.LOTE_OnHand.forEach(item => {
          item.Quantity = item.Original;
          if (item.Pickeada > 0) {
            item.Quantity = item.Quantity - item.Pickeada;
            CantidadPickeada = CantidadPickeada + item.Pickeada;
            var copiaLoteSerie  = {...item};
            data.LOTE.push(copiaLoteSerie);
          }
        });
      }
      //serie
      if (data.ManSerNum) {
      
        data.SERIE_OnHand.forEach(item => {
          item.Quantity = item.Original;
          item.Pickeada = 0;
          if (item.Checked) {
            CantidadPickeada = CantidadPickeada + 1;
            item.Quantity = 0;
            item.Pickeada = 1;
            var copiaLoteSerie  = {...item};
            data.SERIE.push(copiaLoteSerie);
          }
        });

        data.Pickeada = CantidadPickeada;
      }
      
    }

    if (data.BinLocationActive) {

      data.Pickeada = 0;
      data.UBICACION_OnHand.forEach(item => {

        
        item.Quantity = item.Original;
        
        if (!data.ManBtchNum && !data.ManSerNum) {
          CantidadPickeada = CantidadPickeada + item.Pickeada;
         
        }
       


        //item.LOTE = [];
        //item.SERIE = [];
        var copiUbicacion  = {...item};
        copiUbicacion.LOTE = [];
        copiUbicacion.SERIE = [];


        if (data.ManBtchNum) {
          item.Pickeada = 0;
          item.LOTE.forEach(itemLoteSerie => {
            itemLoteSerie.Quantity = itemLoteSerie.Original;
            if (itemLoteSerie.Pickeada > 0) {
              item.Pickeada = item.Pickeada + itemLoteSerie.Pickeada;
              itemLoteSerie.Quantity = itemLoteSerie.Quantity - itemLoteSerie.Pickeada;
              CantidadPickeada = CantidadPickeada + itemLoteSerie.Pickeada;
              var copiaLoteSerie  = {...itemLoteSerie};
            
              copiUbicacion.LOTE.push(copiaLoteSerie);
            }
          });
        }

        if (data.ManSerNum) {
          item.Pickeada = 0;
          item.SERIE.forEach(itemLoteSerie => {
            itemLoteSerie.Quantity = 1;
            item.Pickeada = item.Pickeada + 1;
            if (itemLoteSerie.Checked) {
              itemLoteSerie.Quantity = 0;
              itemLoteSerie.Pickeada = 1;
      

              var copiaLoteSerie  = {...itemLoteSerie};
            
              copiUbicacion.SERIE.push(copiaLoteSerie);
            }
          });
        }

        item.Quantity =  item.Quantity - item.Pickeada;
        if (item.Pickeada > 0) {
          
          copiUbicacion.Pickeada = item.Pickeada;
          data.UBICACION.push(copiUbicacion);
        }
      });
      
      
    }

    data.Quantity = data.OnHand - CantidadPickeada;
    data.Pickeada = CantidadPickeada;

    
    return data;
  }


}
