<div class="cargando-container">
  <div class="row">
    <div class="col-12" style="display: flex;justify-content: center;">
      <img src="assets/LogoTW.png" alt="" srcset="">

    </div>
    <div class="col-12" style="display: flex;justify-content: center;">
      <i class="fa-solid fa-spinner-third fa-spin"></i>

    </div>
  </div>
</div>
