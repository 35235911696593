import { DOCUMENT } from "@angular/common";
import { Component, OnInit, Inject, ViewChild, TemplateRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IndesadService } from "src/app/api/indesad.service";
import { WMSService } from "src/app/api/wms.service";
import { PrepararPedidoComponent } from "../preparar-pedido.component";
import { MatAccordion } from "@angular/material/expansion";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { variableGlobal } from "src/app/global.enum";
import { PistoleoComponent } from "../pistoleo/pistoleo.component";
import { SeleccionarLineaComponent } from "./seleccionar-linea/seleccionar-linea.component";
import { number } from "@amcharts/amcharts4/core";
import { ro } from "date-fns/locale";
import Swal from "sweetalert2";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { SeleccionarUbicacionComponent } from "./seleccionar-ubicacion/seleccionar-ubicacion.component";
import { SeleccionarSerieLoteComponent } from "./seleccionar-serie-lote/seleccionar-serie-lote.component";
import { DetallepickeoComponent } from "../../vista-nv/detallepickeo/detallepickeo.component";
import { DragRef } from "@angular/cdk/drag-drop";
import { MatDrawer } from "@angular/material/sidenav";

@Component({
  selector: "app-picking",
  templateUrl: "./picking.component.html",
  styleUrls: ["./picking.component.css"],
})
export class PickingComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  elem: any;
  fullScreen: boolean = false;
  resultado;
  showFiller = true;
  DocEntry;
  DocNum;
  titulo;
  Detalle;
  InputItemCode;
  InputQty;
  ObjType;
  ESTADO_WMS;
  DataPicking;
  Picking: any;
  WMSaccion;
  TituloAccion;
  mensajeAlerta;
  mostrarAlerta;

  constructor(
    public dialogRef: MatDialogRef<PrepararPedidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiMongo: IndesadService,
    private _apiWMS: WMSService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.mostrarAlerta = false;
    this.WMSaccion = this.data.WMSaccion;

    console.log("WMS ACCIÓN", this.WMSaccion);

    this.elem = document.documentElement;

    //console.log(this.data);
    this.DocEntry = this.data.DocEntry;
    this.DocNum = this.data.DocNum;
    this.ObjType = this.data.ObjType;
    this.ESTADO_WMS = this.data.ESTADO_WMS;
    this.TituloAccion = this.data.WMS;

    console.log("ESTADO WMS", this.ESTADO_WMS);

    //this.openFullscreen();

    this.AbrirPicking();
  }

  //drawer

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  togglerModal()
  {
    this.drawer.toggle();
    this.limpiar();
  }
  async AbrirPicking() {
    let data = {
      DocEntry: this.DocEntry,
      ObjType: this.ObjType,
    };
    this.DataPicking = await this._apiWMS.AbrirPicking(data);
    console.log("DATA PICKING", this.DataPicking);
    this.Detalle = this.DataPicking.resultado.DETALLLE;
    console.log("Detalle", this.Detalle);
  }

  codigoMultiple: any;
  detalleSeleccionado: any;
  ubicacionSeleccionada: any;
  ItemCode: any;
  Ubicacion: any;
  Lote: any;
  Serie: any;
  Cantidad: any = 0;

  async capturaCualquierCodigo() {
    this.detalleSeleccionado = null;
    this.ItemCode = "";
    this.Ubicacion = "";
    this.Lote = "";
    this.Serie = "";
    this.Cantidad = "";

    var seleccionadoEsSerie = false;

    var rows: any = [];
    if (this.codigoMultiple) {
      this.DataPicking.resultado.DETALLLE.forEach((row) => {
        if (row.ItemCode == this.codigoMultiple) {
          this.ItemCode = row.ItemCode;
          rows.push(row);
        } else {
          //validar si es ubicacion, lote, serie
          if (row.BinLocationActive) {
            //ojito que si no es ubicacion podria ser lote o serie

            row.UBICACION_OnHand.forEach((ubicacion) => {
              console.log("ubicacion", ubicacion);
              if (ubicacion.BinCode == this.codigoMultiple) {
                this.ItemCode = row.ItemCode;
                this.Ubicacion = ubicacion.BinCode;
                rows.push(row);
              } else {
                //ver si tiene lote o serie
                if (row.ManBtchNum) {
                  ubicacion.LOTE.forEach((lote) => {
                    if (lote.CodigoLoteSerie == this.codigoMultiple) {
                      this.ItemCode = row.ItemCode;
                      this.Ubicacion = ubicacion.BinCode;
                      this.Lote = lote.CodigoLoteSerie;
                      rows.push(row);
                    }
                  });
                }
                if (row.ManSerNum) {
                  ubicacion.SERIE.forEach((serie) => {
                    if (serie.CodigoLoteSerie == this.codigoMultiple) {
                      this.ItemCode = row.ItemCode;
                      this.Ubicacion = ubicacion.BinCode;
                      this.Serie = serie.CodigoLoteSerie;
                      seleccionadoEsSerie = true;
                      //this.compraValidaLoteSerie('SERIE');
                      rows.push(row);
                    }
                  });
                }
              }
            });
          } else {
            //si no tiene ubicacion validemos si tiene lote o serie

            if (row.ManBtchNum) {
              row.LOTE_OnHand.forEach((lote) => {
                if (lote.CodigoLoteSerie == this.codigoMultiple) {
                  this.ItemCode = row.ItemCode;
                  this.Lote = lote.CodigoLoteSerie;
                  rows.push(row);
                }
              });
            }
            if (row.ManSerNum) {
              row.SERIE_OnHand.forEach((serie) => {
                if (serie.CodigoLoteSerie == this.codigoMultiple) {
                  this.ItemCode = row.ItemCode;
                  this.Serie = serie.CodigoLoteSerie;
                  seleccionadoEsSerie = true;
                  //this.compraValidaLoteSerie('SERIE');
                  rows.push(row);
                }
              });
            }
          }
        }
      });

      if (rows.length > 0) {
        this.codigoMultiple = "";
        //console.log("ROWS considentes", rows);
        if (rows.length == 1) {
          this.detalleSeleccionado = rows[0];
          console.log(this.detalleSeleccionado);

          if (seleccionadoEsSerie) {
            this.compraValidaLoteSerie('SERIE');
          }

        } else {
          let ngbModalOptions: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            modalDialogClass: variableGlobal.CLASS_MODAL_BG,
          };

          const modalRef = this.modalService.open(
            SeleccionarLineaComponent,
            ngbModalOptions
          );
          modalRef.componentInstance.data = rows;
          modalRef.componentInstance.modalReference = modalRef;

          modalRef.result.then(
            (item) => {
              if (item) {
                this.ItemCode = item.ItemCode;
                this.detalleSeleccionado = item;
                console.log(item);
              }
            },
            (reason) => {
              //this.editar(item);
            }
          );
        }
      } else {
        Swal.fire(
          "Error",
          "Código ingresado no tiene relacion con ningun item dentro del picking",
          "error"
        );
      }
    } else {
      //this.limpiar();
    }
  }

  existeLote:boolean = false;
  async compraValidaLoteSerie(loteSerie)
  {
   this.existeLote = false;
    if (this.WMSaccion != "WMSCompra")
    {

      if (loteSerie == "SERIE") {
        this.Cantidad = 1;
        this.pickeaCantidad();
      }
      else{
        //validemos si lote ingresado existe
        var serieLoteExiste = false;
        await this.detalleSeleccionado.UBICACION_OnHand.LOTE.forEach(element => {
          if (element.CodigoLoteSerie == this.Lote) {
            this.existeLote = true;
            serieLoteExiste = true;
          }
        });
        await this.detalleSeleccionado.UBICACION_OnHand.SERIE.forEach(element => {
          if (element.CodigoLoteSerie == this.Serie) {
            serieLoteExiste = true;
          }
        });

        await this.detalleSeleccionado.LOTE_OnHand.forEach(element => {
          if (element.CodigoLoteSerie == this.Lote) {
            this.existeLote = true;
            serieLoteExiste = true;
          }
        });
        await this.detalleSeleccionado.SERIE_OnHand.forEach(element => {
          if (element.CodigoLoteSerie == this.Serie) {
            serieLoteExiste = true;
          }
        });

        if (!serieLoteExiste) {
          if (this.detalleSeleccionado.ManBtchNum) {
            Swal.fire("Lote ingresado no esta en los registros");
            this.Lote = "";
          }
          if (this.detalleSeleccionado.ManSerNum) {
            Swal.fire("Serie ingresada no esta en los registros");
            this.Serie = "";
          }
        }
      
        
      }

      return "";
    }

    var jsonLoteSerie = {
      "Checked": false,
      "SysNumber": 0,
      "CodigoLoteSerie": "",
      "BatchNumber": "",
      "Quantity": 0,
      "Original": 0,
      "Pickeada": 0
  };

    console.log("Crear lote o serie");
    console.log(this.detalleSeleccionado);

    if (loteSerie == "LOTE") {
      jsonLoteSerie.CodigoLoteSerie = this.Lote;
      jsonLoteSerie.BatchNumber = this.Lote;

      if (this.detalleSeleccionado.BinLocationActive) {
        if (this.Ubicacion) {
          await this.detalleSeleccionado.UBICACION_OnHand.forEach(async ubicacion => {
            var existeLote = false;
            ubicacion.LOTE.forEach(element => {
              if (element.CodigoLoteSerie == this.Lote) {
                existeLote = true;
                jsonLoteSerie.Pickeada =  element.Pickeada;
              }
            });

            if (!existeLote) {
              this.detalleSeleccionado.UBICACION_OnHand.LOTE.push(jsonLoteSerie);
            }

          });
        }
        else{
            Swal.fire("Antes de ingresar un lote debe asginar una ubicación valida");
        }
      }
      else{
        var existeLote = false;
        await this.detalleSeleccionado.LOTE_OnHand.forEach(async element => {
          if (element.CodigoLoteSerie == this.Lote) {
            existeLote = true;
            jsonLoteSerie.Pickeada =  element.Pickeada;
          }
        });
        console.log("Paso por aqui!!");
        if (!existeLote) {
          this.detalleSeleccionado.LOTE_OnHand.push(jsonLoteSerie);
        }


      
      }

    }
    else if (loteSerie == "SERIE") {
      if (!this.Serie) {
        
        return "";
      }

      jsonLoteSerie.CodigoLoteSerie = this.Serie;
      jsonLoteSerie.BatchNumber = this.Serie;

      if (this.detalleSeleccionado.BinLocationActive) {
        if (this.Ubicacion) {
          await this.detalleSeleccionado.UBICACION_OnHand.forEach(async ubicacion => {
            var existeLote = false;
            ubicacion.SERIE.forEach(element => {
              if (element.CodigoLoteSerie == this.Serie) {
                existeLote = true;
                element.Checked = !element.Checked;
              }
            });

            if (!existeLote) {
              jsonLoteSerie.Checked = true;
              this.detalleSeleccionado.UBICACION_OnHand.SERIE.push(jsonLoteSerie);
            }


          });
        }
        else{
            Swal.fire("Antes de ingresar un lote debe asginar una ubicación valida");
        }
      }
      else{
        var existeLote = false;
        await this.detalleSeleccionado.SERIE_OnHand.forEach(async element => {
          if (element.CodigoLoteSerie == this.Serie) {
            existeLote = true;
            element.Checked = !element.Checked;
          }
        });

        if (!existeLote) {
          jsonLoteSerie.Checked = true;
          this.detalleSeleccionado.SERIE_OnHand.push(jsonLoteSerie);
        }

      }


      this.pickeaCantidad();
    }


    console.log(this.detalleSeleccionado);
  }

  async checkeaLote() {
    if (this.WMSaccion == "WMSCompra") {
      if (this.detalleSeleccionado.BinLocationActive) {
        if (this.Ubicacion) {
          if (this.detalleSeleccionado.ManBtchNum) {
            let loteExiste = false;

            this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
              if (element.BinCode == this.Ubicacion) {
                element.LOTE.forEach((lote) => {
                  if (lote.CodigoLoteSerie == this.Lote) {
                    loteExiste = true;
                  }
                });

                if (!loteExiste) {
                  let nuevoLote = {
                    Checked: false,
                    SysNumber: 0,
                    CodigoLoteSerie: this.Lote,
                    BatchNumber: this.Lote,
                    Quantity: 0,
                    Original: 0,
                    Pickeada: 0,
                  };
                  element.LOTE.push(nuevoLote);
                  console.log("LOTE CREADO", this.detalleSeleccionado);
                } else {
                  console.log("LOTE EXISTE PO WEON");
                }
              }
            });
          }

          if (this.detalleSeleccionado.ManSerNum) {


            let serieExiste = false;

            this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
              if (element.BinCode == this.Ubicacion) {
                element.SERIE.forEach((lote) => {
                  if (lote.CodigoLoteSerie == this.Serie) {
                    serieExiste = true;
                  }
                });

                if (!serieExiste) {
                  let nuevoLote = {
                    Checked: false,
                    SysNumber: 0,
                    CodigoLoteSerie: this.Serie,
                    BatchNumber: this.Serie,
                    Quantity: 0,
                    Original: 0,
                    Pickeada: 0,
                  };
                  element.SERIE.push(nuevoLote);
                  console.log("SERIE CREADO", this.detalleSeleccionado);
                } else {
                  console.log("SERIE EXISTE PO WEON");
                }
              }
            });

          }
        } else {
          Swal.fire("Mensaje", "Debe colocar una ubicación", "error");
        }
      }

      else if (this.detalleSeleccionado.ManBtchNum) {

            let loteExiste = false;

            this.detalleSeleccionado.LOTE_OnHand.forEach((element) => {
              if (element.CodigoLoteSerie == this.Lote) {
                loteExiste = true
              }

            });

            if (!loteExiste) {
              let nuevoLote = {
                Checked: false,
                SysNumber: 0,
                CodigoLoteSerie: this.Lote,
                BatchNumber: this.Lote,
                Quantity: 0,
                Original: 0,
                Pickeada: 0,
              };
              this.detalleSeleccionado.LOTE_OnHand.push(nuevoLote);
              console.log("LOTE CREADO", this.detalleSeleccionado);
            } else {
              console.log("LOTE EXISTE PO WEON");
            }

      }

      else if (this.detalleSeleccionado.ManSerNum) {
            let serieExiste = false;

            this.detalleSeleccionado.SERIE_OnHand.forEach((element) => {
              if (element.CodigoLoteSerie == this.Serie) {
                serieExiste = true
              }

            });

            if (!serieExiste) {
              let nuevoSerie = {
                Checked: false,
                SysNumber: 0,
                CodigoLoteSerie: this.Serie,
                BatchNumber: this.Serie,
                Quantity: 0,
                Original: 0,
                Pickeada: 0,
              };
              this.detalleSeleccionado.SERIE_OnHand.push(nuevoSerie);
              console.log("SERIE CREADO", this.detalleSeleccionado);
            } else {
              console.log("SERIE EXISTE PO WEON");
            }
      }
        
      
    }
  }

  async checkeaSerie() {
    if (this.WMSaccion == "WMSCompra") {
      if (this.detalleSeleccionado.BinLocationActive) {
        if (this.Ubicacion) {
          if (this.detalleSeleccionado.ManBtchNum) {
            let loteExiste = false;

            this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
              if (element.BinCode == this.Ubicacion) {
                element.LOTE.forEach((lote) => {
                  if (lote.CodigoLoteSerie == this.Lote) {
                    loteExiste = true;
                  }
                });

                if (!loteExiste) {
                  let nuevoLote = {
                    Checked: false,
                    SysNumber: 0,
                    CodigoLoteSerie: this.Lote,
                    BatchNumber: this.Lote,
                    Quantity: 0,
                    Original: 0,
                    Pickeada: 0,
                  };
                  element.LOTE.push(nuevoLote);
                  console.log("LOTE CREADO", this.detalleSeleccionado);
                } else {
                  console.log("LOTE EXISTE PO WEON");
                }
              }
            });
          }

          if (this.detalleSeleccionado.ManSerNum) {


            let serieExiste = false;

            this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
              if (element.BinCode == this.Ubicacion) {
                element.SERIE.forEach((lote) => {
                  if (lote.CodigoLoteSerie == this.Serie) {
                    serieExiste = true;
                  }
                });

                if (!serieExiste) {
                  let nuevoLote = {
                    Checked: false,
                    SysNumber: 0,
                    CodigoLoteSerie: this.Serie,
                    BatchNumber: this.Serie,
                    Quantity: 0,
                    Original: 0,
                    Pickeada: 0,
                  };
                  element.SERIE.push(nuevoLote);
                  console.log("SERIE CREADO", this.detalleSeleccionado);
                } else {
                  console.log("SERIE EXISTE PO WEON");
                }
              }
            });

          }
        } else {
          Swal.fire("Mensaje", "Debe colocar una ubicación", "error");
        }
      }

      else if (this.detalleSeleccionado.ManBtchNum) {

            let loteExiste = false;

            this.detalleSeleccionado.LOTE_OnHand.forEach((element) => {
              if (element.CodigoLoteSerie == this.Lote) {
                loteExiste = true
              }

            });

            if (!loteExiste) {
              let nuevoLote = {
                Checked: false,
                SysNumber: 0,
                CodigoLoteSerie: this.Lote,
                BatchNumber: this.Lote,
                Quantity: 0,
                Original: 0,
                Pickeada: 0,
              };
              this.detalleSeleccionado.LOTE_OnHand.push(nuevoLote);
              console.log("LOTE CREADO", this.detalleSeleccionado);
            } else {
              console.log("LOTE EXISTE PO WEON");
            }

      }

      else if (this.detalleSeleccionado.ManSerNum) {
            let serieExiste = false;

            this.detalleSeleccionado.SERIE_OnHand.forEach((element) => {
              if (element.CodigoLoteSerie == this.Serie) {
                serieExiste = true
              }

            });

            if (!serieExiste) {
              let nuevoSerie = {
                Checked: false,
                SysNumber: 0,
                CodigoLoteSerie: this.Serie,
                BatchNumber: this.Serie,
                Quantity: 0,
                Original: 0,
                Pickeada: 0,
              };
              this.detalleSeleccionado.SERIE_OnHand.push(nuevoSerie);
              console.log("SERIE CREADO", this.detalleSeleccionado);
            } else {
              console.log("SERIE EXISTE PO WEON");
            }
      }
        
      
    }
  }
  async pickeaCantidad()
  {
    //console.log(this.detalleSeleccionado);
    console.log("Cantidad seleccionada", this.Cantidad);
    if (!this.Cantidad) {
      if (this.Cantidad == null) {
        console.log("Con 0 paso");
        return "";
      }
      else
      {
        if (this.Cantidad == "") {
          return "";
        }
      }
    }
    if (this.Cantidad == "") {
      return "";
    }

    if (this.detalleSeleccionado.BinLocationActive) {
      if (!this.detalleSeleccionado.ManBtchNum && !this.detalleSeleccionado.ManSerNum ) {
        this.detalleSeleccionado.UBICACION_OnHand.forEach(element => {
          if (element.BinCode == this.Ubicacion) {
            element.Pickeada = this.Cantidad;
          }
        });
      }

      if (this.detalleSeleccionado.ManBtchNum) {
        this.detalleSeleccionado.UBICACION_OnHand.forEach(element => {
          if (element.BinCode == this.Ubicacion) {
            element.LOTE.forEach(lote => {
              if (lote.CodigoLoteSerie == this.Lote) {
                lote.Pickeada = this.Cantidad;
              }
            });
           
          }
        });
      }
      if (this.detalleSeleccionado.ManSerNum) {
        this.detalleSeleccionado.UBICACION_OnHand.forEach(element => {
          if (element.BinCode == this.Ubicacion) {
            element.SERIE.forEach(serie => {
              if (serie.CodigoLoteSerie == this.Serie) {
                serie.Pickeada = 1;
                serie.Checked = !serie.Checked;
              }
            });
           
          }
        });
      }
    }
    else{
      if (!this.detalleSeleccionado.ManBtchNum && !this.detalleSeleccionado.ManSerNum ) {
        this.detalleSeleccionado.Pickeada = this.Cantidad;
      }
      else{
        if (this.detalleSeleccionado.ManBtchNum) {
          this.detalleSeleccionado.LOTE_OnHand.forEach(element => {
            if (element.CodigoLoteSerie == this.Lote) {
              element.Pickeada = this.Cantidad;
            }
          });
        }
        if (this.detalleSeleccionado.ManSerNum) {
          this.detalleSeleccionado.SERIE_OnHand.forEach(element => {
            if (element.CodigoLoteSerie == this.Serie) {
              element.Checked = !element.Checked;
              element.Pickeada = 1;
            }
          });
        }
      }
     
    }


    //this.guardarPicking();
    console.log("actual", this.detalleSeleccionado);
    var prueba = await this._apiWMS.calculaDatoPickeadoDetalle(this.detalleSeleccionado)



    var mensaje = "Cantidad pickeada " + this.detalleSeleccionado.Pickeada + " del articulo " + this.detalleSeleccionado.ItemCode;

    this.mensajeAlerta = mensaje;

    this.mostrarAlerta = true;
    setTimeout(() => {
      this.mostrarAlerta = false;
    }, 5000);

    //this.limpiar();
  }

  
  async pickeaCantidadWeasDeLeo() {
    //console.log(this.detalleSeleccionado);
    if (this.detalleSeleccionado.BinLocationActive) {
      if (
        !this.detalleSeleccionado.ManBtchNum &&
        !this.detalleSeleccionado.ManSerNum
      ) {
        this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
          if (element.BinCode == this.Ubicacion) {
            element.Pickeada = this.Cantidad;
          }
        });
      }

      if (this.detalleSeleccionado.ManBtchNum) {
        this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
          if (element.BinCode == this.Ubicacion) {
            element.LOTE.forEach((lote) => {
              if (lote.CodigoLoteSerie == this.Lote) {
                lote.Pickeada = this.Cantidad;
              }
            });
          }
        });
      }
      if (this.detalleSeleccionado.ManSerNum) {


        let serieExiste = false;

        this.detalleSeleccionado.UBICACION_OnHand.forEach((element) => {
          if (element.BinCode == this.Ubicacion) {
            element.SERIE.forEach((lote) => {
              if (lote.CodigoLoteSerie == this.Serie) {
                serieExiste = true;
              }
            });

            if (!serieExiste) {
              let nuevoLote = {
                Checked: false,
                SysNumber: 0,
                CodigoLoteSerie: this.Serie,
                BatchNumber: this.Serie,
                Quantity: 0,
                Original: 0,
                Pickeada: 0,
              };
              element.SERIE.push(nuevoLote);
              console.log("SERIE CREADO", this.detalleSeleccionado);
            } else {
              console.log("SERIE EXISTE PO WEON");
            }
          }
        });


      }
    } else {
      if (
        !this.detalleSeleccionado.ManBtchNum &&
        !this.detalleSeleccionado.ManSerNum
      ) {
        this.detalleSeleccionado.Pickeada = this.Cantidad;
      } else {
        if (this.detalleSeleccionado.ManBtchNum) {
          this.detalleSeleccionado.LOTE_OnHand.forEach((element) => {
            if (element.CodigoLoteSerie == this.Lote) {
              element.Pickeada = this.Cantidad;
            }
          });
        }
        if (this.detalleSeleccionado.ManSerNum) {

          this.detalleSeleccionado.SERIE_OnHand.forEach((element) => {
            if (element.CodigoLoteSerie == this.Serie) {
              element.Checked = !element.Checked;
              element.Pickeada = 1;
            }
          });

          
        }
      }
    }

    //this.guardarPicking();
    console.log("actual", this.detalleSeleccionado);
    var prueba = await this._apiWMS.calculaDatoPickeadoDetalle(
      this.detalleSeleccionado
    );

    console.log("resultado", prueba);
  }

  async Pistoleo(row: any) {
    //console.log('dato que no funca',row)
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(PistoleoComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.WMSaccion = this.WMSaccion;
    modalRef.componentInstance.detalleov = this.DocNum;
    modalRef.componentInstance.modalReference = modalRef;
    await modalRef.result.then(async (data) => {});
  }

  async guardarPicking() {
    console.log("se guarda", this.DataPicking.resultado);
    var datos: any = await this._apiWMS.actualizaPicking(
      this.DataPicking.resultado
    );
    if (datos.estado) {
      this.DataPicking.resultado = datos.resultado;
      console.log("Datos guardados en mongo", datos.resultado);
      Swal.fire("Mensaje", "Picking almacenado con exito.!", "success" );
    } else {
      console.log("No se guardaron los datos en mongo", datos.mensaje);
      Swal.fire("Mensaje", "Picking no pudo ser almacenado", "error" );
    }
  }

  limpiar() {
    this.detalleSeleccionado = null;
    this.ItemCode = "";
    this.Lote = "";
    this.Serie = "";
    this.Ubicacion = "";
    this.Cantidad = "";
  }

  async enAprobacion() {
    if (this.ESTADO_WMS == "AS") {
      let body = {
        DocEntry: this.DataPicking.resultado.DocEntry,
        ObjType: this.DataPicking.resultado.ObjType,
        estado: "EA",
      };
      var respuesta: any = await this._apiWMS.CambiarEstado(body);
      if (respuesta.estado) {
        Swal.fire("Mensaje", respuesta.mensaje, "success");

        await new Promise(r => setTimeout(r, 2000));
        window.location.reload();

      } else {
        Swal.fire("Mensaje", respuesta.mensaje, "error");
      }
    } else {
      Swal.fire(
        "Mensaje",
        "No se puede enviar a aprobación en el estado actual",
        "error"
      );
    }
  }

  limpiaItem() {
    //this.ItemCode = "";
  }
  limpiUbicacion() {
    this.Ubicacion = "";
  }
  limpiaSerie() {
    this.Serie = "";
  }
  limpiaLote() {
    this.Lote = "";
  }
  limpiaCantidad() {
    this.Cantidad = "";
  }

  elijeLinea() {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(
      SeleccionarLineaComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.data = this.DataPicking.resultado.DETALLLE;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(
      (item) => {
        if (item) {
          this.limpiar();
          this.ItemCode = item.ItemCode;
          this.detalleSeleccionado = item;
          console.log(item);
        }
      },
      (reason) => {
        //this.editar(item);
      }
    );
  }

  elijeUbicacion() {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(
      SeleccionarUbicacionComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.data = this.detalleSeleccionado;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(
      (item) => {
        if (item) {
          this.Ubicacion = item.BinCode;
          this.ubicacionSeleccionada = item;
          console.log(item);
        }
      },
      (reason) => {
        //this.editar(item);
      }
    );
  }

  seleccionarSerieLote() {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    if (this.detalleSeleccionado.BinLocationActive) {
      if (this.ubicacionSeleccionada) {
        if (
          this.detalleSeleccionado.BinLocationActive &&
          this.detalleSeleccionado.ManBtchNum
        ) {
          const modalRef = this.modalService.open(
            SeleccionarSerieLoteComponent,
            ngbModalOptions
          );
          modalRef.componentInstance.data = this.ubicacionSeleccionada.LOTE;
          modalRef.componentInstance.modalReference = modalRef;

          modalRef.result.then(
            (item) => {
              if (item) {
                this.Lote = item.CodigoLoteSerie;
              }
            },
            (reason) => {
              //this.editar(item);
            }
          );
        } else if (
          this.detalleSeleccionado.BinLocationActive &&
          this.detalleSeleccionado.ManSerNum
        ) {
          const modalRef = this.modalService.open(
            SeleccionarSerieLoteComponent,
            ngbModalOptions
          );
          modalRef.componentInstance.data = this.ubicacionSeleccionada.SERIE;
          modalRef.componentInstance.modalReference = modalRef;

          modalRef.result.then(
            (item) => {
              if (item) {
                this.Serie = item.CodigoLoteSerie;
                this.compraValidaLoteSerie('SERIE');
              }
            },
            (reason) => {
              //this.editar(item);
            }
          );
        }
      } else {
        Swal.fire("Mensaje", "Debe Seleccionar una ubicación", "error");
      }
    } else if (this.detalleSeleccionado.ManBtchNum) {
      const modalRef = this.modalService.open(
        SeleccionarSerieLoteComponent,
        ngbModalOptions
      );
      modalRef.componentInstance.data = this.detalleSeleccionado.LOTE_OnHand;
      modalRef.componentInstance.modalReference = modalRef;

      modalRef.result.then(
        (item) => {
          if (item) {
            this.Lote = item.CodigoLoteSerie;
          }
        },
        (reason) => {
          //this.editar(item);
        }
      );
    } else if (this.detalleSeleccionado.ManSerNum) {
      const modalRef = this.modalService.open(
        SeleccionarSerieLoteComponent,
        ngbModalOptions
      );
      modalRef.componentInstance.data = this.detalleSeleccionado.SERIE_OnHand;
      modalRef.componentInstance.modalReference = modalRef;

      modalRef.result.then(
        (item) => {
          if (item) {
            this.Serie = item.CodigoLoteSerie;
            this.compraValidaLoteSerie('SERIE');
          }
        },
        (reason) => {
          //this.editar(item);
        }
      );
    }
  }

  cerrar() {
    this.dialogRef.close(this.ESTADO_WMS);
  }

  async infopicking(row){

    console.log("TEST ROW NAZI",row)


    
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

  

    
    //console.log("DATA PICKING",this.DataPicking)

    const modalRef = this.modalService.open(DetallepickeoComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {});

  }



}
