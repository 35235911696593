<div class="modal-header">

  <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
    <p>{{data.ItemName}}</p>
  </h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

</div>

<div class="row">



  <div class="col-12" *ngIf="!data.BinLocationActive && (data.ManBtchNum || data.ManSerNum)">

    <mat-accordion *ngIf="data.ManBtchNum">

        <mat-expansion-panel *ngFor="let element of data.LOTE">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Lote:&nbsp;{{element.CodigoLoteSerie}}
            </mat-panel-title>



          </mat-expansion-panel-header>
          <p>Cantidad pickeada:&nbsp;{{element.Pickeada}}</p>

        </mat-expansion-panel>


    </mat-accordion>

    <mat-accordion *ngIf="data.ManSerNum">

      <mat-expansion-panel *ngFor="let element of data.SERIE">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Serie:&nbsp;{{element.CodigoLoteSerie}}
          </mat-panel-title>



        </mat-expansion-panel-header>
        <p>Cantidad pickeada:&nbsp;{{element.Pickeada}}</p>

      </mat-expansion-panel>


  </mat-accordion>


  </div>



  <div class="col-12" *ngIf="data.BinLocationActive && (!data.ManBtchNum && !data.ManSerNum)">

    <mat-accordion>

        <mat-expansion-panel *ngFor="let element of data.UBICACION">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Ubicación:&nbsp;{{element.BinCode}}
            </mat-panel-title>



          </mat-expansion-panel-header>
          <p>Cantidad pickeada:&nbsp;{{element.Pickeada}}</p>

        </mat-expansion-panel>


    </mat-accordion>




  </div>

  <div class="col-12" *ngIf="!data.BinLocationActive && (!data.ManBtchNum && !data.ManSerNum)">

    <mat-accordion>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Descripción:&nbsp;{{data.ItemName}}
            </mat-panel-title>



          </mat-expansion-panel-header>
          <p>Cantidad pickeada:&nbsp;{{data.Pickeada}}</p>

        </mat-expansion-panel>


    </mat-accordion>




  </div>



  <div class="col-12" *ngIf="data.BinLocationActive && data.ManBtchNum">

    <mat-accordion>

      <mat-expansion-panel *ngFor="let row of data.UBICACION">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ubicación:&nbsp;{{row.BinCode}}
          </mat-panel-title>

        </mat-expansion-panel-header>


        <mat-expansion-panel *ngFor="let element of row.LOTE">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Lote:&nbsp;{{element.CodigoLoteSerie}}
            </mat-panel-title>



          </mat-expansion-panel-header>
          <p>Cantidad pickeada:&nbsp;{{element.Pickeada}}</p>



        </mat-expansion-panel>



      </mat-expansion-panel>

    </mat-accordion>




  </div>

  <div class="col-12" *ngIf="data.BinLocationActive && data.ManSerNum">

    <mat-accordion>

      <mat-expansion-panel *ngFor="let row of data.UBICACION">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ubicación:&nbsp;{{row.BinCode}}
          </mat-panel-title>

        </mat-expansion-panel-header>


        <mat-expansion-panel *ngFor="let element of row.SERIE">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Serie:&nbsp;{{element.CodigoLoteSerie}}
            </mat-panel-title>



          </mat-expansion-panel-header>
          <p>Cantidad pickeada:&nbsp;{{element.Pickeada}}</p>



        </mat-expansion-panel>



      </mat-expansion-panel>

    </mat-accordion>




  </div>


</div>

