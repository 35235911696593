import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { variableGlobal } from 'src/app/global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SapService } from 'src/app/api/sap.service';
import { IndesadService } from 'src/app/api/indesad.service';


@Component({
  selector: 'app-OITM',
  templateUrl: './OITM.component.html',
  styleUrls: ['./OITM.component.sass']
})
export class OITMComponent implements OnInit {

  data = {
    "ItemCode": null,
    "ItemName": null,
    "FrgnName": null,
    "ItmsGrpCod": null,
    "CstGrpCode": null,
    "VATLiable": null,
    "PrchseItem": null,
    "SellItem": null,
    "InvntItem": null,
    "BuyUnitMsr": null,
    "NumInBuy": null,
    "SalUnitMsr": null,
    "NumInSale": null,
    "EvalSystem": null,
    "TreeType": null,
    "TreeQty": null,
    "AssetItem": null,
    "WasCounted": null,
    "ManSerNum": null,
    "SHeight1": 0.000,
    "SWidth1": 0,
    "SLength1": 0.0,
    "SLen2Unit": 0,
    "SVolume": 0.000,
    "SVolUnit": 0,
    "SWeight1": 0.000,
    "SWght1Unit": 0,
    "BVolUnit": null,
    "FirmName": null,
    "QryGroup1": null,
    "PurPackUn": null,
    "SalPackUn": null,
    "ManBtchNum": null,
    "ManOutOnly": null,
    "SWW": null,
    "GLMethod": null,
    "TaxType": null,
    "ByWh": null,
    "WTLiable": null,
    "ItemType": null,
    "MngMethod": null,
    "InvntryUom": null,
    "PlaningSys": null,
    "PrcrmntMtd": null,
    "UomName": null,
    "FirmCode": null,
    "IUoMEntry": null,
    "MEDIDA": null,
    "PurPackMsr": null,
    "U_IND_CATEGORIA2": null,
    "U_IND_CATEGORIA3": null,
    "U_IND_Factor1": 1,
    "U_IND_Factor2": 1,
    "U_IND_SWW": null,
    "U_Origin": null,
  }
  ItemCode:any;
  OLGT;
  OITB;
  OWHS;
  OARG;
  OUOM;
  OMRC;
  USERFIELDS: any;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _api: IntranetoldService,
    // private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,
    private _apiSap: SapService,
    private _apiIndesad: IndesadService,) { }

  ngOnInit() {

    this.cargaDatosIniciales();

  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = null;
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }
  async cargaDatosIniciales() {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se está cargando documento',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

    this.ItemCode = this._activeRoute.snapshot.paramMap.get("ItemCode");
    
    var query: any = await this.obtieneQueryMongo("OLGT");
    var dato: any = await this._api.GetNotFileQuery(query);    
    this.OLGT = dato.objeto[0];
    console.log("OLGT");
    console.log(this.OLGT);
    
    query = await this.obtieneQueryMongo("OITB");
    dato = await this._api.GetNotFileQuery(query);    
    this.OITB = dato.objeto[0];
    console.log("OITB");
    console.log(this.OITB);
    
    query = await this.obtieneQueryMongo("OWHS");
    dato = await this._api.GetNotFileQuery(query);    
    this.OWHS = dato.objeto[0];
    console.log("OWHS");
    console.log(this.OWHS);

    query = await this.obtieneQueryMongo("OARG");
    dato = await this._api.GetNotFileQuery(query);    
    this.OARG = dato.objeto[0];
    console.log("OWHS");
    console.log(this.OARG);

    query = await this.obtieneQueryMongo("OUOM");
    dato = await this._api.GetNotFileQuery(query);        
    this.OUOM = dato.objeto[0];
    console.log("OWHS");
    console.log(this.OUOM);

    query = await this.obtieneQueryMongo("OMRC");
    dato = await this._api.GetNotFileQuery(query);    
    this.OMRC = dato.objeto[0];
    console.log("OWHS");
    console.log("OWHS");
    query = await this.obtieneQueryMongo("USERFIELDS");
    query = query.replace("TABLA", "OITM");
    dato = await this._api.GetNotFileQuery(query);    
    this.USERFIELDS = dato.objeto[0];
    console.log(this.USERFIELDS);

    
    query = await this.obtieneQueryMongo("OITM");
    query = query + "WHERE [ItemCode]='"+this.ItemCode+"'";    
    dato = await this._api.GetNotFileQuery(query);
    console.log(dato);
    this.data = dato.objeto[0];

    console.log(this.data);


    // if (variableGlobal.ESTILO_EASY_TABLE) {
    //   this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    // }

    Swal.close();

  }

}
