import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { SapService } from 'src/app/api/sap.service';
import { WMSService } from 'src/app/api/wms.service';
import { variableGlobal } from 'src/app/global.enum';
import Swal from 'sweetalert2';
import { DetallepickeoComponent } from './detallepickeo/detallepickeo.component';
import { GenerarDocumentoModalComponent } from './generar-documento-modal/generar-documento-modal.component';
@Component({
  selector: 'app-vista-nv',
  templateUrl: './vista-nv.component.html',
  styleUrls: ['./vista-nv.component.css']
})
export class VistaNvComponent implements OnInit {
  displayedColumns: string[] = ['estadolinea', 'codesap', 'descripcion', 'stockbodega', 'bodega', 'cantsolicitada', 'cantpendiente', 'cantpicking'];
  dataSource:any;


  constructor(
    private _api: IntranetoldService,
      private _apiSap: SapService,
      private _apiWMS: WMSService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private _dbService: NgxIndexedDBService,


    ) { }
  resultado;
  ESTADO_WMS;
  data:any;
  datasap:any;
  DataPicking:any;
  Detalle:any;
  titulo:any;
  listavendedor:any;
  formasEnvio:any;
  usuarios: any = [];
  carga:any;


  usuario:any;
  idUsuario;any;
  adminUsuario:any;





  ngOnInit(): void {

    this.cargaInicial();



    console.log("TITUTO",this.titulo);

    console.log("VENDEDORES", this.listavendedor);

  }

  async infopicking(row){

    console.log("TEST ROW NAZI",row)



    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };




    //console.log("DATA PICKING",this.DataPicking)

    const modalRef = this.modalService.open(DetallepickeoComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {});

  }



  async getUsuarios() {
    var res: any = await this._api.getAllLogin();
    var encargados: any = await this._apiWMS.GetGrupo("Responsable");
    //console.log(encargados);
    // if (encargados.estado) {
    this.usuarios = encargados.objeto;
    // }
  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }


 async cargaInicial(){

  this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
    item => {
      var itemx: any = item;
      this.usuario = itemx.datos.login;
      this.idUsuario = this.usuario._id;
      this.adminUsuario = this.usuario.ADMIN;
      console.log("es admin?", this.adminUsuario);
      //console.log("usuario 2", this.idUsuario);
    }
  );


  console.log('ESTADO WMS',this.ESTADO_WMS)
  this.carga = true;
  var json:any = {

         "DocEntry": this.data.DocEntry,
         "ObjType": this.data.ObjType

    }
    console.log(json)
    this.datasap = await this._apiSap.ObtenerDocumentoMarketingLITE(json);
    this.DataPicking = await this._apiWMS.AbrirPicking(json)
    console.log("DATAPICKING",this.DataPicking);
    this.Detalle = this.DataPicking.resultado.DETALLLE;
    console.log("DETALLE",this.Detalle)


    this.Detalle.forEach(detalle => {
      this.datasap.resultado.Detalles.forEach(datasap => {
        if (detalle.ItemCode == datasap.ItemCode && detalle.LineNum == datasap.LineNum) {
          datasap.pickeado = detalle.Quantity;
        }
      });
    });

    this.Detalle.forEach(element => {
      element.pendiente = element.OpenQuantity - element.Pickeada;
    });


    this.dataSource = this.Detalle;


    this.dataSource.forEach(element => {
      if (element.LineStatus=="O") {
        element.LineStatus="Abierto"
      }else{
        element.LineStatus="Cerrado"
      }
    });

    this.listavendedor = await this.obtieneQueryMongo("listaVendedor");
    this.listavendedor = await this._api.GetNotFileQuery(this.listavendedor);

    this.formasEnvio = await this.obtieneQueryMongo("formaEnvio");
    this.formasEnvio = await this._api.GetNotFileQuery(this.formasEnvio);

    this.listavendedor.objeto.forEach(element => {
      element.SlpCode == this.datasap.resultado.SlpCode;
      this.datasap.resultado.SlpName = element.SlpName
    });

    this.formasEnvio.objeto.forEach(element => {
      element.TrnspCode == this.datasap.resultado.TrnspCode;
      this.datasap.resultado.TrnspName = element.TrnspName
    });

    await this.getUsuarios();

    console.log("USUARIOS",this.usuarios);

    this.datasap.resultado.UserFields.forEach(element => {
      if (element.Field == "U_IND_RESPONSABLE_PICKING") {

       this.datasap.resultado.RUT = element.Value;

      }
    });

    console.log("DATASAP",this.datasap);


    this.carga = false;

  }

  async UpdateResponsable(event) {
    // console.log(event.target.value);
    let body = {
      DocEntry: this.datasap.resultado.DocEntry,
      ObjType: this.datasap.resultado.ObjType,
      responsable: event.target.value,
    };

    console.log("BODY RESPONSABLE",body)
    await this._apiWMS.AsignarResponsable(body);


  }

  cerrar() {
    this.activeModal.close(this.ESTADO_WMS);
  }

  async cambiarEstado(){


    if (this.titulo == "generardoc" && this.ESTADO_WMS == 'EA') {

      let body = {
        DocEntry:this.DataPicking.resultado.DocEntry,
        ObjType:this.DataPicking.resultado.ObjType,
        estado:"AP"
      };
      this.ESTADO_WMS = "AP";

      this.resultado = await this._apiWMS.CambiarEstado(body)

      Swal.fire(
        `${this.resultado.mensaje}`,
        "",
        'success'
      )
    }


    else{
      Swal.fire(
        `${this.resultado.mensaje}`,
        "",
        'error'
      )
    }

  }

  async cambiarEstadoAdmin(event){



      let body = {
        DocEntry:this.DataPicking.resultado.DocEntry,
        ObjType:this.DataPicking.resultado.ObjType,
        estado:event.target.value
      };

      this.resultado = await this._apiWMS.CambiarEstado(body)

      console.log(this.resultado)
      Swal.fire(
        `${this.resultado.mensaje}`,
        "",
        'success'
      )


  }


  async generarDocumento() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "sm",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,

    };

    const modalRef = this.modalService.open(GenerarDocumentoModalComponent, ngbModalOptions);
    modalRef.componentInstance.data = this.data;



    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {


    });
  }

}

export interface PeriodicElement {
  estadolinea: any;
  codesap: any;
  descripcion: any;
  stock: any;
  stockbodega: any;
  bodega: any;
  cantsolicitada: any;
  cantpendiente: any;
  cantpicking: any;

}






