<div class="modal-content">
    <form>
        <div class="modal-header">						
            <h4 class="modal-title">Crear Meta</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cerrar()">×</button>
        </div>
        <div class="modal-body">					
            <div class="form-group">
                <label>Nombre</label>
                <input type="text" class="form-control" required="">
            </div>
            <div class="form-group">
                <label>Mes</label>
                <input type="email" class="form-control" required="">
            </div>
            <div class="form-group">
                <label>Año</label>
                <textarea class="form-control" required=""></textarea>
            </div>
            <div class="form-group">
                <label>Valor</label>
                <input type="text" class="form-control" required="">
            </div>					
        </div>
        <div class="modal-footer">
            <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel">
            <input type="submit" class="btn btn-success" value="Add">
        </div>
    </form>
</div>