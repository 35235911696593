     <!-- Navbar -->
     <!--<nav class="main-header navbar navbar-expand navbar-white navbar-light">-->
      <nav class="{{general.CLASS_NAVBAR}}">

        <!-- Left navbar links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
          </li>
        </ul>

        {{CompnyName}} <img *ngIf="bandera == 'peru'" src="assets/peru.png" style="width: 39px;margin-left: 10px;" alt="" srcset="">
        <img *ngIf="bandera == 'chile'" src="assets/chile.png" style="width: 39px;margin-left: 10px;" alt="" srcset="">

        <ul class="navbar-nav ml-auto">

          <li class="nav-item">
            <a class="nav-link" (click)="refresh()">
              <i class="fa-regular fa-arrows-rotate"></i>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" (click)="cambioContrasena()">
              <i class="fas fa-regular fa-key"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="cerrarSession()">
                <i class="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </nav>
