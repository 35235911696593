

<body>

  <div class="loading" *ngIf="!imprimibles">
    <i class="fa-solid fa-spinner-third fa-spin" style="border-radius: 50%;margin-top: 40%;"></i>
  </div>

	<div class="controls-container">
		<button (click)="printPage()">Imprimir</button>
		<button (click)="cerrar()">Cerrar</button>

  </div>

  <div class="main-container">
    <div id="page-container" class="page-container">
      <div class="page-inner">
        <div class="container">

            <div class="row">

              <div class="col-6" style="display: flex;justify-content: center;">
                <img src="assets/LogoTWAzul.png" alt="" srcset="" style="height: 100px;border-radius: 23px;">
              </div>

              <div class="col-6" style="display: flex;justify-content: center;margin-top: 17px;">
                <div class="row">
                  <div class="col-12" style="display: flex;">

                  <h5 style="padding-top: 4px;">{{"Numero Documento: "+datasap.resultado.DocNum}}</h5>
                  </div>

                  <div class="col-12" style="display: flex;">
                    <h5 style="padding-top: 4px;">{{'Fecha:'+"&nbsp;"}}</h5>
                  <h5 style="padding-top: 4px;">{{" "+fechaactual | date: 'dd/MM/yyyy'}}</h5>
                  </div>


                </div>
              </div>

                <div class="col-12" style="border: 1px solid black;margin-top: 30px;padding-top: 10px;">

                    <div class="row" style="height: 300px;">

                        <div class="col-6">
                            <h4>
                                {{ClienteProveedor}}:
                            </h4>
                            <h5 style="padding-top: 4px;">{{datasap.resultado.CardName}}</h5>
                        </div>


                        <div class="col-6">
                          <h4>
                              Fecha Entrega:
                          </h4>
                          <h5 style="padding-top: 4px;">{{datasap.resultado.DocDueDate | date: 'dd/MM/yyyy'}}</h5>
                      </div>

                      <div class="col-6">
                        <h4>
                            Dirección:
                        </h4>
                        <h5 class="overflow-visible" style="padding-top: 4px;">{{datasap.resultado.Address2}}</h5>
                    </div>

                    <div class="col-6">
                      <h4>
                          Responsable:
                      </h4>
                      <h5 style="padding-top: 4px;">{{datasap.resultado.responsable}}</h5>
                  </div>

                  <div class="col-6">
                    <h4>
                        Comentario:
                    </h4>
                    <h5 style="padding-top: 4px;width: max-content;">{{datasap.resultado.Comments}}</h5>
                 </div>


                    </div>
                </div>


            </div>


        </div>



        <table style="width: 100%;margin-top: 30px;">
          <tr>
            <td>Código</td>
            <td>Descripción</td>
            <td>Cantidad Solicitada</td>
            <td>Cantidad Pickeada</td>
          </tr>
          <tr *ngFor="let row of imprimibles">
            <td>{{row.ItemCode}}</td>
            <td>{{row.Dscription}}</td>
            <td>{{row.OpenQty}}</td>
            <td></td>
          </tr>

        </table>





      </div>
    </div>
  </div>

    <script src="https://code.jquery.com/jquery-3.3.1.min.js" crossorigin="anonymous"></script>

  </body>
