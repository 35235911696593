import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WMSService } from 'src/app/api/wms.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generar-documento-modal',
  templateUrl: './generar-documento-modal.component.html',
  styleUrls: ['./generar-documento-modal.component.css']
})
export class GenerarDocumentoModalComponent implements OnInit {

  constructor(
    private _WMS: WMSService,
    public activeModal: NgbActiveModal,
    ) { }
  data;
  resultado;
  carga;

  ngOnInit(): void {

  this.carga = false;

  console.log(this.data)

  }

  async generarDocumento(ObjTypeDestino){

    this.carga = true;

    if (ObjTypeDestino == 15) {
      let body = {
        DocEntry: this.data.DocEntry,
        ObjType: this.data.ObjType,
        ObjTypeDestino: ObjTypeDestino
      }

      this.resultado = await this._WMS.generaDocumentoDesdePicking(body);
      if (this.resultado.estado) {
        Swal.fire(
          "Mensaje",
          `${this.resultado.mensaje}`,
          'success'
        )
      }
      else{
        Swal.fire(
          "Mensaje",
          `${this.resultado.mensaje}`,
          'error'
        )
      }
      
     

      this.activeModal.close();


    }

    else if (ObjTypeDestino == 13){

      let body = {
        DocEntry: this.data.DocEntry,
        ObjType: this.data.ObjType,
        ObjTypeDestino: ObjTypeDestino
      }

      this.resultado = await this._WMS.generaDocumentoDesdePicking(body);
      
      Swal.fire(
        `${this.resultado.mensaje}`,
        "",
        'success'
      )

      this.activeModal.close();


    }
     else
    {
      Swal.fire(
        `Hubo un error`,
        "",
        'error'
      )
      this.activeModal.close();


    }

    this.carga = false;

  }

  cerrar() {
    this.activeModal.close();
  }




}
