<mat-accordion style="margin: 5px;">
    <div class="row">
        <div class="col-12" *ngFor="let row of data.UBICACION_OnHand" style="display: flex;margin-bottom: 10px;">
            <mat-expansion-panel style="width: 100%;">
                <mat-expansion-panel-header>
                    <mat-panel-title> Ubicación: {{row.BinCode}}</mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-list>
                    <mat-list-item>Stock: {{row.Quantity}}</mat-list-item>
                  </mat-list>
            </mat-expansion-panel>
            <button style="background: #B41B20;border: 1px solid #B41B20;" class="btn btn-danger" (click)="select(row)">Seleccionar</button>
        </div>
        <div class="col-12" style="display: flex;justify-content: end;">
            <button style="background: #B41B20;border: 1px solid #B41B20;margin-right:15px;" class="btn btn-primary" (click)="cerrar()">Cerrar</button>

        </div>
    </div>


</mat-accordion>
