<div class="modal-header">
    <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" style="margin-right: 5px;">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
        </svg>
         Pistoleando en Ubicación ({{ubicaciondata.BinCode}})</p> 

    </h4>
  
    <button type="button" class="close" aria-label="Close" (click)="cerrar()" style="color: white;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="row container-botton">



    <div class="col-12" *ngIf="!loteoserie || loteoserie == '' || loteoserie == undefined">
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-upc-scan"
              viewBox="0 0 16 16"
            >
              <path
                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
              />
            </svg>
          </span>
        </div>
        <input
          [(ngModel)]="newloteserie.CodigoLoteSerie"
          placeholder="Nombre Lote/Serie"
          type="text"
          class="form-control input-design"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          style="
            opacity: 1;
            position: initial;
            font-family: 'Roboto';
            font-size: 27px;
          "


        />
      </div>
    </div>

    <div class="col-12" *ngIf="!loteoserie || loteoserie == '' || loteoserie == undefined">
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-box-seam-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
              />
            </svg>
          </span>
        </div>
        <input
        [(ngModel)]="newloteserie.Pickeada"

          type="number"
          id="pickingQuantity"
          min="1"
          step="0.01"
          class="form-control input-design"
          placeholder="Cantidad"
          autocomplete="off"
          style="
            opacity: 1;
            position: initial;
            font-family: 'Roboto';
            font-size: 27px;
          "

        />
      </div>
    </div>
    
    <div class="col-12" *ngIf="loteoserie == 'lote' || loteoserie == 'serie'">
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-upc-scan"
              viewBox="0 0 16 16"
            >
              <path
                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
              />
            </svg>
          </span>
        </div>
        <input
          [(ngModel)]="newloteserie.CodigoLoteSerie"
          (blur)="PasarSeriesLote()"
          placeholder="Nombre Lote/Serie"
          type="text"
          class="form-control input-design"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          style="
            opacity: 1;
            position: initial;
            font-family: 'Roboto';
            font-size: 27px;
          "


        />
      </div>
    </div>

    <div class="col-12" *ngIf="loteoserie == 'lote' || loteoserie == 'serie'">
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-box-seam-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
              />
            </svg>
          </span>
        </div>
        <input
          [(ngModel)]="newloteserie.Pickeada"
          (blur)="PasarSeriesLote()"
          type="number"
          id="pickingQuantity"
          min="1"
          step="0.01"
          class="form-control input-design"
          placeholder="Cantidad"
          autocomplete="off"
          style="
            opacity: 1;
            position: initial;
            font-family: 'Roboto';
            font-size: 27px;
          "

        />
      </div>
    </div>


    <div class="col-6 responsive" >

      <mat-expansion-panel >
        <mat-expansion-panel-header>

          <mat-panel-title>
            Titulo Tabla
          </mat-panel-title>

        </mat-expansion-panel-header>

        <table class="table">

          <thead>
            <tr>
              <th scope="col"*ngIf="rowDetalle.ManSerNum">Pickear</th>
              <th scope="col"*ngIf="rowDetalle.ManSerNum">Código de SERIE 
                <input type="text" placeholder="filtrar por código de serie" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()">
              </th>

              <th scope="col" *ngIf="rowDetalle.ManBtchNum">Código de LOTE 
                <input type="text" placeholder="filtrar por código de lote" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()">
              </th>

              
              <th scope="col">Cantidad</th>
              <th scope="col" *ngIf="rowDetalle.ManBtchNum">Pickear</th>
            </tr>
          </thead>

          <tbody>


            <tr *ngFor="let serie of ubicaciondata.SERIE">
              <td>
                <input type="checkbox" class="form-control" [(ngModel)]="serie.Checked" (change)="PasarSeriesLote()">

                <!--
                <div class="boton-pasar" (click)="PasarSeries()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg>
                </div>
                -->

              </td>
              <td>{{serie.CodigoLoteSerie}}</td>
              <td>{{serie.Quantity}}</td>
            </tr>


            <tr *ngFor="let lote of ubicaciondata.LOTE">
              <td>{{lote.CodigoLoteSerie}}</td>
              <td>{{lote.Quantity}}</td>
              <td>
                <input [(ngModel)]="lote.Pickeada" (blur)="PasarSeriesLote()" type="number" style="width: 50px;">
              </td>
            </tr>


          </tbody>
        </table>
        
      </mat-expansion-panel>


    </div>


    <div class="col-6 responsive" >
      <div style="width: 100%;display: flex;justify-content: center;">
        <p>
          Series Pickeadas
        </p>
      </div>
      <table class="table" *ngFor="let ubicacion of this.rowDetalle.UBICACION">

        <thead>
          <tr>

            <th scope="col" *ngIf="rowDetalle.ManSerNum">Código de SERIE 
              <input type="text" placeholder="filtrar por código de serie" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()">
            </th>

            <th scope="col" *ngIf="rowDetalle.ManBtchNum">Código de LOTE 
              <input type="text" placeholder="filtrar por código de serie" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()">
            </th>

            <th scope="col">Cantidad</th>
            <th scope="col">Borrar</th>
          </tr>
        </thead>
      
        <tbody *ngIf="ubicacion.BinCode == this.ubicaciondata.BinCode">
          <tr *ngFor="let serie of ubicacion.SERIE">

            <td>{{serie.CodigoLoteSerie}}</td>
            <td>{{serie.Quantity}}</td>
            <td>
              <div class="boton-borrar">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </div>
            </td>
          </tr>
          <tr *ngFor="let lote of ubicacion.LOTE">

            <td>{{lote.CodigoLoteSerie}}</td>
            <td>{{lote.Pickeada}}</td>
            <td>
              <div class="boton-borrar">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
   
      </table>
    </div>





  </div>