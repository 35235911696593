<app-cargando *ngIf="carga"></app-cargando>

<div class="modal-header">

  <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
    <p>Nota de venta ({{data.DocEntry}})</p>
  </h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>

<div class="modal-body">
  <div class="row container-vista-nv">

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Cliente</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Cliente"
          value={{datasap?.resultado?.CardCode}}
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Nombre</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Nombre"
          value={{datasap?.resultado?.CardName}}
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Número de referencia de deudor</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Número de referencia de deudor"
          value={{datasap?.resultado?.NumAtCard}}
        disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Fecha de contabilización</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="00/00/0000"
          value= "{{datasap?.resultado?.DocDate | date: 'dd/MM/yyyy'}}"
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Fecha de entrega</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="00/00/0000"
          value="{{ datasap?.resultado?.TaxDate | date: 'dd/MM/yyyy'}}"
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Fecha del documento</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="00/00/0000"
          value="{{ datasap?.resultado?.DocDueDate | date: 'dd/MM/yyyy'}}"
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Vendedor</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Vendedor"
          value="{{ datasap?.resultado?.SlpName }}"
          disabled
        />
      </div>
    </div>

    <div class="responsive">
      <div class="form-group">
        <label for="exampleInputEmail1">Forma de envío</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Forma de envío"
          value="{{ datasap?.resultado?.TrnspName }}"
          disabled/>

      </div>
    </div>

    <div class="responsive">
      <div class="form-group">

        <label for="exampleFormControlSelect1">Responsable Picking</label>
        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" (change)="UpdateResponsable($event);" [(ngModel)]="datasap?.resultado.RUT">
          <option value="" disabled>Seleccione Responsable</option>
          <option *ngFor="let row of usuarios" value={{row.RUT}}>{{row.NOMBRE}}</option>
        </select>

      </div>
    </div>

    <div class="responsive" *ngIf="titulo == 'generardoc'">
      <div class="form-group">
        <label for="exampleInputEmail1">Bultos</label>
        <input
          type="number"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Bultos"
        />
      </div>
    </div>


    <div class="responsive" *ngIf="titulo == 'asignar'">
      <div class="form-group">
        <label for="exampleInputEmail1">Bultos</label>
        <input
          type="number"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Bultos"
          disabled
        />
      </div>
    </div>

    <div class="responsive" *ngIf="adminUsuario">
      <div class="form-group">

        <label for="exampleFormControlSelect1">Cambiar Estado</label>
        <select [(ngModel)]="ESTADO_WMS" class="custom-select mr-sm-2" id="inlineFormCustomSelect" (change)="cambiarEstadoAdmin($event);">
          <option value="" disabled>Seleccione Estado</option>
          <option value="SA">Sin Asignar</option>
          <option value="AS">Asignado</option>
          <option value="EA">En Aprobación</option>
          <option value="AP">Aprobado</option>
          <option value="-">No Visualizar</option>
        </select>

      </div>
    </div>

    <div class="col-3"></div>
    <div class="col-3"></div>

    <div class="col-12 tabla-nv">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="estadolinea" style="margin-right: 500px">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Estado Linea
          </th>
          <td mat-cell *matCellDef="let element" >{{ element.LineStatus }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="codesap">
          <th mat-header-cell *matHeaderCellDef>Código SAP</th>
          <td mat-cell *matCellDef="let element">{{ element.codesap }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef>Descripción</th>
          <td mat-cell *matCellDef="let element">{{ element.ItemName }}</td>
        </ng-container>




        <ng-container matColumnDef="stockbodega">
          <th mat-header-cell *matHeaderCellDef>Stock Bodega</th>
          <td mat-cell *matCellDef="let element">{{ element.OnHand }}</td>
        </ng-container>

        <ng-container matColumnDef="bodega">
          <th mat-header-cell *matHeaderCellDef>Bodega</th>
          <td mat-cell *matCellDef="let element">{{ element.WhsCode }}</td>
        </ng-container>

        <ng-container matColumnDef="cantsolicitada">
          <th mat-header-cell *matHeaderCellDef>Cantidad solicitada</th>
          <td mat-cell *matCellDef="let element">
            {{ element.OpenQuantity }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cantpendiente">
          <th mat-header-cell *matHeaderCellDef>Cantidad pendiente</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pendiente }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cantpicking">
          <th mat-header-cell *matHeaderCellDef>Cantidad picking</th>
          <td mat-cell *matCellDef="let element" (click)="infopicking(element)" style="cursor: pointer;">{{ element.Pickeada }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button (click)="cerrar()" type="button" class="btn btn-danger" style="background: #B41B20;border: 1px solid #B41B20;margin-left: 5px;">Cerrar</button>
  <button *ngIf="titulo=='generardoc' && ESTADO_WMS == 'AP'" (click)="generarDocumento()" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">Generar Documento
    <i class="fa-solid fa-plus"></i>
  </button>

  <button *ngIf="titulo=='generardoc' && ESTADO_WMS == 'EA'" (click)="cambiarEstado()" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">Aprobar
    <i class="fa-solid fa-check"></i>
  </button>


</div>


