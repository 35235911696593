<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Socio de negocio</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<app-maestro-business-partner [headerInivisible]="headerInivisible" [CardCode]="CardCode"></app-maestro-business-partner>

<div class="modal-footer">
</div> 