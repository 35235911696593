import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetoldService} from '../../../api/intranetold.service'
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {editaSocioNegocioComponent} from './editaSocioNegocio';
import Swal from 'sweetalert2'
import {variableGlobal} from "../../../global.enum";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-socio-negocio',
  templateUrl: './socio-negocio.component.html',
  styleUrls: ['./socio-negocio.component.sass']
})
export class SocioNegocioComponent implements OnInit {

  constructor(
    private _api: IntranetoldService,
    private _activeRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  Origen:any = "";
  // CardType:any ="";
  data:any = [];
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;

  ngOnInit(): void {
    this.cargaInicial();
  }

  async cargaInicial()
  {
    this.Origen = this._activeRoute.snapshot.paramMap.get("Origen");
    console.log(this.Origen);
    // this.CardType = this._activeRoute.snapshot.paramMap.get("CardType");
    
     this.columns = [
      { key: 'CardCode', title: "Cliente"},
      { key: 'CardName', title: 'Nombre'},
      { key: 'LicTradNum', title: 'RUT'},
      { key: 'CardType', title: 'Tipo cliente'},
      { key: 'StrrtB', title: 'Dirección'},
      { key: 'E_Mail', title: 'Correo'},
      { key: 'Phone1', title: 'Telefono'},
      { key: 'SlpName', title: 'Empleado'},
      { key: '', title: 'Acción', searchEnabled: false, orderEnabled: false, cellTemplate: this.frmAccion }

    ];
    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled:true,
      horizontalScroll: true,
      resizeColumn: true,
      orderEnabled: true,
      rowReorder:false,
      columnReorder:false,
      fixedColumnWidth: true,
    };

    // Carga los datos de la tabla llamando a la función
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "traerClientesDatos") { //mantenedorCliente
        query = element.CONSULTA;
      }
    });
    if(this.Origen=="Compra") {
      query=query.replace("WHERE T0.[CardType] != 'S'", "WHERE T0.[CardType] = 'S'")
    }
    var dato:any = await this._api.GetNotFileQuery(query);
    this.data = dato.objeto;
    // END Carga los datos de la tabla llamando a la función

    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  Editar(item, rowIndex, id)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };

    if (item.Address === undefined) {
      var socio = {
        'Nombre': item.CardName,
        'Codigo': item.CardCode,
        'Rut': item.LicTradNum,
        'Telefono': item.Phone1,
        'Correo': item.E_Mail,
        'Vendedor': item.SlpCode,
        'VendedorNombre': item.SlpName,
        'GroupCode': item.GroupCode,
        'CardType': item.CardType,
        'U_IND_origenLead': item.U_IND_origenLead,
        //SE AGREGA OCRD.NOTES
        'Notes': item.Notes,
        //Giro = OCRD.NOTES
        // SE AGREGA DIRECCIÓN FACTURACIÓN
        'BillingAddress': {
          'Address': item.AddressS,
          'Street': item.StreetS,
          'County': item.CountyS,
          'City': item.CityS,
          'Country': item.CountryS,
          'State': item.StateS,
      },
        // END DIRECCION FACTURACIÓN
        'ContactPersons1': {
            'Name': item.Name,
            'FirstName': item.FirstName,
            'Cargo': item.Position,
            'tel': item.Tel1,
            'correo': item.E_MailL,
        },
        "Origen": this.Origen
      }
    } else{
      var socio = {
        'Nombre': item.CardName,
        'Codigo': item.CardCode,
        'Rut': item.LicTradNum,
        'Telefono': item.Phone1,
        'Correo': item.E_Mail,
        'Vendedor': item.SlpCode,
        'VendedorNombre': item.SlpName,
        'GroupCode': item.GroupCode,
        'CardType': item.CardType,
        'U_IND_origenLead': item.U_IND_origenLead,
        //SE AGREGA OCRD.NOTES
        'Notes': item.Notes,
        //Giro = OCRD.NOTES
        // SE AGREGA DIRECCIÓN FACTURACIÓN
        'BillingAddress': {
          'Address': item.Address,
          'Street': item.Street,
          'County': item.County,
          'City': item.City,
          'Country': item.Country,
          'State': item.State,
        },
        // END DIRECCION FACTURACIÓN
        'ContactPersons1': {
            'Name': item.Name,
            'FirstName': item.FirstName,
            'Cargo': item.Position,
            'tel': item.Tel1,
            'correo': item.E_MailL,
        },
        "Origen": this.Origen
      }
    }
    
    const modalRef = this.modalService.open(editaSocioNegocioComponent, ngbModalOptions);
    modalRef.componentInstance.socio = socio;
    modalRef.componentInstance.nuevo = false;
    modalRef.result.then();
    modalRef.result.then(async (data) => {
      if (data) {
        this.UpdateRow(item, data, rowIndex, id);
      } else {
        console.log("Nulo");
      }
    },(error) => {
    });
  }

  nuevoSocio() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };

    var socio = {
      'Nombre': null,
      'Codigo': null,
      'Rut': null,
      'Telefono': null,
      'Correo': null,
      'Vendedor':null,
      'GroupCode':null,
      'CardType': null,
      'U_IND_origenLead':null,
      //SE AGREGA OCRD.NOTES
      'Notes':null,
      //Giro = OCRD.NOTES
      // SE AGREGA DIRECCIÓN FACTURACIÓN
      'BillingAddress': {
          'Address': null,
          'Street': null,
          'County': null,
          'City': null,
          'Country': null,
          'State': null,
      },
      // END DIRECCION FACTURACIÓN
      'ContactPersons1': {
          'Name': null,
          'FirstName': null,
          'Cargo': null,
          'tel': null,
          'correo': null,
      },
      "Origen": this.Origen
    }
    const modalRef = this.modalService.open(editaSocioNegocioComponent, ngbModalOptions);
    modalRef.componentInstance.socio = socio;
    modalRef.componentInstance.nuevo = true;
    // modalRef.componentInstance.CardType = this.CardType;
    // modalRef.closed.subscribe((jsonSocio: any) => {
    //   console.log("FUNCIONA!: "+jsonSocio);
    // });
    modalRef.result.then(async (data) => {
      if (data) {
        var substrAddress = Object.values(Object.values(data)[11])[0];
        if (Object.values(Object.values(data)[11])[0] != null) {
          substrAddress = Object.values(Object.values(data)[11])[0].substring(Object.values(Object.values(data)[11])[0].indexOf(".")+1)
        }
        this.dataJSON = {
          "CardCode": Object.values(data)[3],
          "GroupCode": Object.values(data)[0],
          "CardType": Object.values(data)[1],
          "CardName": Object.values(data)[2],
          "LicTradNum": Object.values(data)[4],
          "Phone1": Object.values(data)[5],
          "E_Mail": Object.values(data)[6],
          "SlpName": Object.values(data)[7],
          "U_IND_origenLead": Object.values(data)[8],
          "Notes": Object.values(data)[9],
          "Address": substrAddress,
          "Street": Object.values(Object.values(data)[11])[1],
          "County": Object.values(Object.values(data)[11])[2],
          "City": Object.values(Object.values(data)[11])[3],
          "Country": Object.values(Object.values(data)[11])[4],
          "State": Object.values(Object.values(data)[11])[5],
          "Name": Object.values(Object.values(data)[12])[5],
          "FirstName": Object.values(Object.values(data)[12])[1],
          "Position":Object.values(Object.values(data)[12])[4],
          "Tel1": Object.values(Object.values(data)[12])[6],
          "E_MailL": Object.values(Object.values(data)[12])[2],
          "SlpCode": Object.values(data)[13]
        }
        this.data.unshift(this.dataJSON);
        // console.log("Address:"+ substrAddress);
        this.data = [ ...this.data ];
        // console.log("DATA"+Object.values(this.dataJSON));
      } else {
        console.log("Nulo");
      }
    },
    (error) => {
    });
  }

  dataJSON: any;

  async UpdateRow(item, data, rowIndex, id) {
    // console.log("ID: "+Object.values(id));
    var substrAddress = Object.values(Object.values(data)[11])[0];
    if (Object.values(Object.values(data)[11])[0] != null) {
      substrAddress = Object.values(Object.values(data)[11])[0].substring(Object.values(Object.values(data)[11])[0].indexOf(".")+1)
    }
    this.data = [
      ...this.data.map((obj, index) => {
        if (Object.values(id)[0] === obj.CardCode) {
          // console.log("index => "+index+" - row id => "+Object.values(id)[0]);
          obj.GroupCode = Object.values(data)[0];
          obj.CardType = Object.values(data)[1];
          obj.CardName = Object.values(data)[2];
          obj.CardCode = Object.values(data)[3];
          obj.LicTradNum = Object.values(data)[4];
          obj.Phone1 = Object.values(data)[5];
          obj.E_Mail = Object.values(data)[6];
          obj.SlpName = Object.values(data)[7];
          obj.U_IND_origenLead = Object.values(data)[8];
          obj.Notes = Object.values(data)[9];
          obj.Address = substrAddress;
          obj.Street = Object.values(Object.values(data)[11])[1];
          obj.County = Object.values(Object.values(data)[11])[2];
          obj.City = Object.values(Object.values(data)[11])[3];
          obj.Country = Object.values(Object.values(data)[11])[4];
          obj.State = Object.values(Object.values(data)[11])[5];
          obj.Name = Object.values(Object.values(data)[12])[5];
          obj.FirstName = Object.values(Object.values(data)[12])[1];
          obj.Position = Object.values(Object.values(data)[12])[4];
          obj.Tel1 = Object.values(Object.values(data)[12])[6];
          obj.E_MailL = Object.values(Object.values(data)[12])[2];
          obj.SlpCode = Object.values(data)[13];

          return { ...obj };
        }
        return obj;
      }),
    ];

  }
}