<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Aprobadores</h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">

        
      <ngx-table #tabla [configuration]="configuration"
        [data]="data.modeloAprobacion.APROBADORES"
        [columns]="columns">
      </ngx-table>
      <ng-template #frmMensaje let-row let-rowIndex="rowIndex">
        <input type="text" class="form-control" [disabled]="validaMensaje(row)" [(ngModel)]="row.MENSAJE">
      </ng-template> 
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-info btn-sm" (click)="cerrar()">Cerrar</button>
  <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
</div> 