import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetoldService} from './../../../api/intranetold.service'
import { StockComercialDetalleItemBodegaComponent } from './detalleArticuloBodega';
import {variableGlobal} from "../../../global.enum";
import Swal from 'sweetalert2'
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-stock-comercial',
  templateUrl: './stock-comercial.component.html',
  styleUrls: ['./stock-comercial.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockComercialComponent implements OnInit {

  constructor(
    private _api:IntranetoldService,
    private modalService: NgbModal,

  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  data:any = [];
  dataCopia:any = [];
  OPLN:any;
  listaPrecio:number = 1;
  listaCategorias:any;
  categoriaSeleccionada:string = "";
  listaFamilia:any;
  familiaSeleccionada:string = "";
  listaSubFamilia:any;
  subFamiliaSeleccionada:string = "";
  public configuration: Config;
  public columns: Columns[];
  ngOnInit(): void {
    this.cargaTabla();
  }
  @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  // SE muestra este mensaje durante mucho tiempo, solucionar
  async cargaTabla()
  {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se esta cargando stock',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
          Swal.showLoading()
      }
  });
    this.columns = [
      { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmItemCode },
      { key: 'ItemName', title: 'Descripción' },
      { key: 'ItmsGrpNam', title: 'Categoria' },
      { key: 'FirmName', title: 'Marca' },
      { key: 'U_IND_SWW',title:'Código Transworld'},
      { key: 'U_IND_CATEGORIA2', title: 'Familia' },
      { key: 'U_IND_CATEGORIA3', title: 'Sub-Familia' },
      { key: 'OnHand', title: 'En stock' },
      { key: 'IsCommited', title: 'NV' },
      { key: 'OnOrder', title: 'Por llegar' },
      { key: 'Available', title: 'Disponible' },
      { key: 'Price', title: 'Precio', cellTemplate: this.frmPrice }
    ];

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };

    var data:any = await this._api.getQueryAll();
    var query:string = "";

    await data.objeto.forEach(element => {
      // if (element.NOMBRE == "stockComercial") {
        if(element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
      }
    });

    this.data = await this._api.GetNotFileQuery(query);
    this.dataCopia = this.data.objeto;
    // console.log("PRECIO CLP: "+ this.dataCopia[120].Price);
    console.log(data);
    console.log(this.dataCopia);
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaPrecios") {
        query = element.CONSULTA;
      }
    });
    var datos:any = await this._api.GetNotFileQuery(query);
    this.OPLN = datos.objeto;

    console.log("datos objeto",datos.objeto)

    let collator = new Intl.Collator()

    var distinct = this.dataCopia
                 .map(item => item.ItmsGrpNam)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaCategorias = distinct;

    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA2)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaFamilia = distinct;
    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA3)
                 .filter((value, index, self) => self.indexOf(value) === index);

    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b)); //error posible acá
    this.listaSubFamilia = distinct;

    this.data = [...this.dataCopia];

    //console.log(this.data);
    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    Swal.close();
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  AbrirDetalleItem(item)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(StockComercialDetalleItemBodegaComponent, ngbModalOptions);
    modalRef.componentInstance.ItemCode = item.ItemCode;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }

  limpiar()
  {
    this.data = [...this.dataCopia];
    //this.listaPrecio = 1;
    this.categoriaSeleccionada = "";
    this.familiaSeleccionada = "";
    this.subFamiliaSeleccionada = "";
  }

  filtro()
  {
    this.data = [...this.dataCopia];
    //ItmsGrpNam              categoriaSeleccionada
    //U_IND_CATEGORIA2        familiaSeleccionada
    //U_IND_CATEGORIA3        subFamiliaSeleccionada
    if (this.categoriaSeleccionada) {
      this.data = this.data.filter((_) => _.ItmsGrpNam == this.categoriaSeleccionada);
    }
    if (this.familiaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA2 == this.familiaSeleccionada);
    }
    if (this.subFamiliaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA3 == this.subFamiliaSeleccionada);
    }
  }
  async cambiaPrecio()
  {
    Swal.fire({
        title: 'Espere por favor!',
        html: 'Se esta cargando lista de precio',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading()
        }
    });
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
      }
    });
    query = query.replace("[PriceList] = 1", "[PriceList] = " + this.listaPrecio);
    console.log(query);
    var datos:any = await this._api.GetNotFileQuery(query);
    this.data = datos.objeto;
    this.dataCopia = [...this.data];
    this.filtro();
    Swal.close();

  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.data);
  }

}
