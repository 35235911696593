import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  Columns,
  Config,
  DefaultConfig,
  APIDefinition,
  API,
} from "ngx-easy-table";
import { ActivatedRoute, Router } from "@angular/router";
import { variableGlobal } from "src/app/global.enum";
import { IntranetoldService } from "src/app/api/intranetold.service";
import { PickingComponent } from "../preparar-pedido/picking/picking.component";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material/dialog";
import { array, object } from "@amcharts/amcharts4/core";
import { IndesadService } from "src/app/api/indesad.service";
import { MongoService } from "src/app/api/mongo.service";
import { VistaNvComponent } from "../vista-nv/vista-nv.component";
import { WMSService } from "src/app/api/wms.service";
import { ImprimibleComponent } from "../layout/imprimible/imprimible.component";
import { indexOf } from "@amcharts/amcharts4/.internal/core/utils/Array";

@Component({
  selector: "app-asignar-pedido",
  templateUrl: "./asignar-pedido.component.html",
  styleUrls: ["./asignar-pedido.component.css"],
})
export class AsignarPedidoComponent implements OnInit {
  public configuration: Config;
  public columns: Columns[];
  newdata = [];
  WMS;
  data = [];
  dataFiltrada = [];
  result: any = [];
  tipo = "";
  titulo = "";
  ValoresValidosPorFiltrar: any;
  usuarios: any = [];
  checked = new Set([]);
  WMSaccion:any;
  @ViewChild("table", { static: true }) table: APIDefinition;
  @ViewChild("frmDueDate", { static: true }) frmDueDate: TemplateRef<any>;
  @ViewChild("frmTotal", { static: true }) frmTotal: TemplateRef<any>;
  @ViewChild("frmDocNum", { static: true }) frmDocNum: TemplateRef<any>;
  @ViewChild("frmAccion", { static: true }) frmAccion: TemplateRef<any>;
  constructor(
    private modalService: NgbModal,
    private _activeRoute: ActivatedRoute,
    private _api: IntranetoldService,
    private _apiMongo: IndesadService,
    public dialog: MatDialog,
    private _apiWMS: WMSService,
  ) { }

  ngOnInit(): void {
    this.cargaInicial();
  }

  async cargaInicial() {
    this.columns = [
      { key: "DocNum", title: "N° Doc", cellTemplate: this.frmDocNum },
      { key: "ESTADO_WMS", title: "ESTADO WMS" },
      { key: "CardCode", title: "Codigo SN" },
      { key: "CardName", title: "Socio" },
      { key: "Address2", title: "Despacho" },
      {
        key: "DocDueDate",
        title: "Fecha vencimiento",
        cellTemplate: this.frmDueDate,
      },
      { key: "DocTotal", title: "Total", cellTemplate: this.frmTotal },
      { key: "", title: "Asignar", cellTemplate: this.frmAccion },
    ];


    const path = this._activeRoute.snapshot.url.map(segment => segment.path);
    console.log("PATH", path)
    let WMS = "";
    path.forEach(element => {
      if (element == "WMSInventario") {
        WMS = element;
        this.WMSaccion = element;
      }
      else if (element == "WMSCompra") {
        WMS = element;
        this.WMSaccion = element;
      }
      else if (element == "WMS") {
        WMS = element;
        this.WMSaccion = element;
      }
    });

    if (WMS == "WMSInventario") {
      this.columns = [
        { key: "DocNum", title: "N° Doc", cellTemplate: this.frmDocNum },
        { key: "ESTADO_WMS", title: "ESTADO WMS" },
        { key: "CardCode", title: "Codigo SN" },
        { key: "FromWhsCod", title: "Bodega Origen" },
        { key: "WhsCode", title: "Bodega Destino" },
        {
          key: "DocDueDate",
          title: "Fecha vencimiento",
          cellTemplate: this.frmDueDate,
        },
        { key: "DocTotal", title: "Total", cellTemplate: this.frmTotal },
        { key: "", title: "Asignar", cellTemplate: this.frmAccion },
      ];
      var query = (await this.obtieneQueryMongo("Inventario_WMS"));

    }
    if (WMS == "WMSCompra") {
      this.columns = [
        { key: "DocNum", title: "N° Doc", cellTemplate: this.frmDocNum },
        { key: "ESTADO_WMS", title: "ESTADO WMS" },
        { key: "CardCode", title: "Codigo SN" },
        { key: "WhsCode", title: "Bodega" },
        { key: "CardName", title: "Socio" },
        { key: "Address2", title: "Despacho" },
        {
          key: "DocDueDate",
          title: "Fecha vencimiento",
          cellTemplate: this.frmDueDate,
        },
        { key: "DocTotal", title: "Total", cellTemplate: this.frmTotal },
        { key: "", title: "Asignar", cellTemplate: this.frmAccion },
      ];
      var query = (await this.obtieneQueryMongo("Compra_WMS"));
    }
    if (WMS == "WMS") {
      var query = await this.obtieneQueryMongo("VST_WMS");
    }


    var data: any = await this._api.GetNotFileQuery(query);
    console.log('data ---', data)
    query = await this.obtieneQueryMongo("ValoresValidosPorFiltrar");
    this.ValoresValidosPorFiltrar = await this._api.GetNotFileQuery(query);


    this.ValoresValidosPorFiltrar = this.ValoresValidosPorFiltrar.objeto.filter(
      (element) =>
        element.TableID == "ORDR" && element.AliasID == "IND_ESTADO_WMS"
    );

    this.data = data.objeto.forEach((element) => {
      this.ValoresValidosPorFiltrar.forEach((item) => {

        if (!element.U_IND_ESTADO_WMS) {
          element.U_IND_ESTADO_WMS = "-";
        }

        if (element.U_IND_ESTADO_WMS == item.FldValue) {
          element.ESTADO_WMS = item.Descripcion_valor;
        }

      });
    });


    this.data = data.objeto.filter(
      (element) => element.U_IND_ESTADO_WMS != "-"
    );


    let Unicos = this.eliminarDuplicados( this.data, 'DocEntry');

    console.log(Unicos);
    this.data = [...Unicos]

    console.log("DATOS QUE TENGO QUE VER", this.data);


    //console.log("Valores validos por filtrar", this.ValoresValidosPorFiltrar);
    //console.log("DATA OBJETO ASDASDSA", this.data);

    this.columns.forEach((element) => {
      this.checked.add(element.key);
    });

    this.obtieneTitulo();

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };
    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    await this.getUsuarios();

    //console.log("GET USUARIOS",this.usuarios);
    // console.log("USUARIOS:  \n");
    // console.log(this.usuarios);

    switch (this.tipo) {
      case 'asignar':


        this.data.forEach(element => {
          if (element.U_IND_ESTADO_WMS == 'SA' || element.U_IND_ESTADO_WMS == 'AS') {
            this.newdata.push(element)
          }
        });

        this.data = this.newdata;
        console.log("CASE ASIGNAR")

        break;

      case 'preparar':

        this.data.forEach(element => {
          if (element.U_IND_ESTADO_WMS == 'AS') {
            this.newdata.push(element)
          }
        });

        this.data = this.newdata;

        console.log("CASE PREPARAR")


        break;

      case 'generardoc':

        this.data.forEach(element => {
          if (element.U_IND_ESTADO_WMS == 'EA' || element.U_IND_ESTADO_WMS == 'AP') {
            this.newdata.push(element)
          }
        });

        this.data = this.newdata;

        console.log("CASE GENERARDOC")


        break;


    }



    this.dataFiltrada = this.data;

  }
  async getUsuarios() {
    var res: any = await this._api.getAllLogin();
    var encargados: any = await this._apiWMS.GetGrupo("Responsable");
    //console.log(encargados);
    // if (encargados.estado) {
    this.usuarios = encargados.objeto;
    // }
  }
  obtieneTitulo() {
    this.tipo = this._activeRoute.snapshot.paramMap.get("tipo");
    //console.log(this.tipo);

    if (this.tipo == "asignar") {
      console.log("Dice asignar");
      this.titulo = "Asignacion de pedidos";
    }
    if (this.tipo == "generar") {
      this.titulo = "generar pedidos";
    }
    if (this.tipo == "pre-asignar") {
      this.titulo = "pre-asignacíon de pedidos";
    }
    if (this.tipo == "preparar") {
      this.titulo = "Preparacion de pedidos";
      // this.ocultaColumna("");
    }
    if (this.tipo == "generardoc") {
      this.titulo = "Generar Documento";
      // this.ocultaColumna("");
    }
    if (this.tipo == "transferenciastock") {
      this.titulo = "Transferencia Stock";
      // this.ocultaColumna("");
    }

  }

  ocultaColumna(name: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //
    this.checked.has(name) ? this.checked.delete(name) : this.checked.add(name);
    this.columns = this.columns.filter((column) =>
      this.checked.has(column.key)
    );
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  async OpenDoc(row: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,

    };

    const modalRef = this.modalService.open(VistaNvComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.titulo = this.tipo;
    modalRef.componentInstance.ESTADO_WMS = row.U_IND_ESTADO_WMS


    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {

      this.data.forEach(element => {
        if (element.DocEntry == row.DocEntry) {
          element.U_IND_ESTADO_WMS = data
        }
      });
    });
  }



  async GenerarDoc(Encabezado) {



    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };




    //console.log("DATA PICKING",this.DataPicking)

    const modalRef = this.modalService.open(VistaNvComponent, ngbModalOptions);
    modalRef.componentInstance.data = Encabezado;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {

    });

  }



  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach((element) => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  async abrirPicking(Encabezado) {
    var doc = await this.CargarDatos(Encabezado.DocEntry);

    //console.log(doc);
    //console.log(Encabezado);

    var config = {
      height: "100vh",
      width: "100vw",
      disableClose: true,
      panelClass: 'custom-dialog-class',
      data: {
        DocEntry: Encabezado.DocEntry,
        DocNum: Encabezado.DocNum,
        ObjType: Encabezado.ObjType,
        Document: doc,
        WMSaccion: this.WMSaccion,
        ESTADO_WMS: Encabezado.U_IND_ESTADO_WMS
      },
    };

    const dialogRef = this.dialog.open(PickingComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  async OpenModalV2(datos) {
    var query: any = await this.obtieneQueryMongo("loteStockBodega");
    query = query + "WHERE [ItemCode] = ' " + +"'";
    var lotes = await this._api.GetNotFileQuery(query);
  }

  async CargarDatos(DocEntry) {
    var query = await this.obtieneQueryMongo("WMS_NV_DET");
    query = query.replace("NV", DocEntry);
    var respuesta: any = await this._api.GetNotFileQuery(query);
    var Document = respuesta.objeto;

    //console.log(query);
    //console.log(respuesta);
    return Document;
  }

  async UpdateResponsable(fila, event) {
    // console.log(event.target.value);
    let body = {
      DocEntry: fila.DocEntry,
      ObjType: fila.ObjType,
      responsable: event.target.value,
    };

    await this._apiWMS.AsignarResponsable(body);
  }

  getNombreResponsable(rut) {
    let nombre: any;
    this.usuarios.forEach(element => {
      if (element.RUT == rut) {
        nombre = element.NOMBRE
      }
    });
    return nombre;
  }

  async Imprimible(Encabezado) {



    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };



    //console.log("DATA PICKING",this.DataPicking)

    const modalRef = this.modalService.open(ImprimibleComponent, ngbModalOptions);
    modalRef.componentInstance.data = Encabezado;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => { });

  }
  imprimir(item: any) {
    console.log(item);
    //  window.print();
    var url = "/WMSEntregable/" + item.DocEntry + "/" + item.ObjType;
    console.log(url);
    // var url = "/Layoult/Venta/" + this.DocEntry + "/" + this.ObjType;
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  eliminarDuplicados(arr, prop) {
    return arr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

}
