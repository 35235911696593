import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle, DocumentoMarting, Lote} from "../../../model/ventaModel"
import Swal from 'sweetalert2'
import {variableGlobal} from "../../../global.enum";



@Component({
  selector: 'app-abreTablaGenerico',
  templateUrl: './abreTablaGenerico.html',
  styleUrls: ['./query-mongo.component.sass']
})
export class abreTablaGenericoComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService
  ) { }

  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  columnsCopy: Columns[] = [];
  data:any = [];
  query:string;
  titulo:string;
  ngOnInit(): void {

    this.cargaInicial();
  }


  async cargaInicial()
  {


    this.columns = [];

    var dato:any = await this._api.GetNotFileQuery(this.query);
    console.log("QUERY",dato)

    if (dato.estado) {

      console.log(dato.objeto[0]);
      var arregloNombre = Object.keys(dato.objeto[0]);
      arregloNombre.forEach(element => {
        var columna = { key: element, title: element};
        this.columns.push(columna);
      });

      this.data = dato.objeto;
      this.configuration = {
        ...DefaultConfig,
        searchEnabled: true,
        detailsTemplate: true,
        paginationRangeEnabled: true,
        paginationEnabled:true,
        horizontalScroll: true,
        resizeColumn: true,
        orderEnabled: false,
        rowReorder:false,
        columnReorder:false,
        fixedColumnWidth: true,
     };
    }
    else
    {
      Swal.fire('Mensaje',dato.mensaje, 'error');
    }

    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  cerrar() {
    this.activeModal.close();
  }







}
