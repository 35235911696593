import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SapService } from 'src/app/api/sap.service';
import { variableGlobal } from 'src/app/global.enum';
import { CrearMaestroArticuloComponent } from './crear-maestro-articulo/crear-maestro-articulo.component';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { NgxIndexedDBService } from 'ngx-indexed-db';


@Component({
  selector: 'app-maestro-articulo',
  templateUrl: './maestro-articulo.component.html',
  styleUrls: ['./maestro-articulo.component.css']
})
export class MaestroArticuloComponent implements OnInit {

  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  public configuration: Config;
  public columns: Columns[];
  AllMaestroArticulos: any;

  data = [];
  articuloeliminar: any;
  administrador: any;


  private setClass(name: string): void {
    if (this.table && this.table.apiEvent) {
      this.table.apiEvent({
        type: API.setTableClass,
        value: name,
      });
    }
  }


  eliminarMT(row) {
    this.articuloeliminar = row
  }

  async confirmareliminar() {
    await this._sap.EliminarMT(this.articuloeliminar)
    location.reload()
  }

  constructor(
    private modalService: NgbModal,
    private _sap: SapService,
    private _dbService: NgxIndexedDBService,

  ) { }

  ngOnInit(): void {
    this._dbService.getByID("general", "login").subscribe((res: any) => {
      console.log(res)
      if (typeof res === 'object' && res !== null) {
        this.administrador = res.datos.login.OITM_SAP;
        console.log("|" + res.datos.login.OITM_SAP + "|");
      }
    });
    this.cargaInicial();
  }


  async cargaInicial() {

    this.AllMaestroArticulos = await this._sap.ObtenerMaestroTarifarios();


    console.log("ALL MAESTRO TARIFARIO", this.AllMaestroArticulos);


    this.columns = [

      { key: 'ItemCode', title: 'N° Artículo' },
      { key: 'ItemName', title: 'Descripción' },
      { key: 'ItmsGrpNam', title: 'Grupo de artículos' },
      { key: 'OnHand', title: 'Stock' },
      { key: 'U_IND_CATEGORIA2', title: 'Familia' },
      { key: 'U_IND_CATEGORIA3', title: 'Sub-Familia' },
      { key: 'U_IND_SWW', title: 'Código Antiguo' },
      { key: '', title: 'Asignar', cellTemplate: this.frmAccion },


    ];

    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }


    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };

    this.data = this.AllMaestroArticulos.resultado;
  }


  crearMaestroArticulo() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(CrearMaestroArticuloComponent, ngbModalOptions);
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.componentInstance.crear = true;

    modalRef.result.then(async (data) => { });
  }

  async editarMaestroArticulo(row) {
    console.log("row ----",row)
    let itemMT = await this._sap.ObtenerItemMT(row.ItemCode)
    console.log("itemMT---", itemMT)
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(CrearMaestroArticuloComponent, ngbModalOptions);
    modalRef.componentInstance.data = itemMT;
    modalRef.componentInstance.crear = false;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => { });
  }




}
