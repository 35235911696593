import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pistoleo-ubicacion',
  templateUrl: './pistoleo-ubicacion.component.html',
  styleUrls: ['./pistoleo-ubicacion.component.css']
})
export class PistoleoUbicacionComponent implements OnInit {

  rowDetalle:any;
  ubicaciondata:any;
  searchCodigoLoteSerie: any;
  searchCheck: any;
  WMSaccion;
  loteoserie;

  newloteserie = {
    "Checked": Boolean,
    "SysNumber": 0,
    "CodigoLoteSerie": "",
    "BatchNumber": "",
    "Quantity": 0,
    "Original": 0,
    "Pickeada": 0
}
  constructor(public activeModal: NgbActiveModal) { }

  cargaInicial(){
    console.log("DEBUG CARGA INICIAL");
    console.log(this.loteoserie);

    if (this.loteoserie == 'lote') {
      this.ubicaciondata.LOTE = [];
      this.rowDetalle.UBICACION.forEach(element => {

        element.LOTE.forEach(item => {
          if (element.AbsEntry == this.ubicaciondata.AbsEntry) {
            this.ubicaciondata.LOTE.push(item)
          }

        });

      });
      
    }

  }

  async ngOnInit(): Promise<void> {

    await this.cargaInicial();
    console.log("WMSaccion",this.WMSaccion);
    console.log("loteoserie",this.loteoserie);

    console.log("UBICACIÓN DATA",this.ubicaciondata);
    console.log("ROW DETALLE",this.rowDetalle);
    /*
    this.ubicaciondata.SERIE.forEach(element => {
      element.checked = false;
    });
    */

  }

  cerrar() {
    this.activeModal.close();
  }

  filterFunction(item: any) {
    return (
      (!this.searchCodigoLoteSerie || item.CodigoLoteSerie.toLowerCase().indexOf(this.searchCodigoLoteSerie.toLowerCase()) !== -1) &&
      (!this.searchCheck || item.email.toLowerCase().indexOf(this.searchCheck.toLowerCase()) !== -1)
    );
  }

  search() {
    this.searchCodigoLoteSerie = this.searchCodigoLoteSerie.trim();
  }



async PasarSeriesLote(){


  if (this.newloteserie.CodigoLoteSerie && this.newloteserie.Pickeada) {

    if (this.loteoserie == 'lote') {
      this.rowDetalle.UBICACION_OnHand.forEach(element => {

        if (element.BinCode == this.ubicaciondata.BinCode) {

          element.LOTE.push(this.newloteserie)
            
         console.log("ELEMENT",element)

        }
        
      });
    }


    this.newloteserie = {
      "Checked": Boolean,
      "SysNumber": 0,
      "CodigoLoteSerie": "",
      "BatchNumber": "",
      "Quantity": 0,
      "Original": 0,
      "Pickeada": 0
  }
  }
 
  this.rowDetalle.UBICACION = [];
  this.rowDetalle.Quantity = 0;
  //console.log("detalle", this.rowDetalle);
  await this.rowDetalle.UBICACION_OnHand.forEach(async ubicacion => {
    var ubicacionClon = {...ubicacion};

    if (this.rowDetalle.ManSerNum) {

      ubicacionClon.SERIE = [];
      ubicacionClon.Pickeada = 0;
      await ubicacion.SERIE.forEach(async rowSerie => {
        rowSerie.Pickeada = 0;

        if (rowSerie.Checked) {
          rowSerie.Pickeada = 1;
          ubicacionClon.Pickeada = ubicacionClon.Pickeada + 1;
          this.rowDetalle.Quantity = this.rowDetalle.Quantity + 1;

          ubicacionClon.SERIE.push(rowSerie);

        }

      });
    }
    else if (this.rowDetalle.ManBtchNum) {
      //console.log(this.rowDetalle);
      ubicacionClon.LOTE = [];
      ubicacionClon.Pickeada = 0;
      await ubicacion.LOTE.forEach(async rowLote => {
        //rowLote.Pickeada = 0;
        rowLote.Quantity = rowLote.Original;
        if (rowLote.Pickeada > 0) {
      
          ubicacionClon.Pickeada = ubicacionClon.Pickeada + rowLote.Pickeada;
          this.rowDetalle.Quantity =  this.rowDetalle.Quantity + rowLote.Pickeada;

          if (this.WMSaccion == "WMSCompra") {
            rowLote.Quantity = rowLote.Original + rowLote.Pickeada;

          }else{
            rowLote.Quantity = rowLote.Original - rowLote.Pickeada;

          }
          
          

          ubicacionClon.LOTE.push(rowLote);
        }
      });
    }


    if (ubicacionClon.LOTE.length > 0) {
      this.rowDetalle.UBICACION.push(ubicacionClon);
    }
    if (ubicacionClon.SERIE.length > 0) {
      this.rowDetalle.UBICACION.push(ubicacionClon);
    }
  });


  //console.log("detalle2", this.rowDetalle);

}

async recorreUbicacion(){
  
}



}
