import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { CambioPwComponent } from './login/cambio-pw/cambio-pw.component';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './lte/navbar/navbar.component';
import { IframeComponent } from './lte/iframe/iframe.component';
import { InicioComponent } from './lte/inicio/inicio.component';
import { SidebarComponent } from './lte/sidebar/sidebar.component';
import { FooterComponent } from './lte/footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { variableGlobal } from './global.enum';
import { UserComponent } from './intranet/configuracion/user/user.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListFilterPipe } from './list-filter.pipe';
import { UserAddEditComponent } from './intranet/configuracion/user/addEdit';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { DataTablesModule } from "angular-datatables";
import { PerfilComponent } from './intranet/configuracion/perfil/perfil.component';
import { PerfilAddEditComponent } from './intranet/configuracion/perfil/addEdit';
import { CorreoAddEditComponent } from './intranet/configuracion/correo-etapa/addEdit';

import { NgxEasyTableComponent } from './intranet/test/ngx-easy-table/ngx-easy-table.component';
import { TableModule } from 'ngx-easy-table';
import { CorreoEtapaComponent } from './intranet/configuracion/correo-etapa/correo-etapa.component';
import { AsignacionVendedorComponent } from './intranet/crm/asignacion-vendedor/asignacion-vendedor.component';
import { AsignacionVendedorAddEditComponent } from "./intranet/crm/asignacion-vendedor/addEdit";
import { VentaComponent } from './intranet/crm/venta/venta.component'
import { listaArticuloComponent } from './intranet/crm/venta/listaArticulo';
import { QueryComponent } from './intranet/configuracion/query/query.component'
import { listaClienteComponent } from './intranet/crm/venta/listaCliente';
import { listaDireccionComponent } from './intranet/crm/venta/listaDireccion';
import { listaLoteComponent } from './intranet/crm/venta/listaLote';
import { listaSerieComponent } from './intranet/crm/venta/listaSerie';
import { SeguimientoComponent } from './intranet/crm/seguimiento/seguimiento.component';
import { LayoutVentaComponent } from './intranet/layout/venta/layoult-venta.component';
import { VentaLayaultModelComponent } from './intranet/layout/venta/venta-layault-model';
import { INDCLPPipe } from './indclp.pipe';
import { StockComercialComponent } from './intranet/crm/stock-comercial/stock-comercial.component';
import { StockComercialDetalleItemComponent } from './intranet/crm/stock-comercial/detalleArticulo';
import { StockComercialDetalleItemBodegaComponent } from './intranet/crm/stock-comercial/detalleArticuloBodega';
import { IFramePrincipalComponent } from './intranet/iframe/i-frame-principal/i-frame-principal.component';
import { SocioNegocioComponent } from './intranet/crm/socio-negocio/socio-negocio.component';
import { QueryMongoComponent } from './intranet/app/query-mongo/query-mongo.component';
import { abreTablaGenericoComponent } from './intranet/app/query-mongo/abreTablaGenerico';
import { editaSocioNegocioComponent } from './intranet/crm/socio-negocio/editaSocioNegocio';
import { SeguimientoTodoComponent } from './intranet/app/seguimiento-todo/seguimiento-todo.component';
import { CalendarioComponent } from './intranet/crm/calendario/calendario.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MaestroBusinessPartnerComponent } from './intranet/sap/BusinessPartnet/maestro-business-partner.component';
import { MaestroBusinessPartnerComponentV2 } from './intranet/BusinessPartner/maestroBusinessPartner.component';
import { clienteModelComponent } from './intranet/sap/BusinessPartnet/cliente-model';
import { envioCorreoSeguimientoaComponent } from './intranet/crm/seguimiento/envio-correo';
import { ModeloAprobacionComponent } from "./intranet/configuracion/modeloAprobacion/modeloAprobacion.component"
import { ModeloAprobacionAddEditComponent } from "./intranet/configuracion/modeloAprobacion/addEdit"
import { DocumentoPreliminalComponent } from "./intranet/crm/documentoPreliminal/documentoPreliminal.component"
import { DetalleDocumentoPreliminalComponent } from "./intranet/crm/documentoPreliminal/detalleDocumentoPreliminal/detalleDocumentoPreliminal.component"
import { VerAprobacionComponent } from "./intranet/crm/documentoPreliminal/verAprobacion/verAprobacion.component"
import { VerAprobadoresComponent } from "./intranet/crm/documentoPreliminal/verAprobacion/verAprobadores/verAprobadores.component"

//calendario
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!


//ckeditor
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CuentasSocioComponent } from './intranet/crm/venta/cuentas-socio/cuentas-socio.component';
import { InformeComponent } from './intranet/crm/informe/informe.component';
import { ModeloAprobacionCRMComponent } from './intranet/crm/venta/ModeloAprobacion/ModeloAprobacion.component';
import { OITMComponent } from './intranet/crm/stock-comercial/OITM/OITM.component';

// cache
import { CacheInterceptor } from './http-interceptors/CacheInterceptor';
import { GraficosComponent } from './intranet/graficos/graficos.component';
import { StockdisponibleComponent } from './intranet/wms/stockdisponible/stockdisponible.component';
import { PreasignarPedidoComponent } from './intranet/wms/preasignar-pedido/preasignar-pedido.component';
import { AsignarPedidoComponent } from './intranet/wms/asignar-pedido/asignar-pedido.component';
import { PrepararPedidoComponent } from './intranet/wms/preparar-pedido/preparar-pedido.component';
import { GenerarDocumentoComponent } from './intranet/wms/generar-documento/generar-documento.component';
import { GraficosInformedeventasComponent } from './intranet/graficos-informedeventas/graficos-informedeventas.component';
import { MetasVendedoresComponent } from './intranet/configuracion/metas-vendedores/metas-vendedores.component';
import { CrearmetavendedoresComponent } from './intranet/configuracion/metas-vendedores/crear/crearmetavendedores/crearmetavendedores.component';
import { EditarmetavendedoresComponent } from './intranet/configuracion/metas-vendedores/editar/editarmetavendedores/editarmetavendedores.component';
import { PickingComponent } from './intranet/wms/preparar-pedido/picking/picking.component';
import { VistaNvComponent } from './intranet/wms/vista-nv/vista-nv.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

//material-angular
import { MatTableModule } from '@angular/material/table';
import { CrearMaestroArticuloComponent } from './intranet/inventario/maestro-articulo/crear-maestro-articulo/crear-maestro-articulo.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MetasCRUDComponent } from './intranet/crm/venta/metas-crud/metas-crud.component';
import { CrearEditarMetaComponent } from './intranet/crm/venta/metas-crud/crear-editar-meta/crear-editar-meta.component';
import { MaestroArticuloComponent } from './intranet/inventario/maestro-articulo/maestro-articulo.component';
import { BuscarCardCodeComponent } from './intranet/inventario/buscar-card-code/buscar-card-code.component';
import { ImprimibleComponent } from './intranet/wms/layout/imprimible/imprimible.component';
import { PistoleoUbicacionComponent } from './intranet/wms/preparar-pedido/pistoleo/pistoleo-ubicacion/pistoleo-ubicacion.component';
import { SeleccionarSerieLoteComponent } from './intranet/wms/preparar-pedido/picking/seleccionar-serie-lote/seleccionar-serie-lote.component';
import { PistoleoComponent } from './intranet/wms/preparar-pedido/pistoleo/pistoleo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SeleccionarUbicacionComponent } from './intranet/wms/preparar-pedido/picking/seleccionar-ubicacion/seleccionar-ubicacion.component';
import { SeleccionarLineaComponent } from './intranet/wms/preparar-pedido/picking/seleccionar-linea/seleccionar-linea.component';
import { FilterPipe } from './intranet/wms/preparar-pedido/picking/seleccionar-serie-lote/filter.pipe';
import { DescargarInformeVentaComponent } from './intranet/crm/descargar-informe-venta/descargar-informe-venta.component';

import {MatListModule} from '@angular/material/list';
import { DetallepickeoComponent } from './intranet/wms/vista-nv/detallepickeo/detallepickeo.component';
import { CargandoComponent } from './cargando/cargando.component';
import { GenerarDocumentoModalComponent } from './intranet/wms/vista-nv/generar-documento-modal/generar-documento-modal.component';
import { ArticuloTemoralComponent } from './intranet/inventario/maestro-articulo/articulo-temoral/articulo-temoral.component';


const dbConfig: DBConfig = {
  name: variableGlobal.DB_NAME,
  version: 1,
  objectStoresMeta:
    [
      {
        store: variableGlobal.DB_TABLE_GENERAL,
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: []
      }
    ],
  // provide the migration factory to the DBConfig
  //migrationFactory
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({

  declarations: [
    GenerarDocumentoModalComponent,
    GenerarDocumentoComponent,
    DetallepickeoComponent,
    AppComponent,
    LoginComponent,

    NavbarComponent,
    IframeComponent,
    InicioComponent,
    SidebarComponent,
    FooterComponent,
    UserComponent,
    UserAddEditComponent,
    ListFilterPipe,
    PerfilComponent,
    PerfilAddEditComponent,
    NgxEasyTableComponent,
    CorreoEtapaComponent,
    CorreoAddEditComponent,
    AsignacionVendedorComponent,
    AsignacionVendedorAddEditComponent,
    VentaComponent,
    listaArticuloComponent,
    QueryComponent,
    listaClienteComponent,
    listaDireccionComponent,
    listaLoteComponent,
    listaSerieComponent,
    SeguimientoComponent,
    LayoutVentaComponent,
    VentaLayaultModelComponent,
    INDCLPPipe,
    StockComercialComponent,
    StockComercialDetalleItemComponent,
    StockComercialDetalleItemBodegaComponent,
    IFramePrincipalComponent,
    SocioNegocioComponent,
    QueryMongoComponent,
    abreTablaGenericoComponent,
    editaSocioNegocioComponent,
    SeguimientoTodoComponent,
    CalendarioComponent,
    MaestroBusinessPartnerComponent,
    MaestroBusinessPartnerComponentV2,
    clienteModelComponent,
    envioCorreoSeguimientoaComponent,
    ModeloAprobacionComponent,
    ModeloAprobacionAddEditComponent,
    DocumentoPreliminalComponent,
    CuentasSocioComponent,
    InformeComponent,
    ModeloAprobacionCRMComponent,
    OITMComponent,
    DetalleDocumentoPreliminalComponent,
    VerAprobacionComponent,
    VerAprobadoresComponent,
    GraficosComponent,
    StockdisponibleComponent,
    PreasignarPedidoComponent,
    AsignarPedidoComponent,
    PrepararPedidoComponent,
    GenerarDocumentoComponent,
    GraficosInformedeventasComponent,
    MetasVendedoresComponent,
    CrearmetavendedoresComponent,
    EditarmetavendedoresComponent,
    CambioPwComponent,
    PickingComponent,
    VistaNvComponent,
    MaestroArticuloComponent,
    CrearMaestroArticuloComponent,
    MetasCRUDComponent,
    CrearEditarMetaComponent,
    BuscarCardCodeComponent,
    ImprimibleComponent,
    PistoleoUbicacionComponent,
    SeleccionarSerieLoteComponent,
    PistoleoComponent,
    SeleccionarUbicacionComponent,
    SeleccionarLineaComponent,
    FilterPipe,
    DescargarInformeVentaComponent,
    CargandoComponent,
    ArticuloTemoralComponent

  ],
  imports: [
    MatListModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    ReactiveFormsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgbModule,

    MatDialogModule,
    DataTablesModule,
    TableModule,

    NgbModalModule,
    FullCalendarModule, // register FullCalendar with you app
    CKEditorModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    CommonModule,
    MatExpansionModule,
    MatSidenavModule,

  ],
  providers: [CalendarioComponent,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  exports: [
    CommonModule
  ],

})
export class AppModule { }
