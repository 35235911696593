import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detallepickeo',
  templateUrl: './detallepickeo.component.html',
  styleUrls: ['./detallepickeo.component.css']
})
export class DetallepickeoComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    ) { }

  filtro:any;
  filtro2:any;
  data:any


  ngOnInit(): void {

    console.log("ROW LLEGADA",this.data)

    this.filtrodemodelo();

    console.log("FILTRO1",this.filtro,"FILTRO2",this.filtro2)



  }

  filtrodemodelo(){
    if (this.data.BinLocationActive) {
      this.filtro = "UBICACION"
    }
    else if(this.data.ManBtchNum && !this.data.BinLocationActive){
      this.filtro = "LOTE"
    }
    else if(this.data.ManSerNum && !this.data.BinLocationActive){
      this.filtro = "SERIE"
    }
    else{
      this.filtro = null
    }

    if (this.data.BinLocationActive && this.data.ManBtchNum) {
      this.filtro2 = "LOTE"
    }
    else if (this.data.BinLocationActive && this.data.ManSerNum){
      this.filtro2 = "SERIE"
    }
    else{
      this.filtro2 = null
    }
  }



  cerrar() {
    this.activeModal.close();
  }


}
