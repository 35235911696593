<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <label>Debe seleccionar la linea del articulo para que se visuzalicen las series</label>
        <div class="col-12">
            <ngx-table
                #table 
                [configuration]="configurationGeneral"
                [data]="dataGeneral"
                [columns]="columnsGeneral"
                (event)="eventEmittedGeneral($event)"
            ></ngx-table>
        </div>

    </div>
    <div class="row">


        <div class="col-6">
            <ngx-table
                #tablaStock
                [configuration]="configurationStock"
                [data]="dataStock"
                [columns]="columnsStock"
                
            ></ngx-table>
            <ng-template #frmCantidadSeleccionada let-row let-rowIndex="rowIndex">
                <input type="number" step="1" max="1" min="0" class="form-control" [(ngModel)]="row.CantidadSeleccionada" name="CantidadSeleccionada" (change)="actualizaStock(rowIndex)">
              </ng-template>
        </div>
        <div class="container col-md-1">
            <div class="vertical-center">
                <button class="btn-primary vertical-center" (click)="agregaDatosLote()"><i class="fas fa-arrow-right"></i></button>
            </div>
        </div>
        <div class="col-5">
            <ngx-table
            #tableLotePickeado
            [configuration]="configurationPicking"
            [data]="dataPicking"
            [columns]="columnsPicking"
        ></ngx-table>
        <ng-template #frmAccionPicking let-row let-rowIndex="rowIndex">
            <button type="button" class="btn btn-danger" [disabled]="row.LineNum > -1" (click)="eliminaRowPicking(rowIndex)"><i class="fas fa-trash-alt"></i></button>
          </ng-template>
        </div>
    </div>



  </div>
  <div class="modal-footer">
    <button (click)="Seleccionar()" type="button" class="btn btn-success">Guardar</button>
  </div> 