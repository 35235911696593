<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titulo}}</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     

    <div class="form-group">
        <label>Etapa</label>

            
            <select [(ngModel)]="data.Etapa" name="Etapa" class="form-control">
                <option [ngValue]="null" disabled>Selecciona etapa</option>
                <option *ngFor="let datos of etapas" [ngValue]="datos.Num">{{datos.Descript}}</option>
              </select>

    </div>

    <div class="form-group">
        <label>Correo</label>
    
            <input type="text" [(ngModel)]="data.Correo" name="Correo" class="form-control">
   
    </div>



  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->

    <button (click)="guardar()" type="button" class="btn btn-primary w-100">{{txtButton}}</button>
  </div> 