<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/duyplus/fontawesome-pro/css/all.min.css">

<mat-accordion style="margin: 5px;">
    <div class="row">
        <div class="col-12" *ngFor="let row of data" style="display: flex;margin-bottom: 10px;">
            <mat-expansion-panel style="width: 100%;">
                <mat-expansion-panel-header>

                    <mat-panel-title> N° {{row.LineNum + 1}} |  {{row.ItemCode}}
                      <i style="color: #f39c12; position: absolute;
                      right: 43px;" (click)="infopicking(row)" *ngIf="row.Pickeada > 0 && row.Pickeada < row.OpenQuantity" class="fa-solid fa-circle-exclamation picking-falta"></i>
                      <i style="color: #07e76c; position: absolute;
                      right: 43px;" (click)="infopicking(row)" *ngIf="row.Pickeada == row.OpenQuantity" class="fa-solid fa-circle-check picking-check"></i>
                      <i style="color: #03bbf4; position: absolute;
                      right: 43px;" (click)="infopicking(row)" *ngIf="row.Pickeada > row.OpenQuantity" class="fa-solid fa-bell fa-shake picking-demas"></i>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-list>
                    <mat-list-item>Código: {{row.ItemCode}}</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Descripción: {{row.ItemName}}</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Bodega: {{row.WhsCode}}</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Stock: {{row.OnHand}}</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Cantidad Solicitada: {{row.OpenQuantity}}</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Cantidad Pickeada: {{row.Pickeada}}</mat-list-item>
                  </mat-list>
            </mat-expansion-panel>
            <button style="background: #96D23A;border: 1px solid #96D23A;color: #424242;" class="btn btn-danger" (click)="select(row)">Seleccionar</button>
        </div>
        <div class="col-12" style="display: flex;justify-content: end;">
            <button style="background: #96D23A;border: 1px solid #96D23A;margin-right:15px;color: #424242;" class="btn btn-primary" (click)="cerrar()">Cerrar</button>

        </div>
    </div>


</mat-accordion>
