import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { variableGlobal } from 'src/app/global.enum';
import Swal from 'sweetalert2';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

@Component({
  selector: 'app-verAprobadores',
  templateUrl: './verAprobadores.component.html',
  styleUrls: ['./verAprobadores.component.scss']
})
export class VerAprobadoresComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,
  ) { }
  public configuration: Config;
  public columns: Columns[];
  public data:any;
  @ViewChild('tabla', { static: true }) tabla: APIDefinition;
  @ViewChild('frmMensaje', { static: true }) frmMensaje: TemplateRef<any>;
  ngOnInit() {
    this.cargaInicial();
  }
  usuario:any;
  idUsuario:string;
  adminUsuario:boolean = false;
  async cargaInicial()
  {
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx: any = item;
        this.usuario = itemx.datos.login;
        this.idUsuario = this.usuario._id;
        this.adminUsuario = this.usuario.ADMIN;
        console.log("usuario", this.usuario);
        //console.log("usuario 2", this.idUsuario);
      }
    );
    console.log(this.data);
    this.columns = [
      { key: 'NOMBRE', title: "Aprobador" },
      { key: '', title: 'Mensaje aprobador', cellTemplate: this.frmMensaje, width: "400px"},
      // { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

    ];
    this.configuration = {
      ...DefaultConfig,
      searchEnabled: false,
      detailsTemplate: false,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: false,
      rowReorder: false,
      columnReorder: false,
      selectRow: false,

      paginationEnabled: true,
      fixedColumnWidth: true,

      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

    };
   

    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }


  cerrar() {
    this.activeModal.close();
  }
  private setClass(name: string): void {
    this.tabla.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  validaMensaje(row)
  {
    //console.log(row);
    var respuesta:boolean = false;
    if (this.idUsuario != row.id) {
      respuesta = true;
    }

    if (this.adminUsuario) {
      respuesta = false;
    }
    return respuesta;
  }


}
