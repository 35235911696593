import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import {IframeComponent} from'./lte/iframe/iframe.component';
import {InicioComponent} from'./lte/inicio/inicio.component';
import {UserComponent} from'./intranet/configuracion/user/user.component';
import {PerfilComponent} from'./intranet/configuracion/perfil/perfil.component';
import {NgxEasyTableComponent} from "./intranet/test/ngx-easy-table/ngx-easy-table.component"
import {CorreoEtapaComponent} from "./intranet/configuracion/correo-etapa/correo-etapa.component"
import {AsignacionVendedorComponent} from "./intranet/crm/asignacion-vendedor/asignacion-vendedor.component"
import {QueryComponent} from "./intranet/configuracion/query/query.component"
import {VentaComponent} from "./intranet/crm/venta/venta.component"
import {SeguimientoComponent} from "./intranet/crm/seguimiento/seguimiento.component"
import {LayoutVentaComponent} from "./intranet/layout/venta/layoult-venta.component"
import {StockComercialComponent} from "./intranet/crm/stock-comercial/stock-comercial.component"
import {QueryMongoComponent} from "./intranet/app/query-mongo/query-mongo.component"
import {SocioNegocioComponent} from "./intranet/crm/socio-negocio/socio-negocio.component"
import {CalendarioComponent} from "./intranet/crm/calendario/calendario.component"

import {MaestroBusinessPartnerComponent} from "./intranet/sap/BusinessPartnet/maestro-business-partner.component"
import {MaestroBusinessPartnerComponentV2} from './intranet/BusinessPartner/maestroBusinessPartner.component';
import {ModeloAprobacionComponent} from "./intranet/configuracion/modeloAprobacion/modeloAprobacion.component"
import {DocumentoPreliminalComponent} from "./intranet/crm/documentoPreliminal/documentoPreliminal.component"
import { InformeComponent } from './intranet/crm/informe/informe.component';
import { OITMComponent } from './intranet/crm/stock-comercial/OITM/OITM.component';
import { GraficosComponent } from './intranet/graficos/graficos.component';
import { AsignarPedidoComponent } from './intranet/wms/asignar-pedido/asignar-pedido.component';
import { GenerarDocumentoComponent } from './intranet/wms/generar-documento/generar-documento.component';
import { PreasignarPedidoComponent } from './intranet/wms/preasignar-pedido/preasignar-pedido.component';
import { PrepararPedidoComponent } from './intranet/wms/preparar-pedido/preparar-pedido.component';
import { StockdisponibleComponent } from './intranet/wms/stockdisponible/stockdisponible.component';
import { GraficosInformedeventasComponent } from './intranet/graficos-informedeventas/graficos-informedeventas.component';

import { MetasVendedoresComponent } from './intranet/configuracion/metas-vendedores/metas-vendedores.component';
import { VistaNvComponent } from './intranet/wms/vista-nv/vista-nv.component';
import { PickingComponent } from './intranet/wms/preparar-pedido/picking/picking.component';
import { MaestroArticuloComponent } from './intranet/inventario/maestro-articulo/maestro-articulo.component';
import { CrearMaestroArticuloComponent } from './intranet/inventario/maestro-articulo/crear-maestro-articulo/crear-maestro-articulo.component';
import { MetasCRUDComponent } from './intranet/crm/venta/metas-crud/metas-crud.component';
import { ImprimibleComponent } from './intranet/wms/layout/imprimible/imprimible.component';
import { ArticulosPickingComponent } from './intranet/wms/preparar-pedido/articulos-picking/articulos-picking.component';
import { DescargarInformeVentaComponent } from './intranet/crm/descargar-informe-venta/descargar-informe-venta.component';
import { ArticuloTemoralComponent } from './intranet/inventario/maestro-articulo/articulo-temoral/articulo-temoral.component';


const routes: Routes = [
  { path: '',  component: LoginComponent /*redirectTo: 'inicio', pathMatch: 'full' */ },
  { path: 'login', component: LoginComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'iframe', component: IframeComponent },


  //Crud Metas Vendedores

  {path: 'metasvendedores', component: MetasVendedoresComponent},
  //test
  { path: 'easyTable', component: NgxEasyTableComponent },

  //configuracion
  { path: 'User', component: UserComponent },
  { path: 'Perfil', component: PerfilComponent },
  { path: 'ConfiguracionCorreo', component: CorreoEtapaComponent },
  //{ path: 'Query', component: QueryComponent },
  { path: 'Mongo/Query', component: QueryMongoComponent },
  { path: 'Mongo/ModeloAprobacion', component: ModeloAprobacionComponent },

  //crm
  { path: 'Grupo', component: AsignacionVendedorComponent },
  { path: 'Venta/:ObjType', component: VentaComponent },
  { path: 'Venta/:ObjType/:DocEntry', component: VentaComponent },
  { path: 'Venta/:ObjType/:DocEntry/:Origen', component: VentaComponent },
  { path: 'Metas', component: MetasCRUDComponent },

  { path: 'Compra/:ObjType', component: VentaComponent },
  { path: 'Compra/:ObjType/:DocEntry', component: VentaComponent },
  { path: 'Compra/:ObjType/:DocEntry/:Origen', component: VentaComponent },

  { path: 'StockComercial', component: StockComercialComponent },
  { path: 'Seguimiento/:tipoSeguimiento', component: SeguimientoComponent },
  { path: 'BusinessPartner', component: SocioNegocioComponent },
  // { path: 'BusinessPartner/:CardType', component: SocioNegocioComponent },
  { path: 'BusinessPartner/:Origen', component: SocioNegocioComponent },
  { path: 'BusinessPartnerV2', component: MaestroBusinessPartnerComponent },
  { path: 'Preliminar/DocumentoMarketing', component: DocumentoPreliminalComponent },

  { path: 'Layoult/Venta/:DocEntry/:ObjType', component: LayoutVentaComponent },
  { path: 'Calendario', component: CalendarioComponent },

  { path: 'generico/:Nombre',component:InformeComponent},
  { path: 'OITM/:ItemCode',component:OITMComponent  },
  { path: 'OITM',component:OITMComponent  },
  { path: 'descargar-informe-venta',component:DescargarInformeVentaComponent },

  // graficos
  {path: 'graficos',component:GraficosComponent},




  //WMS
  //{path:'WMS/:tipo',component:AsignarPedidoComponent},
  //WMS
  {path:'WMS/:tipo',component:AsignarPedidoComponent},
  {path:'WMSInventario/:tipo',component:AsignarPedidoComponent},
  {path:'WMSCompra/:tipo',component:AsignarPedidoComponent},

  {path:'WMSEntregable',component:ImprimibleComponent},
  {path:'WMSEntregable/:DocEntry/:ObjType',component:ImprimibleComponent},



  {path:'generar-documento',component:GenerarDocumentoComponent},
  {path:'preasignar-pedido',component:PreasignarPedidoComponent},
  {path:'preparar-pedido',component:PrepararPedidoComponent},
  {path:'stockdisponible',component:StockdisponibleComponent},
  {path:'grafico-ventas' ,component:GraficosInformedeventasComponent},
  {path:'vista-nv' ,component: VistaNvComponent},
  {path:'articulos-picking' ,component: ArticulosPickingComponent},



  //INVENTARIO

  {path:'maestro-articulo' ,component: MaestroArticuloComponent},
  {path:'articuloTemporal' ,component: ArticuloTemoralComponent},
  //ArticuloTemoralComponent
  {path:'crear-articulo' ,component: CrearMaestroArticuloComponent},



];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
