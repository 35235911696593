import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-seleccionar-ubicacion',
  templateUrl: './seleccionar-ubicacion.component.html',
  styleUrls: ['./seleccionar-ubicacion.component.css']
})
export class SeleccionarUbicacionComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  data:any;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  ngOnInit(): void {
  
    console.log(this.data)

  }

  select(item) {
    this.activeModal.close(item);
  }

  cerrar() {
    this.activeModal.close();
  }

}
