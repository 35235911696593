

<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Detalle por bodega</h4>

        <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body">
        <div class="columns col-12">
            <ngx-table #tableModal
                [configuration]="configurationModal"
                [data]="dataModal"
                [columns]="columnsModal"
            ></ngx-table>
            <ng-template #frmDetalleLote let-row let-rowIndex="rowIndex">                                    
                <div>
                    <button class="btn btn-primary"  (click)="abrirItemLote(row)">{{row.WhsCode}}</button> 
                </div>
            </ng-template>
            <ng-template #frmIsCommited let-row let-rowIndex="rowIndex">                                    
                <div>
                    <button class="btn btn-warning"  (click)="abrirItemDetalle(row)">{{row.IsCommited}}</button> 
                </div>
            </ng-template>
            <ng-template #frmOnOrder let-row let-rowIndex="rowIndex">                                    
                <div>
                    <button class="btn btn-warning"  (click)="abrirItemDetalleCompra(row)">{{row.OnOrder}}</button> 
                </div>
            </ng-template>
        </div>

    </div>
    <div class="modal-footer">
        <button (click)="cerrar()" type="button" class="btn btn-success">Cerrar</button>
    </div>
</div>

