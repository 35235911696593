import { Component, OnInit } from '@angular/core';
import { IntranetoldService } from 'src/app/api/intranetold.service';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.sass']
})
export class QueryComponent implements OnInit {

  constructor(
    private _api:IntranetoldService
  ) { }

  data:any = {
    "_id": null,
    "NOMBRE": null,
    "CONSULTA": null
  }
  ngOnInit(): void {
  }

  async Guardar(item)
  {
    var data:any = await this._api.AddEditQuery(item);
    alert(data.mensaje);
  }

}
