import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        if (!fieldName) return items;
    
        searchText = searchText.toLowerCase();
        return items.filter(item => item[fieldName].toLowerCase().includes(searchText));
      }
}