import { Component, OnInit, OnDestroy, Input,  EventEmitter} from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService,  } from 'ngx-indexed-db';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, } from "@angular/material/dialog";
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-userEditAdd',
  templateUrl: './addEdit.html',
  styleUrls: ['./user.component.sass']
})

export class UserAddEditComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
      public activeModal: NgbActiveModal,
      private _api:IntranetoldService
      ) {


      }
    passnew:any;
    data:any = {};
    varglo:any;
    imageError: string;
    isImageSaved: boolean;
    titulo:string = "";
    txtButton:string = "Actualizar";

    ngOnInit(): void {
      this.data.PASSWORDNEW = "";
      this.varglo = variableGlobal;
        console.log(this.data);
        this.isImageSaved = true;
        //console.log(this.usuario.RUT);

        this.obtieneListas();

        if (this.titulo.toUpperCase().includes("NUEVO")) {
          this.txtButton = "Agregar";
        }
    }

    vendedores:any;
    perfiles:any;
    async obtieneListas()
    {

      var objeto:any = {
          "nombre":"OSLP",
          "top":"",
          "where": ""
      };
      //var oslp = await this._api.GetFileQuery(objeto);
      //console.log(oslp);

      var query = "SELECT [SlpCode], [SlpName] FROM [OSLP]";


      var oslp2:any = await this._api.GetNotFileQuery(query);
      this.vendedores = oslp2.objeto;
      //console.log(this.vendedores);

      var perfil:any = await this._api.getPerfilAll();
      this.perfiles = perfil.objeto;
      //console.log(this.perfiles);

    }
    modalReference: NgbModalRef;

    async recuperarPass(){

      console.log(this.data)

      var datos:any = await this._api.EditPass(this.data);


      console.log(datos)
      if (datos.estado) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: datos.mensaje,
          showConfirmButton: false,
          timer: 1500
        });
      }

      else
      {
        Swal.fire(datos.mensaje, '', 'error');
      }

    }

    async guardar() {
      //console.log(this.data);
      //console.log(this.data.IMAGE_LITTLE);
      //this.activeModal.close();
      //this.modalReference.close(this.usuario);

      var datos:any = await this._api.AddEditLogin(this.data);



      if (datos.estado) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: datos.mensaje,
          showConfirmButton: false,
          timer: 1500
        });
        this.activeModal.close(this.data);
      }
      else
      {
        Swal.fire(datos.mensaje, '', 'error');
      }


    }




    fileChangeEvent(fileInput: any) {
      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['image/png', 'image/jpeg'];
          const max_height = 15200;
          const max_width = 25600;

          if (fileInput.target.files[0].size > max_size) {
              this.imageError =
                  'Tamaño maximo permitido ' + max_size / 1000 + 'Mb';

              return false;
          }

          if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              this.imageError = 'Solo estos formatos son soportados ( JPG | PNG )';
              return false;
          }
          const reader = new FileReader();
          reader.onload = (e: any) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = rs => {
                  const img_height = rs.currentTarget['height'];
                  const img_width = rs.currentTarget['width'];

                  console.log(img_height, img_width);


                  if (img_height > max_height && img_width > max_width) {
                      this.imageError =
                          'Dimension maxima permitida es ' +
                          max_height +
                          '*' +
                          max_width +
                          'px';
                      return false;
                  } else {
                      const imgBase64Path = e.target.result;
                      //this.cardImageBase64 = imgBase64Path;
                      this.data.IMAGE_LITTLE = imgBase64Path;
                      this.isImageSaved = true;
                      // this.previewImagePath = imgBase64Path;
                  }
              };
          };

          reader.readAsDataURL(fileInput.target.files[0]);
      }
  }

  removeImage() {
      //this.cardImageBase64 = null;
      this.data.IMAGE_LITTLE = variableGlobal.RUTA_IMAGEN_USER_NOT_FOUND;
      this.isImageSaved = false;
  }

  cambioADMIN(){

    if( this.data.ADMIN==false){
      this.data.ADMIN=true
    }else{
      this.data.ADMIN=false
    }

  }

  cambioOITM_SAP(){

    if( this.data.OITM_SAP==false){
      this.data.OITM_SAP=true
    }else{
      this.data.OITM_SAP=false
    }

  }


}
