import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CrearmetavendedoresComponent } from './crear/crearmetavendedores/crearmetavendedores.component';
import {MongoService} from '../../../../app/api/mongo.service';
import { EditarmetavendedoresComponent } from './editar/editarmetavendedores/editarmetavendedores.component';

@Component({
  selector: 'app-metas-vendedores',
  templateUrl: './metas-vendedores.component.html',
  styleUrls: ['./metas-vendedores.component.css']
})
export class MetasVendedoresComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _apiMongo: MongoService
    ) { }

  ngOnInit(): void {
    this.cargaInicial();
  }

  async cargaInicial(){
    await this.metasPorVendedor();
    // console.log("jsonMetaVendedor:", this.jsonMetaVendedor);
  }

  CrearMeta() {
    const config = new MatDialogConfig();
    config.data = {

        width: '98vw', //sets width of dialog
        maxWidth: '798px', //overrides default width of dialog

    };

    const dialogRef = this.dialog.open(CrearmetavendedoresComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log("result:",result);
    })

  }

  EditarMeta(vendedorDatos) {
    var config = {

        width: '98vw', //sets width of dialog
        maxWidth: '798px', //overrides default width of dialog
        data: {
          SlpData: vendedorDatos
        }
    };

    const dialogRef = this.dialog.open(EditarmetavendedoresComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        console.log("data:",JSON.parse(result));
        // this.UpdateRow(JSON.parse(result))
      }
      
    })

  }

  jsonTest:any;
  jsonMetaVendedor:any;
  async metasPorVendedor() {
    
    var jsonTest01 = await this._apiMongo.getMetas("", "");
    // var jsonTest01 = await this._apiMongo.getMetas("", "");
    this.jsonTest = jsonTest01;
    console.log("jsonTest:",this.jsonTest);
    this.jsonMetaVendedor = [];
    // console.log("GETMETAS: ",this.jstonTest);
    for await (var row of this.jsonTest) {
      var dataFixed = {
        "_id": row._id,
        "SlpCode": row.SlpCode,
        "SlpName": row.NOMBRE,
        "Mes": row.MES,
        "Anio": row.ANIO,
        "Meta": row.VALOR
      }
      // console.log("datafixed:",dataFixed);
      this.jsonMetaVendedor.push(dataFixed);
      
    }
  }

  // async UpdateRow(data, id) {
  //   // console.log("ID: "+Object.values(id));
  //   var substrAddress = Object.values(Object.values(data)[11])[0];
  //   if (Object.values(Object.values(data)[11])[0] != null) {
  //     substrAddress = Object.values(Object.values(data)[11])[0].substring(Object.values(Object.values(data)[11])[0].indexOf(".")+1)
  //   }
  //   this.data = [
  //     ...this.data.map((obj, index) => {
  //       if (Object.values(id)[0] === obj.CardCode) {
  //         // console.log("index => "+index+" - row id => "+Object.values(id)[0]);
  //         obj.GroupCode = Object.values(data)[0];
  //         obj.CardType = Object.values(data)[1];
  //         obj.CardName = Object.values(data)[2];
  //         obj.CardCode = Object.values(data)[3];
  //         obj.LicTradNum = Object.values(data)[4];
  //         obj.Phone1 = Object.values(data)[5];
  //         obj.E_Mail = Object.values(data)[6];
  //         obj.SlpName = Object.values(data)[7];
  //         obj.U_IND_origenLead = Object.values(data)[8];
  //         obj.Notes = Object.values(data)[9];
  //         obj.Address = substrAddress;
  //         obj.Street = Object.values(Object.values(data)[11])[1];
  //         obj.County = Object.values(Object.values(data)[11])[2];
  //         obj.City = Object.values(Object.values(data)[11])[3];
  //         obj.Country = Object.values(Object.values(data)[11])[4];
  //         obj.State = Object.values(Object.values(data)[11])[5];
  //         obj.Name = Object.values(Object.values(data)[12])[5];
  //         obj.FirstName = Object.values(Object.values(data)[12])[1];
  //         obj.Position = Object.values(Object.values(data)[12])[4];
  //         obj.Tel1 = Object.values(Object.values(data)[12])[6];
  //         obj.E_MailL = Object.values(Object.values(data)[12])[2];
  //         obj.SlpCode = Object.values(data)[13];

  //         return { ...obj };
  //       }
  //       return obj;
  //     }),
  //   ];

  // }
}
