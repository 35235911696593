<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-4">
                <label for="seleccionarperiodo">Seleccionar Periodo</label>
                <select (change)="verPeriodo()" [(ngModel)]="periodo" class="form-control" name="seleccionarperiodo" id="seleccionarperiodo">
                  <option *ngFor="let month of lastThreeMonths" [value]="month">{{ month }}</option>

                </select>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8">

                <div class="card card-primary">
                    <div class="card-header" style="background: #94CF3A !important;
                    color: black !important;
                    font-weight: bold !important;
                    display: flex;
                    ">
                    <div style="width: 50%;display: flex;align-items: center;">
                        <h3 class="card-title">Ventas por Vendedor</h3>
                    </div>
                    <div *ngIf="SlpName" title="Vendedor seleccionado" style="width: 50%;display: flex;justify-content: right;align-items: center;">
                        {{SlpName}}
                    </div>


                    </div>
                    <div class="card-body">
                        <div class="chartdiv" style="height: 400px"></div>
                    </div>

                </div>

            </div>

            <div class="col-md-4">

                <div class="card card-primary">
                    <div class="card-header" style="background: #94CF3A !important;
                    color: black !important;
                    font-weight: bold !important;">
                        <h3 class="card-title">Ventas por Mes TOP°3</h3>
                    </div>

                    <div class="container-progress-bar">
                        <div *ngFor="let item of ventainformestop3">
                            <div class="titulo-mes">
                                <p>{{item.Mes}}</p>
                            </div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">{{item.Neto|iNDCLP}}</div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </div>

    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">

                <div class="card card-primary">
                    <div class="card-header" style="background: #94CF3A !important;
                    color: black !important;
                    font-weight: bold !important;
                    display: flex;
                    ">
                    <div style="width: 50%;display: flex;align-items: center;">
                        <h3 class="card-title">Ventas por Clientes</h3>
                    </div>
                    <div style="width: 50%;display: flex;justify-content: right;">
                        <button type="button" class="btn btn-secondary boton-limpiar" (click)="cargarDatosOriginales()">Limpiar
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </button>
                    </div>


                    </div>

                    <div class="card-body card-body-2 table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table table-bordered table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Monto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of ventainformeporcliente">
                                    <td>{{item.Codigo}}</td>
                                    <td>{{item.Socio}}</td>
                                    <td style="text-align: right;">{{item.Neto|iNDCLP}}</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            <div class="col-md-6">

                <div class="card card-primary">
                    <div class="card-header" style="background: #94CF3A !important;
                    color: black !important;
                    font-weight: bold !important;">
                        <h3 class="card-title">Metas por Vendedor</h3>
                    </div>

                    <div class="card-body card-body-2 table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table table-bordered table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>Vendedor</th>
                                    <th>Monto</th>
                                    <th>Meta</th>
                                    <th>%</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of jsonMetas">
                                    <td>{{item.SlpName}}</td>
                                        <td>{{item.Neto|iNDCLP}}</td>
                                        <td>{{item.Meta|iNDCLP}}</td>
                                    <td *ngIf="item.Porcentaje<=20">
                                        <span class="badge bg-red">{{item.Porcentaje}}%</span>
                                    </td>
                                    <td *ngIf="item.Porcentaje>=21&&item.Porcentaje<=50">
                                        <span class="badge bg-yellow">{{item.Porcentaje}}%</span>
                                    </td>
                                    <td *ngIf="item.Porcentaje>=51&&item.Porcentaje<=70">
                                        <span class="badge bg-blue">{{item.Porcentaje}}%</span>
                                    </td>
                                    <td *ngIf="item.Porcentaje>=71&&item.Porcentaje<=9999">
                                        <span class="badge bg-green">{{item.Porcentaje}}%</span>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>






        </div>

    </div>
</section>
