import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import {MongoService} from '../../../../../../app/api/mongo.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-editarmetavendedores',
  templateUrl: './editarmetavendedores.component.html',
  styleUrls: ['./editarmetavendedores.component.css']
})
export class EditarmetavendedoresComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditarmetavendedoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiMongo: MongoService
  ) { }

  _id:any;
  SlpCode:any;
  SlpName:any;
  Mes:any;
  Anio:any;
  Meta:any;
  dataSend:any;
  ngOnInit(): void {
    console.log(this.data)
    this._id = this.data.SlpData._id;
    this.SlpCode = this.data.SlpData.SlpCode;
    this.SlpName = this.data.SlpData.SlpName;
    this.Mes = this.data.SlpData.Mes;
    this.Anio = this.data.SlpData.Anio;
    this.Meta = this.data.SlpData.Meta;

    // console.log("_id:",this._id);
    
  }

  cerrar(): void {
    this.dialogRef.close();
  }
    
  cerrarDatos(): void {
    // alert(JSON.stringify(this.dataSend));
    this.dialogRef.close(JSON.stringify(this.dataSend));
  }

  async editarMeta() {
    this.dataSend = {
      "_id": this._id,
      "SlpCode": this.SlpCode,
      "NOMBRE": this.SlpName,
      "MES": this.Mes,
      "ANIO": this.Anio,
      "VALOR": this.Meta
    }
    var datosActualizados = await this._apiMongo.actualizarMeta(this.dataSend);
    // console.log("ACTUALIZADOS:",datosActualizados);
    if (datosActualizados["estado"]) {
      Swal.fire("Mensaje", `Se ha actualizado el vendedor "${this.SlpName}"!.`, "success");
      this.cerrarDatos();
    }
  }

}