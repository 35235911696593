import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DefaultConfig, API, APIDefinition, Columns, Config } from 'ngx-easy-table';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { variableGlobal } from 'src/app/global.enum';
import { PickingComponent } from '../preparar-pedido/picking/picking.component';

@Component({
  selector: 'app-generar-documento',
  templateUrl: './generar-documento.component.html',
  styleUrls: ['./generar-documento.component.css']
})
export class GenerarDocumentoComponent implements OnInit {
  
  public configuration: Config;
  public columns: Columns[];
  data = [];
  tipo = "";
  titulo = "";
  usuarios: any = [];
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmDueDate', { static: true }) frmDueDate: TemplateRef<any>;
  @ViewChild('frmTotal', { static: true }) frmTotal: TemplateRef<any>;
  @ViewChild('frmDocNum', { static: true }) frmDocNum: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  constructor(
    private _api: IntranetoldService,    
    public dialog: MatDialog,
    private _activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  async cargaInicial() {
    this.columns = [
      { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmDocNum },
      { key: 'Dscrption', title: 'Descripcíon' },
      { key: 'Quantity', title: 'Cantidad' },
      { key: 'OpenQty', title: 'Despacho' }     

    ];

    var query = await this.obtieneQueryMongo("VST_WMS");


    var data: any = await this._api.GetNotFileQuery(query);

    

    this.data = data.objeto;

    this.obtieneTitulo();

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };
    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

  }
  async getUsuarios() {

    var res: any = await this._api.getAllLogin();

    if (res.estado) {
      this.usuarios = res.objeto;
    }
  }
  obtieneTitulo() {
    this.tipo = this._activeRoute.snapshot.paramMap.get("tipo");
    //console.log(this.tipo);
    if (this.tipo == "asignar") {

      this.titulo = "Asignacion de pedidos";

    }else if (this.tipo ="generar")
    {
      this.titulo = "generar pedidos";
    }else if (this.tipo ="pre-asignar")
    {
      this.titulo = "pre-asignacíon de pedidos";
    }
    else if (this.tipo ="preparar")
    {
      this.titulo = "Preparacion de pedidos";
    }
   
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  async OpenModal(Encabezado) {


    console.log("asdjknaksdjnaksdjnaksdjnaskdsasdkkadkakdadkadkasdkadknsnkadsnasdknasd");

    var doc = await this.CargarDatos(Encabezado.DocEntry);

    //console.log(doc);
    //console.log(Encabezado);
    



    var config = {
      height:'100%',
      width: '98vw', //sets width of dialog
      maxWidth: '1200px', //overrides default width of dialog
      data: {
        DocEntry: Encabezado.DocEntry,
        DocNum:Encabezado.DocNum,
        Document: doc
      },

    };

    const dialogRef = this.dialog.open(PickingComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })

  }
  async CargarDatos(DocEntry) {
    var query = await this.obtieneQueryMongo("WMS_NV_DET");
    query = query.replace("NV",DocEntry);
    var respuesta: any = await this._api.GetNotFileQuery(query);
    var Document = respuesta.objeto;

    //console.log(query)
    //console.log(respuesta);
    return Document;
  }
}
