<button type="button" class="close" aria-label="Close" (click)="cerrar()" style="position: absolute;right: 10px;top: 6px;">
  <span aria-hidden="true">&times;</span>
</button>

<div style="
display: flex;
justify-content: center;
width: 100%;
">
    <h4 class="modal-title" id="modal-basic-title" style="color: #153C69;">Visualización documento</h4>
</div>

<div class="modal-body">
  <div id="contentToConvert">
    <app-ventaLayault [ObjType]="this.ObjType" [DocEntry]="this.DocEntry" [urllayout]="urllayout" [urlImprimir]="urlImprimir"></app-ventaLayault>
  </div>
</div>



<div style="position: absolute;bottom: 10px;right: 10px;">
  <div (click)="imprimir()" style="width: 40px;height: 40px;background: #153C69;display: flex;justify-content: center;align-items: center;border-radius: 10px;cursor: pointer;">
    <i class="fa-sharp fa-solid fa-print" style="color: white;font-size: 30px;"></i>
  </div>
</div>
