import { Component, OnInit } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { variableGlobal } from "src/app/global.enum";
import { PistoleoUbicacionComponent } from "./pistoleo-ubicacion/pistoleo-ubicacion.component";
import { WMSService } from "../../../../api/wms.service";
import { FormControl } from "@angular/forms";
import { IntranetoldService } from "src/app/api/intranetold.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-pistoleo",
  templateUrl: "./pistoleo.component.html",
  styleUrls: ["./pistoleo.component.css"],
})
export class PistoleoComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _apiWMS: WMSService,
    private _api: IntranetoldService
  ) {}
  data: any;
  detalleov: any;
  InputItemCode: any;
  filtro: any;
  filtroubicacion: any;
  TituloAccion;
  pickeado = [];
  ubicaciones;
  ubisreales = [];

  lotechecked;
  seriechecked;

  ubicompra;
  lotecompra;


  seriecompraobj = {
    BatchNumber: null,
    CodigoLoteSerie: null,
    Pickeada: null
  };

  lotecompraobj = {
    BatchNumber: null,
    CodigoLoteSerie: null,
    Pickeada: null
  };
  seriecompra;

  PickearCompra() {
    if (this.ubicompra) {
      let ubicompranew = {
        AbsEntry: this.ubicompra.AbsEntry,
        BinAbs: this.ubicompra.BinAbs,
        BinCode: this.ubicompra.BinCode,
        LOTE: [],
        Original: this.data.Original,
        Pickeada: this.ubicompra.Pickeada,
        Quantity: this.data.Quantity,
        SERIE: [],
      };
      if (this.lotecompraobj) {
        let lotecompranew = {
          BatchNumber: this.lotecompraobj.CodigoLoteSerie,
          Checked: false,
          CodigoLoteSerie: this.lotecompraobj.CodigoLoteSerie,
          Original: this.data.Original,
          Pickeada: this.lotecompraobj.Pickeada,
          Quantity: this.data.Quantity,
          SysNumber: 3,
        };
        ubicompranew.LOTE.push(lotecompranew);
      }
      if (this.seriecompraobj) {
        let seriecompranew = {
          BatchNumber: this.seriecompraobj.BatchNumber,
          Checked: false,
          CodigoLoteSerie: this.seriecompraobj.CodigoLoteSerie,
          Original: this.data.Original,
          Pickeada: this.seriecompraobj.Pickeada,
          Quantity: this.data.Quantity,
          SysNumber: 3,
        };
        ubicompranew.SERIE.push(seriecompranew);
      }
      
      this.data.UBICACION.push(ubicompranew);

      console.log("DATA ACTUALIZADA",this.data);
    }
  }

  loteseriechecked(string) {
    if (string == "lote") {
      this.seriechecked = false;
      this.lotechecked = true;
    } else {
      this.lotechecked = false;
      this.seriechecked = true;
    }
  }

  async cargaInicial() {
    var query = await this.obtieneQueryMongo("Ubicaciones");
    this.ubicaciones = await this._api.GetNotFileQuery(query);
    this.ubicaciones = this.ubicaciones.objeto;

    console.log("UBICACIONES ANTES DEL FOREACH",this.ubicaciones)

    console.log("DATA WHS ",this.data.WhsCode)

    this.ubicaciones.forEach(element => {
      if (element.WhsCode == this.data.WhsCode) {
        this.ubisreales.push(element);
      }
    });

    this.ubicaciones = this.ubisreales;

    console.log("UBICACIONES DESPUÉS DEL FOREACH",this.ubicaciones)

    



  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach((element) => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  async ngOnInit(): Promise<void> {
    await this.cargaInicial();

    console.log("UBICACIONES", this.ubicaciones);

    console.log("TituloAccion", this.TituloAccion);
    console.log("DATA", this.data);

    //filtro por Serie Lote o Ubicación
    /*
    if (this.data.BinLocationActive) {
      this.filtro = "ubicacion"
    }
    if (this.data.ManBtchNum) {
      this.filtro = "lote"
    }
    if (this.data.ManSerNum) {
      this.filtro = "serie"
    }

    //filtro por ubicación

    if (this.filtro=="ubicacion") {
      let contador = 0;
      this.data.UBICACION_OnHand.forEach(element => {
        element.QuantityEstatico = element.Quantity;

        if (element.LOTE.length > 0) {
          contador = contador + 1;
        }

      });
      if (contador > 0) {
        this.filtroubicacion = "LOTE"
      }
    }

    if (this.filtro=="ubicacion") {
      let contador = 0;
      this.data.UBICACION_OnHand.forEach(element => {
        if (element.SERIE.length > 0) {
          contador = contador + 1;
        }
      });
      if (contador > 0) {
        this.filtroubicacion = "SERIE"
      }
    }

    this.data.pickeado = 0;

    


    console.log("FILTRO PRINCIPAL",this.filtro)
    console.log("FILTRO UBICACIÓN", this.filtroubicacion)
    */
  }

  cerrar() {
    this.activeModal.close();
  }

  pistoleoubicacion(ubi: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(
      PistoleoUbicacionComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.ubicaciondata = ubi;
    //modalRef.componentInstance.filtroubicacion = this.filtroubicacion;
    modalRef.componentInstance.rowDetalle = this.data;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {});
  }

  keyFunc(e) {
    this.data.forEach((element) => {
      if (element.ItemCode == this.InputItemCode) {
        element.Quantity = +element.OpenQty;
      }
    });
  }

  async pickeoServicio() {
    console.log("Antes de cambio", this.data);
    var test: any = await this._apiWMS.calculaDatoPickeadoDetalle(this.data);
    console.log("Despues de cambio", test);
  }

  async Pickear() {
    this.data.UBICACION = [];
    this.data.Quantity = 0;
    if (this.data.BinLocationActive) {
      this.data.UBICACION_OnHand.forEach((element) => {
        element.Quantity = element.Original;

        if (element.Pickeada > 0) {
          this.data.Quantity = this.data.Quantity + element.Pickeada;
          var lineaUbicacion = { ...element };
          element.Quantity = element.Original - element.Pickeada;
          //lineaUbicacion.Quantity = lineaUbicacion.Pickeada;
          this.data.UBICACION.push(lineaUbicacion);
        }
      });
    } else {
      if (this.data.ManBtchNum) {
        if (this.data.ManBtchNum || this.data.ManSerNum) {
          this.data.LOTE = [];
          this.data.SERIE = [];
          this.data.LOTE_OnHand.forEach((row) => {
            row.Quantity = row.Original;
            if (row.Pickeada > 0) {
              this.data.Quantity = this.data.Quantity + row.Pickeada;
              row.Quantity = row.Quantity - row.Pickeada;
              var lineaLoteSerie = { ...row };
              this.data.LOTE.push(lineaLoteSerie);
            }
          });
        } else {
          //cuando es solo cantidad
          //pendiente de configurar
        }
      }
    }
  }
}
