import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CrearMaestroArticuloComponent } from './../crear-maestro-articulo/crear-maestro-articulo.component';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { SapService } from 'src/app/api/sap.service';
import { MongoService } from 'src/app/api/mongo.service';
import { variableGlobal } from 'src/app/global.enum';




@Component({
  selector: 'app-articulo-temoral',
  templateUrl: './articulo-temoral.component.html',
  styleUrls: ['./../maestro-articulo.component.css']
})
export class ArticuloTemoralComponent implements OnInit {


  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  public configuration: Config;
  public columns: Columns[];
  AllMaestroArticulos: any;

  data = [];
  articuloeliminar: any;
  articuloeliminarV: any;
  articuloeliminarD: any;


  private setClass(name: string): void {
    if (this.table && this.table.apiEvent) {
      this.table.apiEvent({
        type: API.setTableClass,
        value: name,
      });
    }
  }

  constructor(
    private modalService: NgbModal,
    private _sap: SapService,
    private _apiMongo: MongoService,

  ) { }

  ngOnInit(): void {
    this.cargaInicial();
  }

  async cargaInicial() {

    var articulos: any = await this._apiMongo.consultaArtivuloTemporal();

    this.AllMaestroArticulos = articulos.objeto

    console.log("ALL MAESTRO TARIFARIO", this.AllMaestroArticulos);


    this.columns = [

      { key: 'item.ItemCode', title: 'N° Artículo'},
      { key: 'item.ItemName', title: 'Descripción' },
      { key: 'item.ItmsGrpNam', title: 'Grupo de artículos' },
      { key: 'item.U_IND_CATEGORIA2', title: 'Familia' },
      { key: 'item.U_IND_CATEGORIA3', title: 'Sub-Familia' },
      { key: 'item.U_IND_SWW', title: 'Código Antiguo' },
      //{ key: '_id', title: 'ID MONGO' },
      { key: '', title: 'Asignar', cellTemplate: this.frmAccion },


    ];

    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }


    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,

    };
    this.data = this.AllMaestroArticulos;
  }


  crearMaestroArticulo() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(CrearMaestroArticuloComponent, ngbModalOptions);
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.componentInstance.crear = true;

    modalRef.result.then(async (data) => { });
  }

  async editarMaestroArticulo(row) {

    console.log("row --------", row)
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(CrearMaestroArticuloComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.crear = false;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => { });
  }
  eliminarMT(row) {
    this.articuloeliminarV = row.item;
    this.articuloeliminarD = row._id;
    console.log("this.articuloeliminarD ", this.articuloeliminarD)
  }

  async confirmareliminar() {
    await this._apiMongo.eliminaArtivuloTemporal(this.articuloeliminarD)
    location.reload()
  }
}
