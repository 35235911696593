import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { variableGlobal } from 'src/app/global.enum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalleDocumentoPreliminal',
  templateUrl: './detalleDocumentoPreliminal.component.html',
  styleUrls: ['./detalleDocumentoPreliminal.component.scss']
})
export class DetalleDocumentoPreliminalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }
  public configuration: Config;
  public columns: Columns[];
  public documento:any;
  @ViewChild('tabla', { static: true }) tabla: APIDefinition;

  ngOnInit() {
    this.cargaInicial();
  }
  async cargaInicial()
  {

    this.columns = [
      { key: 'ItemCode', title: "Articulo" },
      { key: 'Dscription', title: 'Descripción'},
      { key: 'Currency', title: 'Moneda'},
      { key: 'Quantity', title: 'Cantidad'},
      { key: 'Price', title: 'Precio'},
      { key: 'Disc', title: 'Descuento'},
      { key: 'LineTotal', title: 'Total linea'},
      // { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

    ];
    this.configuration = {
      ...DefaultConfig,
      searchEnabled: false,
      detailsTemplate: false,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: false,
      rowReorder: false,
      columnReorder: false,
      selectRow: false,

      paginationEnabled: true,
      fixedColumnWidth: true,

      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

    };


    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }


  cerrar() {
    this.activeModal.close();
  }
  private setClass(name: string): void {
    this.tabla.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
}
