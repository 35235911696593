<div class="card-body">
    <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6"></div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <table id="example2" class="table table-bordered table-hover dataTable dtr-inline"
                    aria-describedby="example2_info">
                    <thead>
                        <tr>
                            <th class="sorting sorting_asc" tabindex="0" aria-controls="example2" rowspan="1"
                                colspan="1" aria-label="Rendering engine: activate to sort column descending"
                                aria-sort="ascending">Rendering engine</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Browser: activate to sort column ascending">Browser</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Platform(s): activate to sort column ascending">Platform(s)</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Engine version: activate to sort column ascending">Engine version</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="CSS grade: activate to sort column ascending">CSS grade</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="odd">
                            <td class="dtr-control sorting_1 background-table" tabindex="0">Gecko</td>
                            <td class="background-table">Firefox 1.0</td>
                            <td class="background-table">Win 98+ / OSX.2+</td>
                            <td class="background-table">1.7</td>
                            <td class="background-table">A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            
        </div>
    </div>
</div>
<div class="card">


    <div class="card-header">
        <h3 class="card-title">{{titulo}}</h3>
    </div>
    <div class="card-body">
        <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-sm-12">

                    <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns"></ngx-table>
                    <ng-template #frmDocNum let-row let-rowIndex="rowIndex">
                                                
                        <a (click)="OpenModal(row)">{{row.ItemCode}} </a> 
                        
                    </ng-template>

                </div>
            </div>
        </div>
        <div>
            <button>Generar entrega</button>
        </div>
    </div>
</div>