

<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Detalle producto</h4>

        <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body">
    
        <ngx-table [configuration]="configuration"
            [data]="data"
            [columns]="columns"
        ></ngx-table>
        <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="AbrirDetalleItem(row)">{{row.ItemCode}}</button>
        </ng-template>

    </div>
    <div class="modal-footer">
        <button (click)="cerrar()" type="button" class="btn btn-success">Cerrar</button>
    </div>
</div>

