import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {AsignacionVendedorAddEditComponent} from './addEdit'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {IntranetoldService} from './.././/../../api/intranetold.service';
import Swal from 'sweetalert2';
import {variableGlobal} from './../../../global.enum';

@Component({ 
  selector: 'app-asignacion-vendedor',
  templateUrl: './asignacion-vendedor.component.html',
  styleUrls: ['./asignacion-vendedor.component.sass']
})
export class AsignacionVendedorComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private _api:IntranetoldService
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  usuarios:any;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  @ViewChild('frmEtapa', { static: true }) frmEtapa: TemplateRef<any>;
  
  data:any;
  ngOnInit(): void {
    this.columns = [
      { key: 'NombreJ', title: "Jefe" },
      { key: 'NombreE', title: 'Empleado' },
      { key: 'action', title: 'Acción', cellTemplate: this.frmAccion, searchEnabled: false },
    ];

    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,
     
      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };
   //this.obtieneTablasSAP();
    this.cargaDatos();


  
   this.modeloNuevo()


  }



  buscaPersona(id)
  {
    //console.log(id);
    //console.log(this.etapas);
    var nombre:string = "";
    this.usuarios.forEach(element => {
      if (Number(element.id) ==  Number(id)) {
        nombre = element.NOMBRE;
      }
    });
    return nombre;
  }





  async cargaDatos()
  {
    var data:any = await this._api.getAllVentaJefe();
    this.data = data.objeto;
    //console.log(this.data);

    data = await this._api.getAllLogin();
    this.usuarios = data.objeto;

    if(variableGlobal.ESTILO_EASY_TABLE)
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  nuevo:any;
  modeloNuevo()
  {
    this.nuevo = 
      {
        "_id": null
        ,"EMPLEADO": null
        ,"JEFE": null
        ,"NombreE": null
        , "NombreJ":null
      }
  }
  async editar(item, titulo = "Editar jefe ventas", rowIndex)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(AsignacionVendedorAddEditComponent, ngbModalOptions);
    modalRef.componentInstance.data = item;
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.usuarios = this.usuarios;
    
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {
      // on close
      //console.log("opt1", data);
      if (data) {
        var datos:any = await this._api.AddEditVentaJefe(data);
        this.cargaDatos();
        //console.log("hacer algo", data);
       
        if (datos.estado) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 1500
          });
        }
        else
        {
          Swal.fire(datos.mensaje, '', 'error');
        }
      }
      this.modeloNuevo();
      
    }, (reason) => {
      //this.editar(item);
    });
  }
  async eliminar(item)
  {
    //);
    Swal.fire({
      title: "¿Está seguro que desea hacer que el usuario " + item.NombreJ  + " deje de ver a " + item.NombreE + " como empleado de venta?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",
    
    }).then( async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        var respuesta:any = await this._api.EliminaVentaJefe(item);

        if (respuesta.estado) {
          this.cargaDatos();
          Swal.fire(respuesta.mensaje, '', 'success');
        }
        else
        {
          Swal.fire(respuesta.mensaje, '', 'error');
        }
       
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
 
}
