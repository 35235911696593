<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Descargar Informe Ventas <i class="fa-solid fa-file-excel"></i>
        </h3>
      </div>

      <div class="row" style="display: flex;justify-content: center;">
        <div class="col-12" style="max-width: 500px;">
          <div class="mb-3">
            <label for="basic-url" class="form-label">Coloca el nombre del informe</label>
            <div class="input-group">
              <input [(ngModel)]="nombreExcel" type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4">
            </div>
          </div>
        </div>

        <div class="col-12" style="display: flex;justify-content: center;margin-bottom: 20px;">
          <a href="{{'https://twapiv2.indeone.app/Excel/DescargaExcel?nombre='+nombreExcel}}" target="_blank" rel="noopener noreferrer">
            <button mat-raised-button style="background: rgb(127, 203, 127);">Descargar</button>

          </a>
        </div>

      </div>


    </div>

  </div>

</div>
