import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-ventaLayault',
  templateUrl: './layoult-venta.component.html',
  styleUrls: ['./layoult-venta.component.sass']
})
export class LayoutVentaComponent implements OnInit {


  @Input() public ObjType: string;
  @Input() public DocEntry: string;
  @Input() public urllayout: string;
  @Input() public urlImprimir: string;
  constructor(private sanitizer: DomSanitizer) {}
  safeUrl;


  ngOnInit(): void {

    console.log("URL FINAL",this.urllayout);

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urllayout);




  }



}
