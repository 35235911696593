import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import {BusinessPartner, direccion, personaContacto} from "../../../model/BusinessPartnerModel";
import {SapService} from "../../../api/sap.service"
import { IntranetoldService } from '../../../api/intranetold.service';
import Swal from 'sweetalert2'
//import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-maestro-business-partner',
  templateUrl: './maestro-business-partner.component.html',
  styleUrls: ['./maestro-business-partner.component.sass']
})
export class MaestroBusinessPartnerComponent implements OnInit {


  @Input() public CardCode: string;
  @Input() public headerInivisible:boolean;
  constructor(
    private _sap:SapService,
    private _api:IntranetoldService
  ) { }
  data:BusinessPartner = {
    CardCode: "",
    Balance:0,
    ChecksBal:0,
    DNotesBal:0,
    OrdersBal:0,
    OprCount:0,
    general: {},
    personasContactos: [{
      Name:""
    }],
    direcciones: [{
      Address:"",
      Address2:"",
      Address3:""
    }],
    CondicionPago:{}
  };
  
  GroupCode:any;
  Currency:any;
  ShipType:any;
  SlpCode:any;
  contactoPosicion:any = 0;
  direccionPosicion:any = 0;
  GroupNum:any;
  ListNum:any;
  //:string;
  //:boolean;
  
  ngOnInit(): void {
    console.log(this.headerInivisible);
    this.datosIniciales();
  }
  async datosIniciales()
  {
    //this.CardCode = "C10150818-8";
    this.data.CardCode = this.CardCode;
    var data:any = await this._sap.ObtenerSocioNegocio(this.data)
    this.data = {...data.resultado};

    var dataQueryMongo:any = await this._api.getQueryAll();
    var query:string = "";
    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "grupoDeClientes") {
        query = element.CONSULTA;
      }
    });
    var tipoCliente = "'" + this.data.CardType + "'";
    query = query.replace("'C'", tipoCliente);
    data = await this._api.GetNotFileQuery(query);
    this.GroupCode = [...data.objeto]

    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "monedasDeSistema") {
        query = element.CONSULTA;
      }
    });
    data = await this._api.GetNotFileQuery(query);
    this.Currency = [...data.objeto];

    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "formaEnvio") {
        query = element.CONSULTA;
      }
    });
    data = await this._api.GetNotFileQuery(query);
    this.ShipType = [...data.objeto];

    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "listaVendedor") {
        query = element.CONSULTA;
      }
    });
    data = await this._api.GetNotFileQuery(query);
    this.SlpCode = [...data.objeto];

    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "listaCondicionPago") {
        query = element.CONSULTA;
      }
    });
    data = await this._api.GetNotFileQuery(query);
    this.GroupNum = [...data.objeto];

    await dataQueryMongo.objeto.forEach(element => {
      if (element.NOMBRE == "listaPrecios") {
        query = element.CONSULTA;
      }
    });
    data = await this._api.GetNotFileQuery(query);
    this.ListNum = [...data.objeto];
    

    //contacto definir posicion default
    this.saberPosicionPersonaContacto(this.data.general.CntctPrsn);
    

    //console.log(this.data);
    //console.log(this.GroupCode);
    //console.log(this.data.personasContactos[this.contactoPosicion]);
  }

  saberPosicionPersonaContacto(Name)
  {

    this.data.personasContactos.forEach((element, index) => {
      if (element.Name == Name) {
        this.contactoPosicion = index;
      }
    });
  }
  saberPosicionDireccion(Address, tipoDireccion)
  {

    this.data.direcciones.forEach((element, index) => {
      if (element.Address == Address && element.tipoDireccion == tipoDireccion) {
        this.direccionPosicion = index;
      }

    });
  }

  cambiaPeronaContacto(item:personaContacto)
  {
    this.saberPosicionPersonaContacto(item.Name);
  }
  cambiaDireccion(item:direccion)
  {
    this.saberPosicionDireccion(item.Address, item.tipoDireccion);
  }
  eliminarContacto(item:personaContacto)
  {

    this.data.personasContactos.forEach((element:personaContacto, index) => {
      if (element.Name == item.Name) {
        this.data.personasContactos.splice(index,1);
      }
    });
    
    this.contactoPosicion = 0;
  }
  eliminarDireccion(item:direccion)
  {

    this.data.direcciones.forEach((element:direccion, index) => {
      if (element.Address == item.Address && element.tipoDireccion == item.tipoDireccion) {
        this.data.direcciones.splice(index,1);
      }
    });
    
    this.direccionPosicion = 0;
  }
  nuevoContacto()
  {
    if (this.data.personasContactos[this.data.personasContactos.length - 1].Name) {
      var nuevo:personaContacto = {
        Address: "",
        Cellolar:"",
        E_Mail:"",
        Fax:"",
        FirstName:"",
        LastName:"",
        MiddleName:"",
        Name:"",
        Pager:"",
        Position:"",
        Tel1:"",
        Tel2:"",
        Title:""
      };
      this.data.personasContactos.push(nuevo);
      this.contactoPosicion = this.data.personasContactos.length - 1;
    }
  }
  nuevoDireccion(tipoDireccion:string)
  {
    var indice =  this.data.direcciones.filter((item) => item.tipoDireccion == tipoDireccion).length - 1;
    if (this.data.direcciones.filter((item) => item.tipoDireccion == tipoDireccion)[indice].Address) {
      var nuevo:direccion = {
        Address: "",
        Address2:"",
        Address3:"",
        Block:"",
        City:"",
        Country:"",
        County:"",
        State:"",
        Street:"",
        TaxCode:"IVA",
        tipoDireccion:tipoDireccion,
        ZipCode:""
      };
      this.data.direcciones.push(nuevo);
      this.direccionPosicion = this.data.direcciones.length - 1;
    }
  }
  getFactura() {
    return this.data.direcciones.filter((item) => item.tipoDireccion == "B");
  }
  getDespacho() {
    return this.data.direcciones.filter((item) => item.tipoDireccion == "S");
  }

  async guardarSocio()
  {
    var datos:any = await this._sap.GeneraSocioNegocio(this.data);
    if (datos.estado) {
      Swal.fire("Mensaje", datos.mensaje, "success");
    }
    else
    {
      Swal.fire("Mensaje", datos.mensaje, "error");
    }

    //Swal.fire({ html: `You selected:` })
  }

}

