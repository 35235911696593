import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle} from "../../../model/ventaModel"
import { variableGlobal } from '../../../global.enum';
import {MaestroBusinessPartnerComponent} from "./maestro-business-partner.component"


@Component({
  selector: 'app-clienteModel',
  templateUrl: './cliente-model.html',
  styleUrls: ['./maestro-business-partner.component.sass'],
})
export class clienteModelComponent implements OnInit {
  @Input()
  CardCode:string;
  headerInivisible:boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService
  ) { 

  }
 
 
  ngOnInit(): void {
    console.log(this.headerInivisible);
  }


  cerrar() {
    this.activeModal.close();
  }


}
