import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PickingComponent } from './picking/picking.component';

@Component({
  selector: 'app-preparar-pedido',
  templateUrl: './preparar-pedido.component.html',
  styleUrls: ['./preparar-pedido.component.css']
})
export class PrepararPedidoComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  picking() {
    var config = {
        
        width: '98vw', //sets width of dialog
        maxWidth: '1600px', //overrides default width of dialog

    };

    const dialogRef = this.dialog.open(PickingComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })

  }

}
