<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Inventario Comercial <i class="fas fa-solid fa-download" (click)="exportToCSV()"></i>
        </h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <label>Lista de precio</label>
            <select class="form-control" [(ngModel)]="listaPrecio" (change)="cambiaPrecio();">
              <option *ngFor="let item of OPLN" [ngValue]="item.ListNum">{{item.ListName}}</option>
            </select>
          </div>
          <div class="col-3">
            <label>Categoria</label>
            <select class="form-control" [(ngModel)]="categoriaSeleccionada" (change)="filtro()">
              <option value="">Seleccione una categoria</option>
              <option *ngFor="let item of listaCategorias" [ngValue]="item">{{item}}</option>
            </select>
          </div>
          <div class="col-3">
            <label>Familia</label>
            <select class="form-control" [(ngModel)]="familiaSeleccionada" (change)="filtro()">
              <option value="">Seleccione una familia</option>
              <option *ngFor="let item of listaFamilia" [ngValue]="item">{{item}}</option>
            </select>
          </div>
          <div class="col-3">
            <label>Sub-Familia</label>
            <select class="form-control" [(ngModel)]="subFamiliaSeleccionada" (change)="filtro()">
              <option value="">Seleccione una sub-familia</option>
              <option *ngFor="let item of listaSubFamilia" [ngValue]="item">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <button type="button" class="btn btn-warning" (click)="limpiar();">Limpiar</button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns"></ngx-table>
            <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
              <button class="btn btn-primary" (click)="AbrirDetalleItem(row)">{{row.ItemCode}}</button>
            </ng-template>
            <ng-template #frmPrice let-row let-rowIndex="rowIndex">
              <!-- <div>{{row.Price | iNDCLP}}</div> -->
              <!-- <div *ngIf="row.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div> -->
              <div *ngIf="row.Currency != 'CLP'">{{row.Price | iNDCLP}}</div>
              <div *ngIf="row.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>
            </ng-template>
          </div>
        </div>


      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>