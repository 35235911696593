<app-cargando *ngIf="carga"></app-cargando>

<div class="modal-header">

    <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
      <p>Generar Documento</p>
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
<div class="row">

    <div class="col-12" style="display: flex;justify-content: center;">

        <button (click)="generarDocumento(15)" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">
            Generar Guía
            <i class="fa-solid fa-check"></i>
          </button>
    </div>
    <div class="col-12">

        <button (click)="generarDocumento(13)" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">
            Generar Factura
            <i class="fa-solid fa-check"></i>
          </button>
    </div>
</div>
