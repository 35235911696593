import { Component, OnInit, OnDestroy, Input,  EventEmitter, ViewChild} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetNewService} from '../../../api/intranet-new.service'
import {variableGlobal} from "../../../global.enum";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2'
import {adjuntoCorreo,envioCorreo,evento} from "./../../../model/Indesad"
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-detalleArticuloBodega',
  templateUrl: './envio-correo.html',
  styleUrls: ['./seguimiento.component.sass']
})

export class envioCorreoSeguimientoaComponent implements OnInit {

    constructor(
    public activeModal: NgbActiveModal,
    private _api:IntranetNewService,
    ) { 
    }
    @ViewChild('myEditor') myEditor: any;
    public Editor = ClassicEditor;
    origen:any;
    data:any;
    correo:string;
    asunto:string;
    mensaje:string;
    ngOnInit(): void {
      this.asunto = this.tipoDocString(this.data.ObjType) + " " + this.data.DocNum;
      this.mensaje = "Estimado envió su " + this.tipoDocString(this.data.ObjType) + " " + this.data.DocNum;
      //console.log(this.data);
    }
    tipoDocString(ObjType:string)
    {
      var texto:string = "";
      switch (ObjType) {
        case "23":
          texto = "Cotización";
          break;
        case "17":
          texto = "Nota de venta";
          break;
        default:
          texto = "No controlado";
          break;
      }
      return texto;
    }
    async envioCorreo()
    {
      console.log(this.correo, this.mensaje);
      console.log(this.myEditor);
      var mensajeHTML:string = this.myEditor.editorInstance.getData();
      console.log("MENSAJE HTML: "+mensajeHTML);
      var base = window.origin;
      if (base.includes("localhost")) {
          base = "https://twv2.indeone.app";
          // base = "https://twdev.indeone.app";
          // console.log("BASE: "+base);
      }

      // var urlLayout =  base + "/Layoult/Venta/" + this.data.DocEntry +"/" + this.data.ObjType;

      // var urlLayout = "http://localhost:8080/"+ "/WEB/viewLayault?ObjType=" +this.data.ObjType + "&DocEntry=" +  this.data.DocEntry;
      // console.log("DEV: "+environment.RUTA_API.toString().substring(environment.RUTA_API.indexOf("/")+2, environment.RUTA_API.indexOf(".")));

      if (environment.RUTA_API.toString().substring(environment.RUTA_API.indexOf("/")+2, environment.RUTA_API.indexOf(".")) == "twapidev") {
        var urlLayout = "http://localhost:8090/"+ "/WEB/viewLayault?ObjType=" +this.data.ObjType + "&DocEntry=" +  this.data.DocEntry;
        // console.log(environment.RUTA_API);
      }
      else {
        var urlLayout = "http://localhost:8080/"+ "/WEB/viewLayault?ObjType=" +this.data.ObjType + "&DocEntry=" +  this.data.DocEntry;
        // console.log(environment.RUTA_API);
      }
      

      //dejo lo antiguio para recordar la embarrada de inxap con los puertos que nos dejaron
      var urlLayout = environment.RUTA_API + "/WEB/viewLayault?ObjType=" +this.data.ObjType + "&DocEntry=" +  this.data.DocEntry;

      // console.log("URL: "+urlLayout);
      var nombreFile = "DOC_" + this.data.ObjType + "_" + this.data.DocNum+".pdf";

      // mensajeHTML += "<br /><a href='" + urlLayout + "' target='_blank'>Click aqui para visualizar documento</a>"
      
    

      
      Swal.fire({
        title: 'Estas seguro que deseas enviar el correo?',
        text: this.mensaje,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: "Cerrar",
        confirmButtonText: 'Enviar correo.!',

      }).then(async (result) => {
        if (result.isConfirmed) {

          Swal.fire({
            title: 'Espere por favor!',
            html: 'Se está enviando el correo',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });
          
          
          var correo:envioCorreo = {
            empresa: "INDESAD",
            from:"SOPORTE SAP B1 <sap@indesad.cl>",
            to:this.correo,
            subject:this.asunto,
            html:mensajeHTML,
            ajunto: [
              {
                  tipo: "PDF",
                  accion: "GENERAR",
                  nombre: nombreFile,
                  url: urlLayout
              }
          ]
          }
          console.log(correo);
          console.log("origen: "+this.origen);
          var data:any = await this._api.enviaCorreo(correo, this.origen);


          if (data.estado) {
              Swal.fire('Mensaje', data.mensaje,'success');
              this.activeModal.close();
          }
          else {
              Swal.fire('Error', data.mensaje, 'error');
          }
        }
      })
    }
    cerrar() {
        this.activeModal.close();
    }
   

}