<mat-accordion style="margin: 5px;">
    <div class="row">


        <div class="col-12" style="display: flex;justify-content: center;">
            <div class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Buscar</mat-label>
                    <input [(ngModel)]="searchTerm" (keyup)="buscarDato()" type="text" matInput placeholder="Buscar">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

        </div>


        <div class="col-12" *ngFor="let row of dataDeADies" style="display: flex;margin-bottom: 10px;">
            <mat-expansion-panel style="width: 100%;">
                <mat-expansion-panel-header>
                    <mat-panel-title> <i *ngIf="row.Checked" class="fa-solid fa-circle-check checked-icon"></i> Nombre: {{row.CodigoLoteSerie}}</mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-list>
                    <mat-list-item>Stock: {{row.Quantity}}</mat-list-item>
                </mat-list>

            </mat-expansion-panel>
            <button *ngIf="!row.Checked" style="background: #B41B20;border: 1px solid #B41B20;" class="btn btn-danger" (click)="select(row)">Seleccionar</button>
            <button *ngIf="row.Checked" style="background: #B41B20;border: 1px solid #B41B20;" class="btn btn-danger" (click)="select(row)">Deseleccionar</button>
        </div>
        <div class="col-12" style="display: flex;justify-content: end;">
            <button style="background: #B41B20;border: 1px solid #B41B20;margin-right:15px;" class="btn btn-primary" (click)="cerrar()">Cerrar</button>

        </div>
    </div>


</mat-accordion>

