import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {IntranetoldService} from '../../../app/api/intranetold.service';
import {MongoService} from '../../../app/api/mongo.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-graficos-informedeventas',
  templateUrl: './graficos-informedeventas.component.html',
  styleUrls: ['./graficos-informedeventas.component.css']

})
export class GraficosInformedeventasComponent implements OnInit {

  constructor(
    private _api: IntranetoldService,
    private _apiMongo: MongoService
    ) { }

    lastThreeMonths = [];
  ngOnInit(): void {

    const today = new Date();
    for (let i = 2; i >= 0; i--) {
      const month = today.getMonth() - i + 1;
      const year = today.getFullYear();
      const formattedMonth = String(month).padStart(2, '0');
      this.lastThreeMonths.push(`${formattedMonth}|${year}`);
    }

    // Create chart instance
    am4core.useTheme(am4themes_animated);

    const date = new Date();
    this.mes = date.getMonth()+1;
    this.anio = date.getFullYear();

    this.periodo = this.mes.toString()+"|"+this.anio.toString();
    this.cargaInicial();


  }


  costoNeto: any;
  itemsCategoria: any;
  dataChartPie: any;
  queryVendedorCantidad: any;
  queryVendedorCantidadNumero: any;
  queryVentasPorVendedor: any;
  ventainformeventas: any;
  arrayventasporvendedor:any;
  ventainformestop3:any;
  ventainformeporcliente:any;
  periodo:any;
  copyventainformeporcliente:any;


  async verPeriodo(){
    var select = this.periodo.split('|');
    this.mes = select[0];
    this.anio = select[1];
    this.cargaInicial();
  }

  async cargaInicial() {
    await this.obtenerVendedor();
    await this.ventaInformesTop();
    await this.ventasInformeVentas();
    await this.metasPorVendedor();
    await this.ventaInformesPorCliente();




    // var data:any = await this._api.getQueryAll();
    // var query:string = "";
    // await data.objeto.forEach(element => {
    //   if (element.NOMBRE ==  "queryCostos1") {
    //     query = element.CONSULTA;
    //   }
    // });
    // var dato:any = await this._api.GetNotFileQuery(query);
    // this.costoNeto = [...dato.objeto];
    // this.costoNeto = Object.values(this.costoNeto[0]);
    // // console.log(this.costoNeto);

    // data = await this._api.getQueryAll();
    // var query:string = "";
    // await data.objeto.forEach(element => {
    //   if (element.NOMBRE == "queryOITMTest") {
    //     query = element.CONSULTA;
    //   }
    // });
    // dato = await this._api.GetNotFileQuery(query);
    // this.itemsCategoria = [...dato.objeto];
    // this.itemsCategoria = Object.values(this.itemsCategoria);


    // data = await this._api.getQueryAll();
    // var query:string = "";
    // await data.objeto.forEach(element => {
    //   if (element.NOMBRE == "queryVendedorCantidad") {
    //     query = element.CONSULTA;
    //   }
    // });
    // dato = await this._api.GetNotFileQuery(query);
    // this.queryVendedorCantidad = [...dato.objeto];
    // this.queryVendedorCantidad = Object.values(this.queryVendedorCantidad);
    // console.log(this.queryVendedorCantidad);


    // data = await this._api.getQueryAll();
    // var query:string = "";
    // await data.objeto.forEach(element => {
    //   if (element.NOMBRE == "QueryVentasPorVendedor") {
    //     query = element.CONSULTA;
    //   }
    // });
    // dato = await this._api.GetNotFileQuery(query);
    // this.queryVentasPorVendedor = [...dato.objeto];
    // this.queryVentasPorVendedor = Object.values(this.queryVentasPorVendedor);
    // console.log(this.queryVentasPorVendedor);


    var chartBar = am4core.create("chartBar", am4charts.XYChart3D);

    chartBar.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];

    // Create axes
    var categoryAxis = chartBar.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";

    var valueAxis = chartBar.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    // Create series
    var series = chartBar.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
    series.name = "Sales";
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    var series2 = chartBar.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "units";
    series2.dataFields.categoryX = "country";
    series2.name = "Units";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";

    // Add cursor
    chartBar.cursor = new am4charts.XYCursor();
  }

  mes: any;
  anio: any;

  jsonTest: any;
  jsonMetas: any = [];

  async metasPorVendedor() {

    var jsonTest01 = await this._apiMongo.getMetas(this.mes, this.anio);
    // var jsonTest01 = await this._apiMongo.getMetas("", "");
    this.jsonTest = jsonTest01;
    this.jsonMetas = [];
    // console.log("GETMETAS: ",this.jstonTest);
    for (var row of this.jsonTest) {
      for (var row1 of this.ventainformeventas) {
        if (row.SlpCode == row1.Codigo) {
          // console.log("ROW:",row);
          // console.log("ROW1:",row1);
          var dataFixed = {
            "SlpCode": row1.Codigo,
            "SlpName": row1.Vendedor,
            "Neto": row1.Neto,
            "Meta": row.VALOR,
            "Porcentaje": ((row1.Neto*100)/row.VALOR).toFixed(2)
          }
          // console.log("fixed:",dataFixed);
          this.jsonMetas.push(dataFixed);

          break;
        }
      }
    }
    // console.log("jsonmetas:",this.jsonMetas);
     console.log(this.jsonMetas);
  }



  async ventaInformesPorCliente() {
    var data: any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "VENTAINFORMEPORCLIENTE2") {
        query = element.CONSULTA;
      }
    });
    // var fechaStr = "'"+this.anio +"-"+ this.mes.toString().padStart(2,'00')+"-01"+"'";
    if (this.SlpCode) {
      query = query.replace("REEMPLAZA", this.mes);
      query = query.replace("REEMPLAZA1", this.anio);
      query = query.replace("REEMPLAZA", this.mes);
      query = query.replace("REEMPLAZA1", this.anio);
      query = query.replace("> -2", `= '${this.SlpCode}'`);
      query = query.replace("> -2", `= '${this.SlpCode}'`);
      console.log("query2: ",query);
    }
    else {
      query = query.replace("REEMPLAZA", this.mes);
      query = query.replace("REEMPLAZA1", this.anio);
      query = query.replace("REEMPLAZA", this.mes);
      query = query.replace("REEMPLAZA1", this.anio);
      console.log("query1: ",query);
    }

    var dato: any = await this._api.GetNotFileQuery(query);
    this.ventainformeporcliente = [...dato.objeto];
    this.ventainformeporcliente = Object.values(this.ventainformeporcliente);
    console.log(this.ventainformeporcliente);
  }
  vendedor:any;
  async obtenerVendedor() {
    var data: any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaVendedor") {
        query = element.CONSULTA;
      }
    });

    var dato: any = await this._api.GetNotFileQuery(query);
    this.vendedor = [...dato.objeto];
    console.log(this.vendedor);
  }

  async ventaInformesTop() {
    var data: any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "VENTAINFORMESTOP3.1") {
        query = element.CONSULTA;
      }
    });
    var fechaStr = "'"+this.anio +"-"+ this.mes.toString().padStart(2,'00')+"-01"+"'";
    if (this.SlpCode) {
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("> -2", `= '${this.SlpCode}'`);
      query = query.replace("> -2", `= '${this.SlpCode}'`);
    }
    else {
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
      query = query.replace("CURRENT_DATE", fechaStr);
    }
    // query = query.replace("", this.anio);
    // console.log("query: ",query);
    var dato: any = await this._api.GetNotFileQuery(query);
    this.ventainformestop3 = [...dato.objeto];
    this.ventainformestop3 = Object.values(this.ventainformestop3);
    // console.log(this.ventainformestop3);
  }


SlpCode:any;
SlpName:any;
  async ventasInformeVentas() {
    var data: any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "VENTAINFORMEVENTAS2.1") {
        query = element.CONSULTA;
      }
    });
    query = query.replace("REEMPLAZA", this.mes);
    query = query.replace("REEMPLAZA1", this.anio);
    query = query.replace("REEMPLAZA", this.mes);
    query = query.replace("REEMPLAZA1", this.anio);
    console.log("Query2: ",query);
    var dato: any = await this._api.GetNotFileQuery(query);
    this.ventainformeventas = [...dato.objeto];
    this.ventainformeventas = Object.values(this.ventainformeventas);
    // console.log("Venta informe ventas: "+this.ventainformeventas);

    var charPie = am4core.create("chartdiv", am4charts.PieChart);

    // for (var row of this.ventainformeventas){
    //   this.arrayventasporvendedor = {
    //     "grupo":row.CardCode
    //   }
    // }

    charPie.data = [];
    for await (var row of this.ventainformeventas) {
      var jsonPie = {
        "vendedor": row.Vendedor,
        "neto": row.Neto
      }
      charPie.data.push(jsonPie);
    }

    var pieSeries = charPie.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "neto";
    pieSeries.dataFields.category = "vendedor";
    pieSeries.dataFields.hidden = "hidden";
    pieSeries.slices.template.propertyFields.fill = "color";
    // Let's cut a hole in our Pie chart the size of 40% the radius
    charPie.innerRadius = am4core.percent(40);

    // Disable ticks and labels
    pieSeries.labels.template.disabled = false;
    pieSeries.ticks.template.disabled = false;
    pieSeries.slices.template.stroke = am4core.color("#0F121A");

    // Disable tooltips
    pieSeries.slices.template.tooltipText = "";

    var SlpName = "";
    var self = this;
    pieSeries.events.on("hit", function(ev) {
      const series: am4charts.PieSeries = ev.target.dataItem.component;
      series.slices.each((slice: am4core.Slice) => {
        // console.log(slice);
        if (slice.isActive) {
          console.log('Selected');
          console.log(slice.dataItem.values.radiusValue.value);

          slice.isActive = !slice.isActive;
          SlpName = slice.dataItem.properties.category;
          console.log('Slice Data Item Category');
          console.log(SlpName);

          //console.log(self.vendedor);

          if (SlpName) {
            var filtro = self.vendedor.filter(a=> a.SlpName == SlpName);
            self.SlpCode = filtro[0].SlpCode;
            self.SlpName = SlpName;
            console.log(filtro);
            console.log(self.SlpCode);

            self.cargaInicial();
          }

        }

      });
    });




    // Add a legend
    // charPie.legend = new am4charts.Legend();
    // charPie.legend.position = "right";
    // charPie.legend.scrollable = true;
  }

  cargarDatosOriginales() {
    this.SlpName = undefined;
    this.SlpCode = undefined;
    this.cargaInicial();
  }

}
