import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {MongoService} from "./../../../api/mongo.service"
import {MODELO_APROBACION} from "./../../../model/Mongo"
import {variableGlobal} from "../../../global.enum";

import {ModeloAprobacionAddEditComponent} from "./../../configuracion/modeloAprobacion/addEdit";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modeloAprobacion',
  templateUrl: './modeloAprobacion.component.html',
  styleUrls: ['./modeloAprobacion.component.sass']
})
export class ModeloAprobacionComponent implements OnInit {
  @ViewChild('table', { static: true }) table: APIDefinition;
  constructor(
    private _apiMongo:MongoService,
    private modalService: NgbModal,
  ) { }
  data:[MODELO_APROBACION];
  public configuration: Config;
  public columns: Columns[];
  ngOnInit() {
    this.cargaInicial();
  }
  @ViewChild('frmEstado', { static: true }) frmEstado: TemplateRef<any>;
  @ViewChild('frmAprobadores', { static: true }) frmAprobadores: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  async cargaInicial()
  {
    this.columns = [
      { key: 'NOMBRE', title: "Nombre"},
      { key: 'DESCRIPCION', title: 'Descripción' },
      { key: 'ACTIVO', title: 'Estado', cellTemplate:this.frmEstado },
      { key: 'DOCUMENTO', title: 'Documentos' },
      { key: 'PROCESA_EN', title: 'Procesado en' },
      { key: 'CANTIDAD_MINIMA_APROBACIONES', title: 'Cantidad Minima de aprobaciones' },
      { key: '', title: 'Cantidad Aprobadores', cellTemplate: this.frmAprobadores },
      { key: '', title: 'Acción', cellTemplate: this.frmAccion }
    ];

    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,
     
      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };




    this.cargaDataTabla();

    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  async addEditModelo(item:MODELO_APROBACION)
  {
    
    if (item == null) {
      item = {
        _id: "",
        ACTIVO: true,
        APROBADORES:[{
          id:"",
          MENSAJE:"",
          NOMBRE:""
        }],
        CANTIDAD_APROBACIONES_ACUMULADO:0,
        CANTIDAD_MINIMA_APROBACIONES:1,
        DESCRIPCION:"",
        DOCUMENTO:"",
        NOMBRE:"",
        PROCESA_EN:"",
        QUERY:"",
      }
      //this.data.push(item);
      //this.data = [...this.data];
    }

    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };


    const modalRef = this.modalService.open(ModeloAprobacionAddEditComponent, ngbModalOptions);
    modalRef.componentInstance.data = item;
    modalRef.result.then(async (data) => {
     this.cargaDataTabla();
    }, (reason) => {
      //this.editar(item);
    });

  }
  async cargaDataTabla()
  {
    var data:any = await this._apiMongo.getModeloAprobacionAll();
    //console.log(data);
    this.data = data.resultado;

  }
  async eliminarModelo(item:MODELO_APROBACION)
  {
    
   var data:any = await this._apiMongo.eliminaModeloAprobacion(item);
   if (data.estado) {
    Swal.fire("Mensaje", data.mensaje, "success");
    this.cargaDataTabla();
   }
   else
   {
    Swal.fire("Mensaje", data.mensaje, "error");
   }
    
  }
}
