
<div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Calendario</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <full-calendar #calendar [options]="calendarOptions" class="show-content"></full-calendar>
    
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
  
