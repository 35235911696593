 <!-- Content Wrapper. Contains page content -->
 <div class="content-wrapper">
    <!-- Content Header (Page header) -->
   
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Mantenedor socio de negocio</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body" >
                <div class="row">

                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6">
                    
                            <div class="row">
                                <div class="col-4">
                                    <label>Código</label>
                                </div>
                                <div class="col-5">
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="data.CardCode" />
                                </div>
                                <div class="col-3">
                                    <select class="form-control form-control-sm" [(ngModel)]="data.CardType">
                                        <option value="C">Cliente</option>
                                        <option value="S">Proovedor</option>
                                        <option value="L">Lead</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Nombre</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="data.CardName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Nombre extranjero</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="data.CardFName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Grupo</label>
                                </div>
                                <div class="col-8">
                                    <select class="form-control form-control-sm" [(ngModel)]="data.GroupCode">
                                        <option *ngFor="let item of GroupCode" [ngValue]="item.GroupCode">{{item.GroupName}}</option>
                                    </select>
                                 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Moneda</label>
                                </div>
                                <div class="col-8">
                                    <select class="form-control form-control-sm" [(ngModel)]="data.Currency">
                                        <option *ngFor="let item of Currency" [ngValue]="item.CurrCode">{{item.CurrName}}</option>
                                        <option value="##">Modendas (Todas)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>RUT</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="data.LicTradNum" />
                                </div>
                            </div>
                
                
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4">
                                    <label> </label>
                                </div>
                                <div class="col-8">
                                    <select class="form-control form-control-sm" disabled>
                                        <option value="">Moneda local</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Saldo de cuenta</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm text-right" value="{{data.Balance | iNDCLP}}" disabled  />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Entregas</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm text-right" value="{{data.DNotesBal | iNDCLP}}" disabled />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Pedidos clientes</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm text-right" value="{{data.OrdersBal | iNDCLP}}" disabled />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Oportunidades</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm text-right" [(ngModel)]="data.OprCount" disabled />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Cheques</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control form-control-sm text-right" value="{{data.ChecksBal | iNDCLP}}" disabled />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="card card-primary card-outline card-outline-tabs">
                        <div class="card-header p-1 border-bottom-0">
                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="tab-GeneralId" data-toggle="pill" href="#tab-General" role="tab" aria-controls="tab-General" aria-selected="true">General</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="personaContacto-tab" data-toggle="pill" href="#personaContacto" role="tab" aria-controls="personaContacto" aria-selected="false">Persona de contacto</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="direcciones-tab" data-toggle="pill" href="#direcciones" role="tab" aria-controls="direcciones" aria-selected="false">Direcciones</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="condicionPago-tab" data-toggle="pill" href="#condicionPago" role="tab" aria-controls="condicionPago" aria-selected="false">Condiciones de pago</a>
                            </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade show active" id="tab-General" role="tabpanel" aria-labelledby="tab-GeneralId">
                                <!--Inicio general-->
                                <div class="row">
                                    <div class="col-md-6">
                        
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.Phone1" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono 2</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.Phone2" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono móvil</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.Cellular"  />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Fax</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.Fax"  />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Correo electrónico</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.E_Mail" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Sitio Web</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.IntrntSite" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Forma de envío</label>
                                            </div>
                                            <div class="col-8">
                                                <select class="form-control form-control-sm" [(ngModel)]="data.general.ShipType">
                                                    <option *ngFor="let item of ShipType" [ngValue]="item.TrnspCode">{{item.TrnspName}}</option>
                                                </select>
                                           
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Clave de acceso</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.Password" />
                                            </div>
                                        </div>
        
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Persona de contacto</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.CntctPrsn" disabled />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>ID número 2</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.AddId" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>ID fiscal federal unificado</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.general.VatIdUnCmp" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Comentarios</label>
                                            </div>
                                            <div class="col-8">
                                                <textarea class="form-control form-control-sm" [(ngModel)]="data.general.Notes"></textarea>
                                        
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Empleado del dpto.de ventas</label>
                                            </div>
                                            <div class="col-8">
                                                <select class="form-control form-control-sm" [(ngModel)]="data.general.SlpCode">
                                                    <option *ngFor="let item of SlpCode" [ngValue]="item.SlpCode">{{item.SlpName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!--
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Responsable</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Código canal SN</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Técnico</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Territorio</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        -->
                                    
                                    </div>
                                </div>
                                <!--Fin general-->
                            </div>
                            <div class="tab-pane fade" id="personaContacto" role="tabpanel" aria-labelledby="personaContacto-tab">
                                <!--inicio persona de contacto-->
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <table class="table table-hover text-nowrap">
                                                <tbody>
                                                    <tr *ngFor="let item of data.personasContactos" (click)="cambiaPeronaContacto(item);">
                                                        <td>{{item.Name}}</td>
                                                        <td><button class="btn btn-danger btn-sm" (click)="eliminarContacto(item);">X</button></td>
                                                    </tr>
                                                    <tr  (click)="nuevoContacto();">
                                                        <td colspan="2">Definir nuevo</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                    
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>ID de contacto</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Name" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Nombre</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].FirstName" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Segundo nombre</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].MiddleName" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Apellido</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].LastName" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Título</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Title" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Posición</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Position" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Dirección</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Address" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono 1</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Tel1"  />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono 2</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Tel2" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Teléfono móvil</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Cellolar" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Fax</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Fax" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Correo electrónico</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].E_Mail" />
                                            </div>
                                        </div>
                                        <!--
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Grupo de correo electrónico</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        -->
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Pager</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.personasContactos[contactoPosicion].Pager" />
                                            </div>
                                        </div>
                                        <!--
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Observaciones 1</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Observaciones 2</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Clave de acceso</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>País/Región de nacimiento</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Fecha de nacimiento</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Sexo</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Profesión</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Lugar de nacimiento</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Dirección conectada</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        -->
                                    
                                    </div>
                                </div>
                                <!--Fin persona de contacto-->
                            </div>
                            <div class="tab-pane fade" id="direcciones" role="tabpanel" aria-labelledby="direcciones-tab">
                                <!--inicio direccion-->
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <table class="table table-hover text-nowrap">
                                                <thead>
                                                <th colspan="2">
                                                    Direccion facturación
                                                </th>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of getFactura()"  (click)="cambiaDireccion(item);">
                                                        <td>{{item.Address}}</td>
                                                        <td><button class="btn btn-danger btn-sm" (click)="eliminarDireccion(item);">X</button></td>
                                                    </tr>
                                                    <tr  (click)="nuevoDireccion('B');">
                                                        <td colspan="2">Definir nuevo</td>
                                                    </tr>
                                                </tbody>
                                                <thead>
                                                <th colspan="2">
                                                    Direccion despacho
                                                </th>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of getDespacho()"  (click)="cambiaDireccion(item);">
                                                        <td>{{item.Address}}</td>
                                                        <td><button class="btn btn-danger btn-sm" (click)="eliminarDireccion(item);">X</button></td>
                                                    </tr>
                                                    <tr  (click)="nuevoDireccion('S');">
                                                        <td colspan="2">Definir nuevo</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                    
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>ID de dirección</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Address" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Nombre de dirección 2</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Address2" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Nombre de dirección 3</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Address3" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Calle/ Número</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Street" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Colonia</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Block" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Ciudad</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].City" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Código postal</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].ZipCode" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Condado</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].County" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Estado</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].State"  />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>País/Región</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].Country" />
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="data.direcciones[direccionPosicion].tipoDireccion == 'S'">
                                            <div class="col-4">
                                                <label>Indicador de impuestos</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.direcciones[direccionPosicion].TaxCode" />
                                            </div>
                                        </div>
                                        <!--
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Número de la calle</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Edificio/Planta/Sala</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Hacienda</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>GLN</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        -->
                                    
                                    </div>
                                </div>
                                <!--fin direccion-->
                            </div>
                            <div class="tab-pane fade" id="condicionPago" role="tabpanel" aria-labelledby="condicionPago-tab">
                                <!--Inicio condicion de pago-->
                                <div class="row">
                                    <div class="col-md-6">
                        
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Condiciones de pago</label>
                                            </div>
                                            <div class="col-8"> 
                                                <select class="form-control form-control-sm" [(ngModel)]="data.CondicionPago.GroupNum">
                                                    <option *ngFor="let item of GroupNum" [ngValue]="item.GroupNum">{{item.PymntGroup}}</option>
                                                </select>
                                       
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>% intereses por retraso</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Lista de precios</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>% descuento total</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Límite de crédito</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Límite de comprometido</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Plazo reclamaciones</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                 
                                    </div>
                                    <div class="col-md-6">
                   
                                    </div>
                                </div>
                                <!--Fin condicion de pago-->
                            </div>
                            </div>
                        </div>
                        <!-- /.card -->
                        </div>
                    </div>
                    </div>

                </div>
                    <div class="col-md-2">
                        <div class="row">
                          
                                <label>GIRO</label>
                       
                                <input type="text" class="form-control form-control-sm" />
                       
                        </div>
                    </div>
                </div>
  
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  
  
  
  
  