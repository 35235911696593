
<!--<body class="hold-transition sidebar-mini layout-fixed" data-panel-auto-height-mode="height" *ngIf="_template == template.NORMAL">-->
<body class="{{general.CLASS_BODY}}" style="height: auto;" *ngIf="_template == template.NORMAL">
<div class="wrapper">

<app-navbar ></app-navbar>

<app-sidebar ></app-sidebar>

   <!-- Content Wrapper. Contains page content -->
 <div class="content-wrapper" *ngIf="_templateV2 == template.NORMAL">
    <!-- Content Header (Page header) -->
   
  
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- /.content -->

   
</div>



<app-footer ></app-footer>

</div>
<!-- ./wrapper -->
</body>





<div *ngIf="_template == template.LOGIN">
  
    <router-outlet></router-outlet>

</div>