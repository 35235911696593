import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { variableGlobal } from 'src/app/global.enum';

import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { MongoService } from 'src/app/api/mongo.service';
import { DOCUMENTO_MARKETING_PRELIMINAL, SolicitarAprobacion } from 'src/app/model/Mongo';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ModeloAprobacion',
  templateUrl: './ModeloAprobacion.component.html',
  styleUrls: ['./ModeloAprobacion.component.scss']
})
export class ModeloAprobacionCRMComponent implements OnInit {

  obj: DOCUMENTO_MARKETING_PRELIMINAL;

  public configuration: Config;
  public columns: Columns[];
  @ViewChild('tabla', { static: true }) tabla: APIDefinition;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _apiMongo: MongoService,    
    ) { }

  ngOnInit() {
    
    this.cargaInicial()
  }
  @ViewChild('frmComentario', { static: true }) frmComentario: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;



  cargaInicial() {
    console.log(this.obj);
    this.columns = [
      { key: 'modeloAprobacion.NOMBRE', title: "Nombre" },
      { key: 'MENSAJE', title: 'Comentario', cellTemplate: this.frmComentario, width: "400px" },
      // { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

    ];
    this.configuration = {
      ...DefaultConfig,
      searchEnabled: false,
      detailsTemplate: false,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: false,
      rowReorder: false,
      columnReorder: false,
      selectRow: false,

      paginationEnabled: true,
      fixedColumnWidth: true,

      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

    };


    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }

  async CrearPreliminar() {
    // console.log(datos);

    var filtro = await this.obj.APROBACION.filter(({MENSAJE}) => MENSAJE == '');
    ///console.log(filtro);

    if (filtro.length == 0) {
       var resp:any = await this._apiMongo.AgregarDocumentoPreliminar(this.obj);
       console.log(resp);
       if(resp.estado)
       {
          this.activeModal.close(resp);
       }
       else
       {
          Swal.fire("Mensaje", resp.mensaje, "error");
       }
       
    }
    else
    {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Favor agregar mensaje a todos los modelos de aprobación',
        showConfirmButton: false,
        timer: 2500
      })
    }
   
    
  }

  cerrar() {
    this.activeModal.close();
  }
  private setClass(name: string): void {
    this.tabla.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
}



