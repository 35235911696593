<div class="modal-header" style="height: 65px">
  <h4
    class="modal-title titulo-notadeventa"
    id="modal-basic-title"
    style="color: #91ca41"
  >
    <p>Selecciona Proveedor</p>

  </h4>

  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="cerrar()"
    style="color: white"
  >
    <span aria-hidden="true" style="color: #91ca41">&times;</span>
  </button>
</div>


    <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns" (event)="traerCardCode($event)"></ngx-table>
