import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { variableGlobal } from './../global.enum'
import { DOCUMENTO_MARKETING_PRELIMINAL, MODELO_APROBACION } from "./../model/Mongo"
import { Respuesta } from "./../model/Indesad"
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MongoService {

  constructor(
    private _http: HttpClient,
  ) { }


  async getModeloAprobacionAll() {
    const body: Respuesta =
    {
      TOKEN: variableGlobal.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/getModeloAprobacionAll', body).toPromise();
  }
  async getIdModeloAprobacion(datos: MODELO_APROBACION) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      resultado: datos
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/getIdModeloAprobacion', body).toPromise();
  }
  async addEditModeloAprobacion(datos: MODELO_APROBACION) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      resultado: datos
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/addEditModeloAprobacion', body).toPromise();
  }
  async eliminaModeloAprobacion(datos: MODELO_APROBACION) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      resultado: datos
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/eliminaModeloAprobacion', body).toPromise();
  }
  async GetDocumentosPreliminares() {

    const body: Respuesta =
    {

      TOKEN: variableGlobal.TOKEN,
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/getDocumentoMarketingPreliminalAll', body).toPromise();
  }
  async AgregarDocumentoPreliminar(datos: DOCUMENTO_MARKETING_PRELIMINAL) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      resultado: datos
    }
    //console.log("BODY");
    //console.log(body);

    return this._http.post(environment.RUTA_API + '/api/Mongo/addEditDocumentoMarketingPreliminal', body).toPromise();
  }

  async getObjetosSAP() {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN
    }

    return this._http.post(environment.RUTA_API + '/api/Mongo/getObjetosSAP', body).toPromise();
  }

  async apruebaORechazaDocumento(preliminal) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      resultado: preliminal
    }

    return this._http.post(environment.RUTA_API + '/api/Mongo/apruebaORechazaDocumento', body).toPromise();
  }

  async getMetas(mes, anio) {
    return this._http.get(environment.RUTA_API + `/api/Mongo/getMetas?mes=${mes}&anio=${anio}`).toPromise();
  }

  async getAllMetas() {
    return this._http.get(environment.RUTA_API + `/api/Mongo/getAllMetas`).toPromise();
  }

  async actualizarMeta(data) {
    const body =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      objeto: data
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/actualizarMetaVendedor', body).toPromise();
  }

  async crearMetaVendedor(data) {
    const body =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      objeto: data
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/crearMetaVendedor', body).toPromise();
  }

  async eliminarMeta(data) {
    const body =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      objeto: data
    }
    return this._http.post(environment.RUTA_API + '/api/Mongo/eliminarMeta', body).toPromise();
  }

  async actualizarContrasena(data) {
    const body =
    {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      objeto: data
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/Mongo/actualizaContrasena', body).toPromise();
  }

  async creaEditaArticuloTemporal(data, crea, id: string = "") {
    var body = {}
    if (crea) {

      body = {
        "estado": true,
        "token": variableGlobal.TOKEN,
        "objeto": {
          "item": data
        }

      }
    } else {
      body = {
        "estado": true,
        "token": variableGlobal.TOKEN,
        "objeto": {
          "_id": id,
          "item": data
        }
      }
    }

    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/Mongo/creaEditaArticuloTemporal', body).toPromise();
  }
  async consultaArtivuloTemporal(id: string = "") {

      var body = {
        estado: false,
        TOKEN: variableGlobal.TOKEN,
        objeto: {
          _id: id
        }
      }


    return this._http.post(environment.RUTA_API + '/api/Mongo/consultaArtivuloTemporal', body).toPromise();
  }
  async eliminaArtivuloTemporal(id: string) {
    var body = {
      estado: false,
      TOKEN: variableGlobal.TOKEN,
      objeto: {
        _id: id
      }
    }

    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/Mongo/eliminaArtivuloTemporal', body).toPromise();
  }
}
