<div class="loading" *ngIf="cargando">
  <img class="efecto-rotacion" src="assets/LogoTW.png" alt="" srcset="" width="115px" height="33px"
    style="margin-top: 30%;">
</div>

<div class="modal-header" style="height: 65px">
  <h4 class="modal-title titulo-notadeventa" id="modal-basic-title" style="color: #91ca41">
    <p *ngIf="crear">Crear Maestro Artículo</p>
    <p *ngIf="!crear">Editar Maestro Artículo</p>
  </h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()" style="color: white">
    <span aria-hidden="true" style="color: #91ca41">&times;</span>
  </button>
</div>



<div class="row" style="margin: 0px; margin-top: 10px">
  <div class="col-6">
    <div class="row" style="margin: 0px">
      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">

              Número de Artículo

              <span class="asteriscorojo">
                *
              </span>

            </span>


          </div>
          <select *ngIf="ocultar" [(ngModel)]="body.Series" class="custom-select" id="inputGroupSelect01" required>
            <option value="" disabled>Seleccionar...</option>
            <option value="3">Manual</option>
          </select>
          <input *ngIf="!this.administrador" [(ngModel)]="body.ItemCode" type="text" class="form-control"
            placeholder="Código de Artículo" aria-label="Username" aria-describedby="basic-addon1" required disabled />
          <input *ngIf="this.administrador" [(ngModel)]="body.ItemCode" type="text" class="form-control"
            placeholder="Código de Artículo" aria-label="Username" aria-describedby="basic-addon1" required />
        </div>
      </div>

      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Descripción
              <span class="asteriscorojo">
                *
              </span>
            </span>

          </div>
          <input [(ngModel)]="body.ItemName" type="text" class="form-control" placeholder="Descripción"
            aria-label="Username" aria-describedby="basic-addon1" />
        </div>
      </div>

      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Nombre Extranjero</span>
          </div>
          <input [(ngModel)]="body.FrgnName" type="text" class="form-control" placeholder="Nombre Extranjero"
            aria-label="Username" aria-describedby="basic-addon1" />
        </div>
      </div>

      <div *ngIf="ocultar"  class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Selec. Clase de artículo
              <span class="asteriscorojo">
                *
              </span>
            </span>
          </div>
          <select [(ngModel)]="body.ItemType" class="custom-select" id="inputGroupSelect01">
            <option value="" disabled>Seleccionar...</option>
            <option value="I">Artículos</option>
            <option value="L">Trabajo</option>
            <option value="T">Viaje</option>

          </select>
        </div>
      </div>

      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Selec. Grupo de artículos
              <span class="asteriscorojo">
                *
              </span>
            </span>

          </div>
          <select [(ngModel)]="body.ItmsGrpCod" class="custom-select" id="inputGroupSelect01"
            (change)="familiaCategoria2()">
            <option value="" disabled>Seleccionar...</option>
            <option *ngFor="let row of grupoArticulos" value="{{row.ItmsGrpCod}}">{{row.ItmsGrpNam}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="ocultar" class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Selec. Grupo unid. de medida</span>
          </div>
          <select [(ngModel)]="body.UgpEntry" class="custom-select" id="inputGroupSelect01">
            <option value="" disabled>Seleccionar...</option>
            <option value="-1">Manual</option>

          </select>
        </div>
      </div>

      <div *ngFor="let row of body.listaPrecios">




      </div>
      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Selec. lista de precios
              <!--               <span class="asteriscorojo">
                *
              </span> -->
            </span>
          </div>

          <select [(ngModel)]="body.PriceList" class="custom-select" id="inputGroupSelect01">
            <option selected>Seleccionar...</option>
            <option *ngFor="let row of listaPrecios" value="{{row.ListNum}}">{{row.ListName}}</option>
          </select>

        </div>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="row" style="margin: 0px">
      <div class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.InvntItem" (change)="setValue('InvntItem', $event)">
          Artículo de inventario</mat-checkbox>


      </div>
      <div class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.SellItem" (change)="setValue('SellItem', $event)">
          Artículo venta</mat-checkbox>
      </div>
      <div class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.PrchseItem" (change)="setValue('PrchseItem', $event)">
          Artículo de compra</mat-checkbox>
      </div>

      <div class="col-12" *ngFor="let row of body.userFields">

        <div class="col-12" style="margin-top: 12px; padding-left: 0px;
        padding-right: 0px;" *ngIf="row.Field == 'U_IND_CATEGORIA2'">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Familia

                <span class="asteriscorojo">
                  *
                </span>
              </span>
            </div>
            <select [(ngModel)]="row.Value" class="custom-select" id="inputGroupSelect01"
              (change)="familiaCategoria3($event)" title="Este select depende del Grupo de artículos seleccionado.">
              <option selected disabled>Seleccionar...</option>
              <option *ngFor="let item of Familia" value="{{item}}">{{item}}</option>
            </select>
          </div>
        </div>

      </div>



      <div class="col-12" *ngFor="let row of body.userFields">
        <div class="input-group mb-3" *ngIf="row.Field == 'U_IND_CATEGORIA3'">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 146px;">Sub-Familia
              <span class="asteriscorojo">
                *
              </span>
            </span>
          </div>

          <select [(ngModel)]="row.Value" class="custom-select" id="inputGroupSelect01" title="Este select depende de la Familia seleccionada.">
            <option selected disabled>Seleccionar...</option>
            <option *ngFor="let item of SubFamilia" value="{{item}}">{{item}}</option>
          </select>
        </div>
      </div>



      <div *ngIf="ocultar" class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 146px;">Código de barras</span>
          </div>
          <input [(ngModel)]="body.CodeBars" type="text" class="form-control" placeholder="Código" aria-label="Username"
            aria-describedby="basic-addon1" />
        </div>
      </div>


      <div class="col-12" *ngFor="let row of body.listaPrecios">

        <div class="col-12" style="padding-left: 0px;padding-right: 0px;" *ngIf="row.PriceList == body.PriceList">
          <div class="input-group mb-3">

            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Precio por unidad
                <!--                 <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>

            <input [(ngModel)]="row.Price" type="text" class="form-control" placeholder="Precio" aria-label="Username"
              aria-describedby="basic-addon1" />
          </div>

        </div>

      </div>



    </div>
  </div>
</div>

<mat-tab-group style="height: 355px;">
  <mat-tab label="General">
    <div class="row" style="margin-top: 5px; margin-left: 5px;margin-right: 0px;">
      <div *ngIf="ocultar"  class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.WTLiable" (change)="setValue('WTLiable', $event)">Sujeto
          a retención de impuesto</mat-checkbox>
      </div>

      <!--  <div class="col-12" *ngIf="body.IndirctTax == 'Y'"> -->
      <div *ngIf="ocultar"  class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.VATLiable" (change)="setValue('VATLiable', $event)">
          Sujeto a impuesto</mat-checkbox>
      </div>

      <!--       <div class="col-12" *ngIf="body.IndirctTax == 'N' || body.IndirctTax == null || body.IndirctTax == ''">
        <mat-checkbox class="example-margin" [(ngModel)]="body.IndirctTax" (change)="setValue('VATLiable', $event)">Sujeto a impuesto</mat-checkbox
        >
      </div> -->




      <!-- <div class="col-12" *ngIf="body.VATLiable == 'Y'"> -->
      <div *ngIf="ocultar"  class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.IndirctTax" (change)="setValue('IndirctTax', $event)">
          Impuesto indirecto</mat-checkbox>
      </div>

      <!--       <div class="col-12" *ngIf="body.VATLiable == 'N'">
        <mat-checkbox class="example-margin" (change)="setValue('IndirctTax', $event)" disabled>Impuesto indirecto</mat-checkbox>
      </div> -->

      <div *ngIf="ocultar"  class="col-12">
        <mat-checkbox class="example-margin" [(ngModel)]="body.NoDiscount" (change)="setValue('NoDiscount', $event)">No
          aplicar grupo de descuento</mat-checkbox>
      </div>

      <div class="col-6">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="width: 208px;">Fabricante
              <span class="asteriscorojo">
                *
              </span>
            </span>
          </div>
          <select [(ngModel)]="body.FirmCode" class="custom-select" id="inputGroupSelect01" ng-placeholder="Fabricante">
            <option value="" disabled selected>Seleccionar fabricante...</option>
            <option *ngFor="let row of listaFabricantes" value="{{row.FirmCode}}">{{row.FirmName}}</option>

          </select>
        </div>
      </div>

      <div class="col-12" style="padding: 0px;" *ngFor="let row of body.userFields">

        <div class="col-6" *ngIf="row.Field=='U_IND_SWW'">
          <div class="input-group mb-3">

            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 208px;">Código Antiguo
                <span class="asteriscorojo">
                  *
                </span>
              </span>
            </div>

            <input [(ngModel)]="row.Value" type="text" class="form-control" placeholder="Código Antiguo"
              aria-label="Username" aria-describedby="basic-addon1" />

          </div>
        </div>



      </div>

      <div class="col-12" style="padding: 0px;">
        <div *ngIf="ocultar" class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 208px;">Forma de envío</span>
            </div>
            <select [(ngModel)]="body.ShipType" class="custom-select" id="inputGroupSelect01">
              <option value="" disabled>Seleccionar...</option>
              <option *ngFor="let row of formasEnvio" value="{{row.TrnspCode}}">{{row.TrnspName}}</option>

            </select>
          </div>
        </div>

        <p style="
          margin-left: 10px;
          font-size: 20;
          font-weight: bold;
          text-decoration: underline;
        ">
          Número de serie y lote
        </p>

        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 208px;">Artículo gestionado por
                <span class="asteriscorojo">
                  *
                </span>
              </span>
            </div>
            <select [(ngModel)]="ArticuloG" (change)="SlcLoteSerie($event)" class="custom-select"
              id="inputGroupSelect01">
              <option value="" disabled>Seleccionar...</option>
              <option value="0">Ninguno</option>
              <option value="1">Número de serie</option>
              <option value="2">Lotes</option>
            </select>
          </div>

          <div *ngIf="body.ManBtchNum || body.ManSerNum" class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 208px;">Método de gestión</span>
            </div>
            <select [(ngModel)]="body.MngMethod" class="custom-select" id="inputGroupSelect01">
              <option value="" disabled>Seleccionar...</option>
              <option value="A">En todas las transacciones</option>
              <option value="R">Sólo en release</option>
            </select>
          </div>
          <mat-checkbox *ngIf="body.ManSerNum && body.MngMethod == 'R'" class="example-margin">Creación automática de
            números de serie </mat-checkbox>


        </div>
      </div>
    </div>
  </mat-tab>




  <mat-tab label="Datos de Compras">
    <div class="row" style="margin-top: 5px; margin-left: 5px;margin-right: 0px;">
      <div class="col-6" style="margin-top: 5px;">

        <div class="row">

          <div  *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Proveedor predeterminado</span>
              </div>
              <input [(ngModel)]="body.CardCode" type="text" class="form-control" placeholder="Proveedor predeterminado"
                aria-label="Username" aria-describedby="basic-addon1" />

              <button (click)="buscarCardCode()" style="background: #96D23A;"><i
                  class="fa-solid fa-magnifying-glass"></i></button>
            </div>
          </div>

          <!--           <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Número de catálogo de fabricante</span>
              </div>
              <input [(ngModel)]="body.SuppCatNum" type="text" class="form-control"
                placeholder="Número de catálogo de fabricante" aria-label="Username" aria-describedby="basic-addon1" />
            </div>
          </div> -->

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Nombre de unidad de medida de
                  compras
                  <span class="asteriscorojo">
                    *
                  </span>
                </span>
              </div>
              <!--              <input [(ngModel)]="body.BuyUnitMsr" type="text" class="form-control"
                placeholder="Nombre de unidad de medidad de compras" aria-label="Username"
                aria-describedby="basic-addon1" /> -->

              <select [(ngModel)]="body.BuyUnitMsr" class="custom-select" id="inputGroupSelect01">
                <option value="" disabled>Seleccionar...</option>
                <option *ngFor="let row of unidadMedida" value="{{row}}">{{row}}</option>
              </select>

            </div>
          </div>

          <div  *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Artículos por unidad comprar
                  <span class="asteriscorojo">
                    *
                  </span>
                </span>
              </div>
              <input [(ngModel)]="body.NumInBuy" type="text" class="form-control"
                placeholder="Artículos por unidad comprar" aria-label="Username" aria-describedby="basic-addon1" />
            </div>
          </div>

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Nombre de unidad de medida de
                  embalaje</span>
              </div>
              <input [(ngModel)]="body.PurPackMsr" type="text" class="form-control"
                placeholder="Nombre de unidad de medida de embalaje" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Cantidad por paquete</span>
              </div>
              <input [(ngModel)]="body.PurPackUn" type="text" class="form-control" placeholder="Cantidad por paquete"
                aria-label="Username" aria-describedby="basic-addon1" />
            </div>
          </div>

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="height: 38px;" style="width: 339px;">Grupo de
                  aduanas</span>
              </div>
              <select [(ngModel)]="body.CstGrpCode" class="custom-select" id="inputGroupSelect01">
                <option value="" disabled>Seleccionar...</option>
                <option *ngFor="let row of grupoAduanas" value="{{row.CstGrpCode}}">{{row.CstGrpName}}</option>

              </select>
              <input [(ngModel)]="body.TotalTax" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
              <p style="font-size: 25px; margin-left: 5px; margin-right: 5px">%</p>
            </div>
          </div>

        </div>

      </div>

      <div class="col-6" style="margin-top: 5px;">

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Longitud{{BLongitudUM}}
                <!--                 <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.BLength1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Longitud en cm" aria-label="Username" aria-describedby="basic-addon1" />
            <button title="Este botón copia los campos de compra a venta: unidad y dimensiones."
              (click)="copiarVenta();" style="background: #96D23A;"><i class="fa-solid fa-angles-right"></i></button>
          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Ancho{{BAnchoUM}}
                <!--
                  <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.BWidth1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Ancho en cm" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Altura{{BAlturaUM}}
                <!--                 <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.BHeight1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Altura en cm" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div class="col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Volumen
                <!--               <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.BVolume" type="number" step="0.1" class="form-control"
              placeholder="Proveedor predeterminado" aria-label="Username" aria-describedby="basic-addon1" />
            <select [(ngModel)]="body.BVolUnit" class="custom-select" id="inputGroupSelect01">
              <option value="" disabled>Unidad...</option>
              <option value="4">cm</option>
              <option value="2">cc</option>
              <option value="5">ci</option>
              <option value="1">cmm</option>
              <option value="3">dm3</option>
              <option value="6">vgl</option>
            </select>
          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Peso{{BPesoUM}}
                <!--                 <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.BWeight1" (change)="kgfuncion()" (blur)="kgfuncion()" type="number"
              class="form-control" placeholder="Peso en kg" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <br  *ngIf="ocultar">

        <div  *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 1</span>
            </div>
            <input [(ngModel)]="body.PurFactor1" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div  *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 2</span>
            </div>
            <input [(ngModel)]="body.PurFactor2" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div  *ngIf="ocultar" class="col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 3</span>
            </div>
            <input [(ngModel)]="body.PurFactor3" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div  *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 4</span>
            </div>
            <input [(ngModel)]="body.PurFactor4" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <br>



      </div>



    </div>
  </mat-tab>


  <mat-tab label="Datos de Ventas">
    <div class="row" style="margin-top: 5px; margin-left: 5px;margin-right: 0px;">
      <div class="col-6" style="margin-top: 5px;">
        <div class="row">

          <div class="col-12">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Nombre de unidad de medida de
                  ventas
                  <span class="asteriscorojo">
                    *
                  </span>
                </span>
              </div>
              <!-- <input [(ngModel)]="body.SalUnitMsr" type="text" class="form-control"
                placeholder="Proveedor predeterminado" aria-label="Username" aria-describedby="basic-addon1" /> -->

              <select [(ngModel)]="body.SalUnitMsr" class="custom-select" id="inputGroupSelect01">
                <option value="" disabled>Seleccionar...</option>
                <option *ngFor="let row of unidadMedida" value="{{row}}">{{row}}</option>
              </select>

            </div>
          </div>

          <div  *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Artículos por unidad de ventas
                  <span class="asteriscorojo">
                    *
                  </span>
                </span>
              </div>
              <input [(ngModel)]="body.NumInSale" type="text" class="form-control"
                placeholder="Número de catálogo de fabricante" aria-label="Username" aria-describedby="basic-addon1" />



            </div>
          </div>

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Nombre de unidad de medida de em
                  <span class="asteriscorojo">
                    *
                  </span></span>
              </div>
              <input [(ngModel)]="body.SalPackUn" type="text" class="form-control"
                placeholder="Nombre de unidad de medidad de compras" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 339px;">Cantidad por paquete</span>
              </div>
              <input type="text" class="form-control" placeholder="Artículos por unidad comprar" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-6" style="margin-top: 5px;">

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Longitud{{SLongitudUM}}</span>
            </div>
            <input [(ngModel)]="body.SLength1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Longitud en cm" aria-label="Username" aria-describedby="basic-addon1" />


          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Ancho{{SAnchoUM}}</span>
            </div>
            <input [(ngModel)]="body.SWidth1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Ancho en cm" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Altura{{SAlturaUM}}</span>
            </div>
            <input [(ngModel)]="body.SHeight1" (change)="calculaVolumen()" (blur)="calculaVolumen()" type="number"
              class="form-control" placeholder="Altura en cm" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div class="col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Volumen
                <!--                 <span class="asteriscorojo">
                  *
                </span> -->
              </span>
            </div>
            <input [(ngModel)]="body.SVolume" type="number" step="0.1" class="form-control"
              placeholder="Proveedor predeterminado" aria-label="Username" aria-describedby="basic-addon1" />
            <select [(ngModel)]="body.SVolUnit" class="custom-select" id="inputGroupSelect01">
              <option value="" disabled>Unidad...</option>
              <option value="4">cm</option>
              <option value="2">cc</option>
              <option value="5">ci</option>
              <option value="1">cmm</option>
              <option value="3">dm3</option>
              <option value="6">vgl</option>
            </select>
          </div>
        </div>

        <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Peso{{SPesoUM}}</span>
            </div>
            <input [(ngModel)]="body.SWeight1" (change)="kgfuncion()" (blur)="kgfuncion()" type="number" step="0.1"
              class="form-control" placeholder="Peso en kg" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <br  *ngIf="ocultar">

        <div *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 1</span>
            </div>
            <input [(ngModel)]="body.SalFactor1" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 2</span>
            </div>
            <input [(ngModel)]="body.SalFactor2" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div  *ngIf="ocultar" class="col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 3</span>
            </div>
            <input [(ngModel)]="body.SalFactor3" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <div *ngIf="ocultar" class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1" style="width: 146px;">Factor 4</span>
            </div>
            <input [(ngModel)]="body.SalFactor4" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div>

        <br>

        <!--         <div class="col-12">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Crear código QR de</span>
            </div>
            <input [(ngModel)]="body.QRCodeSrc" type="text" class="form-control" placeholder="Proveedor predeterminado"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
        </div> -->


      </div>






    </div>
  </mat-tab>


  <mat-tab label="Datos de Inventario">
    <div class="row" style="margin-top: 5px; margin-left: 5px;margin-right: 0px;">


      <div class="col-6" style="margin-top: 5px;">

        <div class="row">

          <div *ngIf="ocultar" class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 216px;">Fijar ctas de mayor según</span>
              </div>
              <select [(ngModel)]="body.GLMethod" class="custom-select" id="inputGroupSelect01">
                <option value="W">Almacén</option>
                <option value="C" (select)="true">Grupo de artículo</option>
                <option value="L">Nivel de artículo</option>
              </select>
            </div>
          </div>

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 216px;">Nombre unid. de medida
                  <span class="asteriscorojo">
                    *
                  </span>
                </span>
              </div>
              <!--               <input [(ngModel)]="body.InvntryUom" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" /> -->

              <select [(ngModel)]="body.InvntryUom" class="custom-select" id="inputGroupSelect01">
                <option value="" disabled>Seleccionar...</option>
                <option *ngFor="let row of unidadMedida" value="{{row}}">{{row}}</option>
              </select>
            </div>
          </div>

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 216px;">Peso
                  <!--                   <span class="asteriscorojo">
                    *
                  </span> -->
                </span>
              </div>
              <input [(ngModel)]="body.IWeight1" type="number" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>


        </div>


      </div>


      <div  *ngIf="ocultar" class="col-6" style="margin-top: 5px;">

        <div class="row">

          <div class="col-12">
            <mat-checkbox class="example-margin" [(ngModel)]="body.ByWh" (change)="setValue('IndirctTax', $event)">
              Gestión de stocks por almacén</mat-checkbox>
          </div>

          <!--           <div class="col-12">
            <p style="font-size: 22px;text-decoration: underline;">Nivel de stock</p>
          </div>

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Necesario (UdM de Compras)</span>
              </div>
              <input [(ngModel)]="body.ReorderQty" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>


          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Mínimo</span>
              </div>
              <input [(ngModel)]="body.MinLevel" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div>

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Máximo</span>
              </div>
              <input [(ngModel)]="body.MaxLevel" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div> -->


        </div>


      </div>

      <br>

      <div  *ngIf="ocultar" class="col-6" style="margin-top: 5px;">
        <div class="row">

          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1" style="width: 216px;">Método de valoración</span>
              </div>
              <select [(ngModel)]="body.EvalSystem" class="custom-select" id="inputGroupSelect01">
                <option value="" disabled [selected]="true">Seleccionar...</option>
                <option value="A">Promedio ponderado</option>
                <option value="S">Estándar</option>
                <option value="F">FIFO</option>
                <!--            <option value="B">Serie/Lote</option> -->
              </select>
            </div>
          </div>

          <!--           <div class="col-12" *ngIf="body.EvalSystem == 'A' || body.EvalSystem == 'S'">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Costo del artículo</span>
              </div>
              <input [(ngModel)]="body.AvgPrice" type="text" class="form-control" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1" />
            </div>
          </div> -->




        </div>
      </div>

      <div class="col-6"></div>

      <div class="col-12 tabla-bodegas" *ngIf="body.OITWs">

        <ngx-table *ngIf="body.OITWs.length > 0" #table [configuration]="configuration" [data]="body.OITWs"
          [columns]="columns"></ngx-table>
      </div>


    </div>
  </mat-tab>


</mat-tab-group>

<div class="modal-footer" style="height: 65px">

  <!--   <button mat-raised-button color="primary" style="background: #91ca41;margin-right: 5px;"
  (click)="verBody()">ver
    body</button> -->

  <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="cerrar()">Cerrar</button>

  <button *ngIf=" this.crear" mat-raised-button color="primary" style="background: #91ca41;margin-right: 10px;"
    (click)="crearArticuloTemporal()">Crear Artículo Temporal</button>

  <button *ngIf=" !this.crear" mat-raised-button color="primary" style="background: #91ca41;margin-right: 10px;"
    (click)="crearArticuloTemporal()">Actualizar Artículo Temporal</button>

  <button *ngIf="this.administrador && !body.ItemCode.includes('TEMPORAL')" mat-raised-button color="primary"
    style="background: #00bc8c;margin-right: 10px; color:black" (click)="crearMaestroArticulo()">Crear o Actualizar en
    SAP</button>

  <button *ngIf="this.administrador && body.ItemCode.includes('TEMPORAL')" mat-raised-button color="primary"
    style="background: #d33f3f;margin-right: 10px; color:rgb(255, 255, 255)" (click)="avisoCambioItemCode()">Crear o
    Actualizar en SAP</button>

</div>
