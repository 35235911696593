  <!-- Main Sidebar Container -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/duyplus/fontawesome-pro/css/all.min.css">


  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link">
      <img src="{{this.general.RUTA_IMAGEN_LOGO}}" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">{{this.general.TITULO_MENU}}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="{{this.general.RUTA_IMAGEN_USER_NOT_FOUND}}" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a class="d-block">{{this.login.NOMBRE}}</a>
        </div>
      </div>

      <!-- SidebarSearch Form -->
      <div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Buscar" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item"  *ngFor="let item of menuAll">
            <a href="#" class="nav-link"  routerLinkActive="active" >
              <i class="nav-icon {{item.ICONO}}"></i>
              <p>
                {{item.MENUs}}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>

            <ul class="nav nav-treeview">

              <li class="nav-item"  *ngFor="let hijo of item.HIJOS">

                <a (click)="abrirInformeVenta()" class="nav-link"  *ngIf="hijo.MENUs == 'Descargar Informe venta'" style="cursor: pointer;">
                  <i class="{{hijo.ICONO}} nav-icon"></i>
                  <p>{{hijo.MENUs}}</p>
                </a>

                <a routerLink="{{hijo.URL}}" routerLinkActive="active" class="nav-link"  *ngIf="!hijo.URL.includes(currentURL) && hijo.MENUs != 'Descargar Informe venta'" >
                  <i class="{{hijo.ICONO}} nav-icon"></i>
                  <p>{{hijo.MENUs}}</p>
                </a>

                <a  routerLink="{{hijo.URL}}"  routerLinkActive="active" class="nav-link active" *ngIf="hijo.URL.includes(currentURL) && hijo.MENUs != 'Descargar Informe venta'">
                  <i class="{{hijo.ICONO}} nav-icon"></i>
                  <p>{{hijo.MENUs}}</p>
                </a>
              </li>

            </ul>
          </li>


        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  <!--End Main Sidebar Container-->
