import { Component, OnInit } from '@angular/core';
//import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { SapService } from 'src/app/api/sap.service';
import { WMSService } from 'src/app/api/wms.service';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-imprimible',
  templateUrl: './imprimible.component.html',
  styleUrls: ['./imprimible.component.css']
})
export class ImprimibleComponent implements OnInit {

  displayedColumns: string[] = ['estadolinea', 'codesap', 'descripcion', 'stock', 'stockbodega', 'bodega', 'cantsolicitada', 'cantpendiente', 'cantpicking'];
  dataSource:any;


  constructor(
      private _apiSap: SapService,
      private _apiWMS: WMSService,
      //public activeModal: NgbActiveModal,
      private _api: IntranetoldService,
      private _activeRoute: ActivatedRoute,
    ) { }

  data:any;
  datasap:any;
  DataPicking:any;
  Detalle:any;
  imprimibles:any;
  usuarios: any = [];

  fechaactual:any;
  DocEntry:any;
  ObjType:any;
  ClienteProveedor:any

  async ngOnInit(): Promise<void> {
    this.getUsuarios();

    await this.cargaInicial();
  }
  async cargaInicial(){

    this.DocEntry = this._activeRoute.snapshot.paramMap.get("DocEntry");
    this.ObjType = this._activeRoute.snapshot.paramMap.get("ObjType");
    console.log(this.DocEntry, this.ObjType);
    if (this.DocEntry) {
      this.data = {};
      this.data.DocEntry = this.DocEntry;
      this.data.ObjType = this.ObjType;
    }

    if (this.ObjType == 17) {
      this.ClienteProveedor = "Cliente"
    }else if(this.ObjType == 22){
      this.ClienteProveedor = "Proveedor"
    }


    console.log(this.data);
  
    this.fechaactual = new Date();
  
    var json:any = {
  
           "DocEntry": this.data.DocEntry,
           "ObjType": this.data.ObjType
  
      }
      console.log(json)
      this.datasap = await this._apiSap.ObtenerDocumentoMarketingLITE(json);
      this.DataPicking = await this._apiWMS.AbrirPicking(json)
      console.log("DATAPICKING",this.DataPicking);
      this.Detalle = this.DataPicking.resultado.DETALLLE;
      console.log("DETALLE",this.Detalle)
      console.log(this.datasap);
  
  
  
      this.Detalle.forEach(detalle => {
        this.datasap.resultado.Detalles.forEach(datasap => {
          if (detalle.ItemCode == datasap.ItemCode && detalle.LineNum == datasap.LineNum) {
            datasap.pickeado = detalle.Quantity;
          }
        });
      });
  
      this.datasap.resultado.responsable = this.getNombreResponsable(this.DataPicking.resultado.RESPONSABLE);
  
  
  
      this.dataSource = this.datasap.resultado.Detalles;
      this.dataSource.forEach(element => {
        if (element.LineStatus=="O") {
          element.LineStatus="Abierto"
        }else{
          element.LineStatus="Cerrado"
        }
      });
  
  
      this.imprimibles = [];
  
      await this.dataSource.forEach(element => {
        if (element.LineStatus == "Abierto") {
          this.imprimibles.push(element);
        }
      });
      
  
      console.log("IMPRIMIBLES",this.imprimibles)
  
  
      this.datasap.resultado.Address2.replace(/[\r\n\t]/g," ");
  

      setTimeout(this.printPage, 500);
      
  
    }

  printPage() {


    window.print();


  }

  async getUsuarios() {
    var encargados: any = await this._apiWMS.GetGrupo("Responsable");
    //console.log(encargados);
    // if (encargados.estado) {
    this.usuarios = encargados.objeto;
    // }
  }

  getNombreResponsable(rut){
    let nombre:any;
    this.usuarios.forEach(element => {
      if (element.RUT==rut) {
        nombre = element.NOMBRE
      }
    });
    return nombre;
  }
  





  cerrar() {
    //this.activeModal.close();
  }




  

}
