import { Component, OnInit } from '@angular/core';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { IntranetoldService } from "./../../../api/intranetold.service"


@Component({
  selector: 'app-ngx-easy-table',
  templateUrl: './ngx-easy-table.component.html',
  styleUrls: ['./ngx-easy-table.component.sass']
})
export class NgxEasyTableComponent implements OnInit {

  constructor(
    private _api:IntranetoldService
  ) { }

  public configuration: Config;
  public columns: Columns[];

  public data = [{
    phone: '+1 (934) 551-2224',
    age: 20,
    address: { street: 'North street', number: 12 },
    company: 'ZILLANET',
    name: 'Valentine Webb',
    isActive: false,
  }, {
    phone: '+1 (948) 460-3627',
    age: 31,
    address: { street: 'South street', number: 12 },
    company: 'KNOWLYSIS',
    name: 'Heidi Duncan',
    isActive: true,
  }];
  ngOnInit(): void {
    this.configuration = { ...DefaultConfig
    , searchEnabled: true };

    this.cargadata();
    this.columns = [
      { key: 'RUT', title: 'RUT' },
      { key: 'NOMBRE', title: 'Nombre' },
      { key: 'CARGO', title: 'Cargo' },
      //{ key: 'IMAGE_LITTLE', title: 'imagen' },
      { key: 'PERFIL', title: 'PERFIL' },
    ];
  }

  async cargadata()
  {
    console.log(this.data);
    var data:any = await this._api.getAllLogin();

    this.data = data.objeto;
    console.log(this.data);
  }

}
