import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { SapService } from 'src/app/api/sap.service';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { variableGlobal } from 'src/app/global.enum';
import { BuscarCardCodeComponent } from '../../buscar-card-code/buscar-card-code.component';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { number } from '@amcharts/amcharts4/core';
import { type } from 'os';
import { MongoService } from 'src/app/api/mongo.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crear-maestro-articulo',
  templateUrl: './crear-maestro-articulo.component.html',
  styleUrls: ['./crear-maestro-articulo.component.css']
})
export class CrearMaestroArticuloComponent implements OnInit {

  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  public configuration: Config;
  public columns: Columns[];

  cargando: any;
  data: any;
  crear: any;
  body: any;
  VinculoPrecioArticulo: any;
  grupoArticulos: any;
  listaPrecios: any;
  listaPreciosOriginal: any;
  listaFabricantes: any;
  formasEnvio: any;
  grupoAduanas: any;
  checkChangeValue: any;
  listapreciosnueva: any;
  listaseleccionada: any;
  obtenerCardCode: any;
  dataTablaBodegas: any;
  ArticuloG: any;
  FamiliaCategorias: any;
  Familia: any;
  SubFamilia: any;
  unidadMedida: any[] = ["UNIDAD", "CAJA", "METRO"];

  ocultar: boolean = false;
  BLongitudUM: string;
  BAnchoUM: string;
  BAlturaUM: string;
  BPesoUM: string;
  SLongitudUM: string;
  SAnchoUM: string;
  SAlturaUM: string;
  SPesoUM: string;
  id: string = "";
  administrador: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService,
    private _sap: SapService,
    private _apiMongo: MongoService,
    private _dbService: NgxIndexedDBService,
    private modalService: NgbModal,
    private router: Router

  ) { }


  async ngOnInit(): Promise<void> {

    this._dbService.getByID("general", "login").subscribe((res: any) => {
      console.log(res)

        this.administrador = res.datos.login.OITM_SAP;
        console.log("|" + res.datos.login.OITM_SAP + "|");

        /*         const administradores = ["17777856-7", "17859658-6", "14505286-6", "16280290-9", "13087278-6", "18966696-9"];
                var existeAdministrador = administradores.filter(a => a.toUpperCase() === rut).length > 0;
                console.log(existeAdministrador)
                this.administrador = existeAdministrador;//false;
                const perfiles = ["Administrador"];
                const perfil = res.datos.perfil.NOMBRE.toUpperCase();
                console.log("|" + perfil + "|");
                 var existeAdministrador = perfiles.filter(a => a.toUpperCase() === perfil).length > 0;
                 console.log("|" + existeAdministrador + "|");
                 this.administrador = existeAdministrador;//false;
                 */

    });

    //console.log("hha", this.unidadMedida)
    await this.cargaInicial();
    await this.cargaTablaBodega();

    console.log("VER BODY", this.body)


  }

  buscarCardCode() {


    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(BuscarCardCodeComponent, ngbModalOptions);
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {
      if (data) {
        this.body.CardCode = data
      }
    }, (reason) => {
      //this.editar(item);
    });

  }

  async cargaInicial() {
    this.ArticuloG = "0";
    this.cargando = true;

    this.listapreciosnueva = []
    //console.log(this.crear,"--------",this.data)
    if (this.crear == false) {
      if (this.data.resultado) {
        this.body = this.data.resultado;
        this.body.PriceList = 1;
      } else {
        this.id = this.data._id;
        this.body = this.data.item;
        this.body.PriceList = 1;
        //console.log("  this.body---------",  this.body)
      }

    }
    if (this.crear == true) {

      var articulos: any = await this._apiMongo.consultaArtivuloTemporal();
      console.log(articulos)
      var jsonArticulos: any = articulos.objeto;
      let ultimoNumero = 0;

      for (const itemJson of jsonArticulos) {
        const itemCode = itemJson.item.ItemCode;
        const match = itemCode.match(/TEMPORAL-(\d+)/);

        if (match) {
          const numero = parseInt(match[1], 10);
          if (numero > ultimoNumero) {
            ultimoNumero = numero;
          }
        }
      }

      const numeroFinal = ultimoNumero + 1;
      const nuevoNombre = await `TEMPORAL-${numeroFinal.toString().padStart(3, '0')}`;

      console.log(nuevoNombre);



      if (nuevoNombre != "") {
        this.body = {
          IWeight1: null,
          Series: 3,
          "ItemCode": nuevoNombre,
          ItemName: null,
          FrgnName: null,
          ItemType: "I",
          ItmsGrpCod: null,
          ItmsGrpNam: null,
          UgpEntry: -1,
          ListNum: null,

          CodeBars: null,
          FirmCode: null,
          ShipType: null,
          ManBtchNum: false,
          ManSerNum: false,
          MngMethod: null,
          CardCode: null,
          SuppCatNum: null,
          BuyUnitMsr: null,
          NumInBuy: 1,
          PurPackMsr: null,
          PurPackUn: null,
          CstGrpCode: null,
          TotalTax: null,
          SalUnitMsr: null,
          NumInSale: 1,
          SalPackMsr: null,
          SalPackUn: null,
          PriceList: 1,

          QRCodeSrc: null,
          GLMethod: "C",
          InvntryUom: null,
          EvalSystem: "A",
          AvgPrice: null,
          /*         ReorderQty: 0,
                  MinLevel: 0,
                  MaxLevel: 0, */
          InvntItem: true,
          SellItem: true,
          PrchseItem: true,
          WTLiable: true,
          VATLiable: true,
          IndirctTax: false,
          NoDiscount: false,
          ByWh: true,
          Price: 0,

          BLength1: null,
          BLen1Unit: null,
          BWidth1: null,
          BWdth1Unit: null,
          BHeight1: null,
          BHght1Unit: null,
          BVolume: null,
          BVolUnit: 2,
          BWeight1: null,
          BWght1Unit: null,

          PurFactor1: 1,
          PurFactor2: 1,
          PurFactor3: 1,
          PurFactor4: 1,

          SLength1: null,
          SLen1Unit: null,
          SWidth1: null,
          SWdth1Unit: null,
          SHeight1: null,
          SHght1Unit: null,
          SVolume: null,
          SVolUnit: 2,
          SWeight1: null,
          SWght1Unit: null,

          SalFactor1: 1,
          SalFactor2: 1,
          SalFactor3: 1,
          SalFactor4: 1,
          userFields: [

            {
              "Field": "U_IND_CATEGORIA2",
              "FieldType": null,
              "Value": "",
              "TypeHTML": null,
              "MaxLength": null,
              "Step": null,
              "Description": null
            },
            {
              "Field": "U_IND_CATEGORIA3",
              "FieldType": null,
              "Value": "",
              "TypeHTML": null,
              "MaxLength": null,
              "Step": null,
              "Description": null
            },
            {
              "Field": "U_IND_SWW",
              "FieldType": null,
              "Value": "",
              "TypeHTML": null,
              "MaxLength": null,
              "Step": null,
              "Description": null
            }

          ],
          listaPrecios: [],

        };
      }

    }

    let queryListaPrecios = await this.obtieneQueryMongo("articulosListaPrecio");

    this.listaPrecios = await this._api.GetNotFileQuery(queryListaPrecios);
    this.listaPrecios = this.listaPrecios.objeto;

    console.log(this.listaPrecios)

    let queryVinculoPrecioArticulo = await this.obtieneQueryMongo("VinculoPrecioArticulo");
    let queryGrupoArticulos = await this.obtieneQueryMongo("articulosGrupo");
    let queryListaFabricantes = await this.obtieneQueryMongo("articulosFabricantes")
    let queryformasEnvio = await this.obtieneQueryMongo('formaEnvio')
    let queryGrupoAduanas = await this.obtieneQueryMongo('GrupoAduanas')
    let queryObtenerCardCode = await this.obtieneQueryMongo('OCRD')
    let queryFamiliaCategorias = await this.obtieneQueryMongo('FamiliaCategorias')
    //FamiliaCategorias

    this.FamiliaCategorias = await this._api.GetNotFileQuery(queryFamiliaCategorias);
    this.FamiliaCategorias = this.FamiliaCategorias.objeto;

    this.grupoArticulos = await this._api.GetNotFileQuery(queryGrupoArticulos);
    this.grupoArticulos = this.grupoArticulos.objeto;

    this.listaFabricantes = await this._api.GetNotFileQuery(queryListaFabricantes);
    this.listaFabricantes = this.listaFabricantes.objeto;

    this.formasEnvio = await this._api.GetNotFileQuery(queryformasEnvio);
    this.formasEnvio = this.formasEnvio.objeto;

    this.grupoAduanas = await this._api.GetNotFileQuery(queryGrupoAduanas);
    this.grupoAduanas = this.grupoAduanas.objeto;

    this.VinculoPrecioArticulo = await this._api.GetNotFileQuery(queryVinculoPrecioArticulo);
    this.VinculoPrecioArticulo = this.VinculoPrecioArticulo.objeto;

    this.obtenerCardCode = await this._api.GetNotFileQuery(queryObtenerCardCode);
    this.obtenerCardCode = this.obtenerCardCode.objeto;

    console.log("XXXXXXXXXX", this.FamiliaCategorias)
    this.familia(this.body.ItmsGrpCod, this.body.userFields[0].Value, this.body.userFields[1].Value);

    if (this.crear) {

      this.listaPrecios.forEach(element => {
        let modelo = {
          "ListName": element.ListName,
          "PriceList": element.ListNum,
          "Currency": element.Currency,
          "Price": element.Price
        }

        this.listapreciosnueva.push(modelo);
      });
    }

    console.log("QUERY POR VER", this.listapreciosnueva);


    if (this.crear) {

      this.listapreciosnueva.forEach(element => {
        this.body.listaPrecios.push(element)
      });

    } else {
      this.listaPrecios.forEach(element => {
        this.body.listaPrecios.forEach(item => {
          if (item.PriceList == element.PriceList) {
            item.ListName = element.ListName;
          }
        });
      });
    }
    this.ArticuloG = "0";

    this.cargando = false;
    //console.log("body--", this.body)

    if (this.body.ManBtchNum == false && this.body.ManSerNum == false) {
      this.ArticuloG = "0"
    }
    else if (this.body.ManSerNum == true) {
      this.ArticuloG = "1"
    }
    else if (this.body.ManBtchNum == true) {
      this.ArticuloG = "2"
    }
    if (this.body.BLen1Unit == 2) {
      this.BLongitudUM = " | cm";
    }
    if (this.body.BWdth1Unit == 2) {
      this.BAnchoUM = " | cm";
    }
    if (this.body.BHght1Unit == 2) {
      this.BAlturaUM = " | cm";
    }
    if (this.body.BWght1Unit == 3) {
      this.BPesoUM = " | Kg";
    }
    if (this.body.SLen1Unit == 2) {
      this.SLongitudUM = " | cm";
    }
    if (this.body.SWdth1Unit == 2) {
      this.SAnchoUM = " | cm";
    }
    if (this.body.SHght1Unit == 2) {
      this.SAlturaUM = " | cm";
    }
    if (this.body.SWght1Unit == 3) {
      this.SPesoUM = " | Kg";
    }


    if (this.body.BLength1 === 0) {
      this.body.BLength1 = null;
      this.body.BLength2 = null;
      this.body.BLen1Unit = null;
    }
    if (this.body.BWidth1 === 0) {
      this.body.BWidth1 = null;
      this.body.BWidth2 = null;
      this.body.BWdth1Unit = null
    }
    if (this.body.BHeight1 === 0) {
      this.body.BHeight1 = null;
      this.body.BHeight2 = null;
      this.body.BHght1Unit = null;
    }
    if (this.body.BVolume === 0) {
      this.body.BVolume = null;
    }
    if (this.body.BWeight1 === 0) {
      this.body.BWeight1 = null;
      this.body.BWeight2 = null;
      this.body.BWght1Unit = null;
    }
    if (this.body.SLength1 === 0) {
      this.body.SLength1 = null;
      this.body.SLength2 = null;
      this.body.SLen1Unit = null;
    }
    if (this.body.SWidth1 === 0) {
      this.body.SWidth1 = null;
      this.body.SWidth2 = null;
      this.body.SWdth1Unit = null;
    }
    if (this.body.SHeight1 === 0) {
      this.body.SHeight1 = null;
      this.body.SHeight2 = null;
      this.body.SHght1Unit = null;
    }
    if (this.body.SVolume === 0) {
      this.body.SVolume = null;
    }
    if (this.body.SWeight1 === 0) {
      this.body.SWeight1 = null;
      this.body.SWeight2 = null;
      this.body.SWght1Unit = null;
    }


  }

  actualizarPrecioUnidad(event) {


    this.body.listaPrecios.forEach(element => {
      if (element.PriceList == event.target.value) {
        this.listaseleccionada = element;
      }

    });

    console.log(this.listaseleccionada)

  }

  setValue(propiedad, event: MatCheckboxChange) {
    this.body[propiedad] = event.checked ? true : false;

    console.log(this.body)
  }

  SlcLoteSerie(event) {

    if (event.target.value == "0") {
      this.body.ManBtchNum = false;
      this.body.ManSerNum = false;
    }
    else if (event.target.value == "1") {
      this.body.ManBtchNum = false;
      this.body.ManSerNum = true;
    }
    else if (event.target.value == "2") {
      this.body.ManBtchNum = true;
      this.body.ManSerNum = false;
    }
  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach((element) => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  cerrar() {
    this.activeModal.close();
  }

  cargaTablaBodega() {

    if (!this.crear) {
    }

    this.columns = [
      { key: 'ItemCode', title: 'Bodega' },
      { key: 'StockValue', title: 'Stock' }
    ];

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };
  }


  calculaVolumen() {

    if (this.body.BLength1 != null && this.body.BWidth1 != null && this.body.BHeight1 != null) {
      this.body.BVolume = this.body.BLength1 * this.body.BWidth1 * this.body.BHeight1;
    }
    if (this.body.BLength1 != null) {
      this.body.BLen1Unit = 2;
    }
    if (this.body.BWidth1 != null) {
      this.body.BWdth1Unit = 2;
    }
    if (this.body.BHeight1 != null) {
      this.body.BHght1Unit = 2;
    }

    if (this.body.BLen1Unit == 2) {
      this.BLongitudUM = " | cm";
    }
    if (this.body.BWdth1Unit == 2) {
      this.BAnchoUM = " | cm";
    }
    if (this.body.BHght1Unit == 2) {
      this.BAlturaUM = " | cm";
    }


    if (this.body.SLength1 != null && this.body.SWidth1 != null && this.body.SHeight1 != null) {
      this.body.SVolume = this.body.SLength1 * this.body.SWidth1 * this.body.SHeight1;
    }

    if (this.body.SLength1 != null) {
      this.body.SLen1Unit = 2;
    }
    if (this.body.SWidth1 != null) {
      this.body.SWdth1Unit = 2;
    }
    if (this.body.SHeight1 != null) {
      this.body.SHght1Unit = 2;
    }



    if (this.body.SLen1Unit == 2) {
      this.SLongitudUM = " | cm";
    }
    if (this.body.SWdth1Unit == 2) {
      this.SAnchoUM = " | cm";
    }
    if (this.body.SHght1Unit == 2) {
      this.SAlturaUM = " | cm";
    }


  }
  kgfuncion() {
    if (this.body.BWeight1 != null) {
      this.body.BWght1Unit = 3
      this.BPesoUM = " | Kg";
    }
    if (this.body.SWeight1 != null) {
      this.body.SWght1Unit = 3
      this.SPesoUM = " | Kg";
    }

  }

  copiarVenta() {
    Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      icon: 'info',
      title: '¡Ten presente!',
      text: 'La unidad y los artículos por unidad pueden variar entre compra y venta.'
    });
    this.body.SLength1 = this.body.BLength1;
    this.body.SWidth1 = this.body.BWidth1;
    this.body.SHeight1 = this.body.BHeight1;
    this.body.SVolume = this.body.BVolume;
    this.body.SWeight1 = this.body.BWeight1;

    this.body.SalUnitMsr = this.body.BuyUnitMsr;
    this.body.InvntryUom = this.body.SalUnitMsr;

    this.body.NumInSale = this.body.NumInBuy;
    this.calculaVolumen()
  }

  familiaCategoria2() {

    console.log("this.body.ItmsGrpCod", this.body.ItmsGrpCod);
    this.Familia = [...new Set(this.FamiliaCategorias.filter(item => item.U_IND_CodPadre === this.body.ItmsGrpCod).map(item => item.U_IND_Categoria2))];
    console.log("familia", this.Familia);

  }

  async familiaCategoria3(item: any) {
    var valor = item.target.value;
    console.log('fff valor', valor)

    this.SubFamilia = [...new Set(await this.FamiliaCategorias.filter(item => item.U_IND_Categoria2 == valor).map(item => item.U_IND_Categoria3))];

    console.log("subFamilia", this.SubFamilia);
  }

  async familia(item: string, itemF: string, itemSub: string) {

    //console.log(item, itemF, itemSub);
    this.Familia = [...new Set(this.FamiliaCategorias.filter(item => item.U_IND_CodPadre == this.body.ItmsGrpCod).map(item => item.U_IND_Categoria2))];
    console.log("familia", this.Familia);
    this.SubFamilia = [...new Set(await this.FamiliaCategorias.filter(item => item.U_IND_Categoria2 == itemF).map(item => item.U_IND_Categoria3))];
    console.log("subfamilia", this.SubFamilia);
  }


  async crearMaestroArticulo() {
    this.cargando = true;

    var U_IND_CATEGORIA2 = "";
    var U_IND_CATEGORIA3 = "";
    var U_IND_SWW = "";
    var ItmsGrpNam = "";
    console.log("this.grupoArticulos", this.grupoArticulos)
    for (var item of this.grupoArticulos) {
      if (item.ItmsGrpCod == this.body.ItmsGrpCod) {
        console.log("XXXXXXXXX", item)
        ItmsGrpNam = item.ItmsGrpNam;
      }

    }
    for (var item of this.body.userFields) {
      if (item.Field == "U_IND_CATEGORIA2") {
        U_IND_CATEGORIA2 = item.Value;
      }
      if (item.Field == "U_IND_CATEGORIA3") {
        U_IND_CATEGORIA3 = item.Value;
      }
      if (item.Field == "U_IND_SWW") {
        U_IND_SWW = item.Value;
      }
    }
    this.body.U_IND_CATEGORIA2 = U_IND_CATEGORIA2;
    this.body.U_IND_CATEGORIA3 = U_IND_CATEGORIA3;
    this.body.U_IND_SWW = U_IND_SWW;
    this.body.ItmsGrpNam = ItmsGrpNam;

    if (this.body.BWght1Unit != null) {
      this.body.BWght1Unit = 3
    }
    if (this.body.SWght1Unit != null) {
      this.body.SWght1Unit = 3
    }



    let alerta = "";

    /*     if (!this.body.Series) {
          alerta = alerta + " " + "Número de Artículo,"
        }
        else  */

    if (this.ArticuloG === "") {
      alerta = alerta + " " + 'Selecciona una opción válida para "Artículo gestionado por: Ninguno, Serie o Lote".'
    }
    if (!this.body.ItemCode) {
      alerta = alerta + " " + "Número de Artículo,"
    }
    else if (!this.body.ItemName) {
      alerta = alerta + " " + "Descripción,"
    }
    else if (!this.body.ItemType) {
      alerta = alerta + " " + "Clase de artículo,"
    }
    else if (!this.body.ItmsGrpCod) {
      alerta = alerta + " " + "Grupo de artículo,"
    }
    /*     else if (!this.body.PriceList) {
          alerta = alerta + " " + "Lista de precios,"
        } */


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA2") {
        if (!element.Value) {
          alerta = alerta + " " + "Familia,"
        }
      }
    });



    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA3") {
        if (!element.Value) {
          alerta = alerta + " " + "Sub-Familia,"
        }
      }
    });


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_SWW") {
        if (!element.Value) {
          alerta = alerta + " " + "Código Antiguo,"
        }
      }
    });


    if (!this.body.BuyUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medidad de compras,"
    }
    else if (!this.body.NumInBuy) {
      alerta = alerta + " " + "Artículos por unidad comprar,"
    }
    else if (!this.body.SalUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medida,"
    }
    else if (!this.body.NumInSale) {
      alerta = alerta + " " + "Artículos por unidad de ventas,"
    }
    /*     else if (!this.body.SalPackUn) {
          alerta = alerta + " " + "Nombre de unidad de medida de em,"
        }
        else if (!this.body.SVolume) {
          alerta = alerta + " " + "Volumen,"
        }
        else if (!this.body.SVolUnit) {
          alerta = alerta + " " + "Volumen,"
        } */
    else if (!this.body.GLMethod) {
      alerta = alerta + " " + "Fijar ctas de mayor según,"
    }
    else if (!this.body.InvntryUom) {
      alerta = alerta + " " + "Nombre unid. de medida,"
    }

    else {

      console.log("VER BODY ANTES DE CREAR/EDITAR", this.body)
      var mensaje: any = await this._sap.CrearEditarMT(this.body)
      console.log(mensaje)
      if (mensaje.estado) {
        if (this.crear) {
          await this._apiMongo.eliminaArtivuloTemporal(this.id)
          Swal.fire({
            icon: 'success',
            title: 'Articulo creado con exito.!',
          })
          this.cargando = false;
          this.activeModal.close();
          location.reload()
          this.router.navigate(['/maestro-articulo']);
        } else {
          await this._apiMongo.eliminaArtivuloTemporal(this.id)
          Swal.fire({
            icon: 'success',
            title: mensaje.mensaje,
          })
          this.cargando = false;
          this.activeModal.close();
          location.reload()
          this.router.navigate(['/maestro-articulo']);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          text: 'Error al crear el articulo: ' + mensaje.mensaje,
        })
        this.cargando = false;
      }
      this.cargando = false;
    }

    if (alerta) {

      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'Los campos:' + alerta + 'son requeridos.',
      })
      this.cargando = false;
    }




  }

  async crearArticuloTemporal() {
    this.cargando = true;
    var U_IND_CATEGORIA2 = "";
    var U_IND_CATEGORIA3 = "";
    var U_IND_SWW = "";
    var ItmsGrpNam = "";
    console.log("this.grupoArticulos", this.grupoArticulos)
    for (var item of this.grupoArticulos) {
      if (item.ItmsGrpCod == this.body.ItmsGrpCod) {
        console.log("ItmsGrp", item)
        ItmsGrpNam = item.ItmsGrpNam;
      }

    }
    for (var item of this.body.userFields) {
      if (item.Field == "U_IND_CATEGORIA2") {
        U_IND_CATEGORIA2 = item.Value;
      }
      if (item.Field == "U_IND_CATEGORIA3") {
        U_IND_CATEGORIA3 = item.Value;
      }
      if (item.Field == "U_IND_SWW") {
        U_IND_SWW = item.Value;
      }
    }
    this.body.U_IND_CATEGORIA2 = U_IND_CATEGORIA2;
    this.body.U_IND_CATEGORIA3 = U_IND_CATEGORIA3;
    this.body.U_IND_SWW = U_IND_SWW;
    this.body.ItmsGrpNam = ItmsGrpNam;

    if (this.body.BWght1Unit != null) {
      this.body.BWght1Unit = 3
    }
    if (this.body.SWght1Unit != null) {
      this.body.SWght1Unit = 3
    }

    let alerta = "";

    /*     if (!this.body.Series) {
          alerta = alerta + " " + "Número de Artículo,"
        }
        else  */
    if (this.ArticuloG === "") {
      alerta = alerta + " " + 'Selecciona una opción válida para "Artículo gestionado por: Ninguno, Serie o Lote".'
    }
    if (!this.body.ItemCode) {
      alerta = alerta + " " + "Número de Artículo,"
    }
    else if (!this.body.ItemName) {
      alerta = alerta + " " + "Descripción,"
    }
    else if (!this.body.ItemType) {
      alerta = alerta + " " + "Clase de artículo,"
    }
    else if (!this.body.ItmsGrpCod) {
      alerta = alerta + " " + "Grupo de artículo,"
    }
    /*     else if (!this.body.PriceList) {
          alerta = alerta + " " + "Lista de precios,"
        } */


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA2") {
        if (!element.Value) {
          alerta = alerta + " " + "Familia,"
        }
      }
    });



    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA3") {
        if (!element.Value) {
          alerta = alerta + " " + "Sub-Familia,"
        }
      }
    });


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_SWW") {
        if (!element.Value) {
          alerta = alerta + " " + "Código Antiguo,"
        }
      }
    });


    if (!this.body.BuyUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medidad de compras,"
    }
    else if (!this.body.NumInBuy) {
      alerta = alerta + " " + "Artículos por unidad comprar,"
    }
    else if (!this.body.SalUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medida,"
    }
    else if (!this.body.NumInSale) {
      alerta = alerta + " " + "Artículos por unidad de ventas,"
    }
    /*     else if (!this.body.SalPackUn) {
          alerta = alerta + " " + "Nombre de unidad de medida de em,"
        }
        else if (!this.body.SVolume) {
          alerta = alerta + " " + "Volumen,"
        }
        else if (!this.body.SVolUnit) {
          alerta = alerta + " " + "Volumen,"
        } */
    else if (!this.body.GLMethod) {
      alerta = alerta + " " + "Fijar ctas de mayor según,"
    }
    else if (!this.body.InvntryUom) {
      alerta = alerta + " " + "Nombre unid. de medida,"
    }

    else {

      console.log("VER BODY ANTES DE CREAR/EDITAR", this.body)
      console.log("ver id", this.id)
      if (this.id == "") {
        var mensaje: any = await this._apiMongo.creaEditaArticuloTemporal(this.body, true, this.id)
      } else {
        var mensaje: any = await this._apiMongo.creaEditaArticuloTemporal(this.body, false, this.id)
      }

      console.log(mensaje)
      if (mensaje.estado) {
        if (this.crear) {
          Swal.fire({
            icon: 'success',
            title: 'Articulo creado con exito.!',
          })
          this.cargando = false;
          this.activeModal.close();
          location.reload()
          this.router.navigate(['/articuloTemporal']);
        } else {
          Swal.fire({
            icon: 'success',
            title: mensaje.mensaje,
          })
          this.cargando = false;
          this.activeModal.close();
          location.reload()
          this.router.navigate(['/articuloTemporal']);
        }

      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          text: 'Error al crear el articulo: ' + mensaje.mensaje,
        })
        this.cargando = false;
      }
      this.cargando = false;
    }

    if (alerta) {

      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'Los campos:' + alerta + 'son requeridos.',
      })
      this.cargando = false;
    }
  }

  verBody() {
    console.log("VER BODY", this.body)
  }


  avisoCambioItemCode() {
    Swal.fire({
      icon: 'warning',
      title: '¡Es Número de Artículo no puede ser temporal!',
      text: 'Cambie el Número de Artículo',
    })
  }
}
