<div class="card">


    <div class="card-header">
        <h3 class="card-title">{{titulo}}</h3>
    </div>
    <div class="card-body">
        <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-sm-12">

                    <ngx-table #table [configuration]="configuration" [data]="dataFiltrada" [columns]="columns"></ngx-table>

                    <ng-template #imprimible let-row let-rowIndex="rowIndex">

                    </ng-template>

                    <ng-template #frmDocNum let-row let-rowIndex="rowIndex">

                        <button style="background: #96D23A;border: 1px solid #96D23A;" class="btn btn-primary" (click)="abrirPicking(row)" *ngIf="tipo  == 'preparar'">{{row.DocNum }} </button>
                        <button style="background: #96D23A;border: 1px solid #96D23A;" class="btn btn-primary" (click)="OpenModalV2(row)" *ngIf="tipo  == 'generar'">{{row.DocNum}} </button>
                        <button style="background: #96D23A;border: 1px solid #96D23A;" class="btn btn-primary" (click)="OpenDoc(row)" *ngIf="tipo  == 'asignar'">{{row.DocNum}}</button>
                        <button style="background: #96D23A;border: 1px solid #96D23A;" class="btn btn-primary" (click)="OpenDoc(row)" *ngIf="tipo  == 'generardoc'">{{row.DocNum}}</button>
                        <button style="background: #96D23A;border: 1px solid #96D23A;margin-left: 5px;" class="btn btn-danger" (click)="imprimir(row)" *ngIf="tipo  == 'asignar'"><i class="fa-duotone fa-print" style="font-size: 20px;"></i></button>
                        <button style="background: #96D23A;border: 1px solid #96D23A;margin-left: 5px;" class="btn btn-danger" (click)="imprimir(row)" *ngIf="tipo  == 'preparar'"><i class="fa-duotone fa-print" style="font-size: 20px;"></i></button>

                    </ng-template>


                    <ng-template #frmDueDate let-row let-rowIndex="rowIndex">
                        {{row.DocDueDate | date: 'dd/MM/yyyy'}}
                    </ng-template>
                    <ng-template #frmTotal let-row let-rowIndex="rowIndex">
                        <div *ngIf="row.DocCur  != 'CLP'">{{row.DocTotal | currency: row.DocCur}}</div>
                        <div *ngIf="row.DocCur  == 'CLP'">{{row.DocTotal | iNDCLP}}</div>
                    </ng-template>
                    <ng-template #frmAccion let-row let-rowIndex="rowIndex">
                        <select style="width: 180px;" class="form-control" (change)="UpdateResponsable(row,$event);" [(ngModel)]="row.U_IND_RESPONSABLE_PICKING">
                            <option *ngIf="!row.U_IND_RESPONSABLE_PICKING" value="" selected disabled>Seleccione Responsable</option>
                            <option *ngIf="row.U_IND_RESPONSABLE_PICKING" value="">Sin Responsable</option>
                            <option *ngFor="let row of usuarios" value={{row.RUT}}>{{row.NOMBRE}}</option>
                        </select>

                        <p  *ngIf="tipo  == 'preparar'"> {{row.U_IND_ENCARGADO}} </p>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>
