import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { IntranetoldService } from './../../../api/intranetold.service';
import { VentaService } from './../../../api/venta.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import Swal from 'sweetalert2'
import { variableGlobal } from 'src/app/global.enum';
import { VentaLayaultModelComponent } from "../../layout/venta/venta-layault-model";
import { envioCorreoSeguimientoaComponent } from "./envio-correo"
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.sass']
})
export class SeguimientoComponent implements OnInit {

  constructor(
    private _activeRoute: ActivatedRoute,
    private _api: IntranetoldService,
    private _apiVenta: VentaService,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  tipoSeguimiento = "";
  titulo = "Seguimiento";
  public configuration: Config;
  public columns: Columns[];
  columnsCopy: Columns[] = [];
  checked = new Set([]);
  data = [];
  vendedores = [];

  @ViewChild('frmNumeroDocumento', { static: true }) frmNumeroDocumento: TemplateRef<any>;
  @ViewChild('frmEstado', { static: true }) frmEstado: TemplateRef<any>;
  @ViewChild('frmVendedor', { static: true }) frmVendedor: TemplateRef<any>;
  @ViewChild('frmFechaDocumento', { static: true }) frmFechaDocumento: TemplateRef<any>;
  @ViewChild('frmFechaEntrega', { static: true }) frmFechaEntrega: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;

  ngOnInit(): void {

    this.cargaDatosIniciales();

  }

  nombrePrincipal = "";
  ObjTypeDoc = "";
  obtieneTitulo() {
    this.tipoSeguimiento = this._activeRoute.snapshot.paramMap.get("tipoSeguimiento");
    if (this.tipoSeguimiento == "COT") {
      this.nombrePrincipal = "Cotización";
      this.titulo = "Seguimiento de cotización";
      this.ObjTypeDoc = "23";
    }
    else if (this.tipoSeguimiento == "NV") {
      this.nombrePrincipal = "Nota de venta";
      this.titulo = "Seguimiento de Nota de venta";
      this.ObjTypeDoc = "17";
    }
    else if (this.tipoSeguimiento == "OFECOM") { //quitar condiciones comerciales
      this.nombrePrincipal = "Pedido a fabrica";
      this.titulo = "Seguimiento pedido interno";
      this.ObjTypeDoc = "540000006";
    }
    else if (this.tipoSeguimiento == "COMPRA") { //quitar condiciones comerciales
      this.nombrePrincipal = "Pedido en transito";
      this.titulo = "Seguimiento pedido en transito";
      this.ObjTypeDoc = "22";
    }
    else if (this.tipoSeguimiento == "FAC") {
      this.nombrePrincipal = "Seguimiento factura";
      this.titulo = "Seguimiento factura";
      this.ObjTypeDoc = "13";
    }
  }

  async cargaDatosIniciales() {
    var objeto = "SELECT * FROM [OSLP]";
    var dato: any = await this._api.GetNotFileQuery(objeto);
    this.vendedores = dato.objeto;

    this.obtieneTitulo();


    this.columnsCopy = [];

    var data: any = await this._api.getQueryAll();
    var query: string = "";


    if (this.tipoSeguimiento == "COT") {

      this.columns = [
        { key: 'DocNum', title: "N°", width: "100px", cellTemplate: this.frmNumeroDocumento },
        { key: 'DocStatus', title: 'Estado', width: "100px", cellTemplate: this.frmEstado },
        { key: 'DocCur', title: 'Moneda', width: "100px" },
        { key: 'CardName', title: 'Cliente', width: "300px" },
        { key: 'SlpCode', title: 'Vendedor', width: "300px", cellTemplate: this.frmVendedor },
        { key: 'DocDate', title: 'Fecha documento', cellTemplate: this.frmFechaDocumento },
        { key: 'DocDueDate', title: 'Fecha entrega', cellTemplate: this.frmFechaEntrega },
        { key: 'DocEntry', title: 'Acción', width: "100px", cellTemplate: this.frmAccion }

      ];
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "seguimientoCotizacion") {
          objeto = element.CONSULTA;
        }
      });
      //objeto = "SELECT TOP 10000 [DocEntry], [DocNum], [DocStatus], [CardCode], [CardName], [SlpCode], [DocDate], [TaxDate], [DocDueDate], [DocTotal], [DocCur] FROM [OQUT] ORDER BY [DocNum] DESC";
      dato = await this._api.GetNotFileQuery(objeto);
      this.data = dato.objeto;
    }
    else if (this.tipoSeguimiento == "NV") {

      this.columns = [
        { key: 'DocNum', title: "N°", width: "100px", cellTemplate: this.frmNumeroDocumento },
        { key: 'FolioNum_FAC', title: 'Folio FAC', width: "100px" },
        { key: 'DocStatus', title: 'Estado', width: "100px", cellTemplate: this.frmEstado },
        { key: 'DocCur', title: 'Moneda', width: "100px" },
        { key: 'CardName', title: 'Cliente', width: "300px" },
        { key: 'SlpCode', title: 'Vendedor', width: "300px", cellTemplate: this.frmVendedor },
        { key: 'NumAtCard', title: 'OC Cliente', width: "300px" },
        { key: 'DocDate', title: 'Fecha emisión', cellTemplate: this.frmFechaDocumento },
        { key: 'DocDueDate', title: 'Fecha entrega', cellTemplate: this.frmFechaEntrega },
        { key: 'FolioNum_GD', title: 'GD', width: "100px" },
        { key: 'DocNum_FAC', title: 'FAC NV', width: "100px" },

        { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

      ];

      await data.objeto.forEach(element => {
        if (element.NOMBRE == "seguimientoNotaVenta") {
          objeto = element.CONSULTA;
        }
      });
      dato = await this._api.GetNotFileQuery(objeto);
      this.data = dato.objeto;
    } else if (this.tipoSeguimiento == "FAC") {

      this.columns = [
        { key: 'DocNum', title: "N°", width: "100px", cellTemplate: this.frmNumeroDocumento },
        { key: 'FolioNum', title: 'Folio', width: "100px" },
        { key: 'DocStatus', title: 'Estado', width: "100px", cellTemplate: this.frmEstado },
        { key: 'DocCur', title: 'Moneda', width: "100px" },
        { key: 'CardName', title: 'Cliente', width: "300px" },
        { key: 'SlpCode', title: 'Vendedor', width: "300px", cellTemplate: this.frmVendedor },
        { key: 'NumAtCard', title: 'OC Cliente', width: "300px" },
        { key: 'DocDate', title: 'Fecha emisión', cellTemplate: this.frmFechaDocumento },
        { key: 'DocDueDate', title: 'Fecha entrega', cellTemplate: this.frmFechaEntrega },
        { key: 'FolioNum_GD', title: 'GD', width: "100px" },
        { key: 'DocNum_NV', title: 'NV', width: "100px" },
        { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

      ];

      await data.objeto.forEach(element => {
        if (element.NOMBRE == "seguimientoFactura") {
          objeto = element.CONSULTA;
        }
      });
      dato = await this._api.GetNotFileQuery(objeto);
      this.data = dato.objeto;
    }
    if (this.tipoSeguimiento == "OFECOM") {

      this.columns = [
        { key: 'DocNum', title: "N°", width: "100px", cellTemplate: this.frmNumeroDocumento },
        { key: 'DocStatus', title: 'Estado', width: "100px", cellTemplate: this.frmEstado },
        { key: 'DocCur', title: 'Moneda', width: "100px" },
        { key: 'CardName', title: 'Cliente', width: "300px" },
        { key: 'SlpCode', title: 'Vendedor', width: "300px", cellTemplate: this.frmVendedor },
        { key: 'DocDate', title: 'Fecha documento', cellTemplate: this.frmFechaDocumento },
        { key: 'DocDueDate', title: 'Fecha entrega', cellTemplate: this.frmFechaEntrega },
        { key: 'DocEntry', title: 'Acción', width: "100px", cellTemplate: this.frmAccion }

      ];
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "seguimientoOfertaCompra") {
          objeto = element.CONSULTA;
        }
      });
      dato = await this._api.GetNotFileQuery(objeto);
      this.data = dato.objeto;
    }
    else if (this.tipoSeguimiento == "COMPRA") {

      this.columns = [
        { key: 'DocNum', title: "N°", width: "100px", cellTemplate: this.frmNumeroDocumento },
        { key: 'DocStatus', title: 'Estado', width: "100px", cellTemplate: this.frmEstado },
        { key: 'DocCur', title: 'Moneda', width: "100px" },
        { key: 'CardName', title: 'Cliente', width: "300px" },
        { key: 'SlpCode', title: 'Vendedor', width: "300px", cellTemplate: this.frmVendedor },
        { key: 'NumAtCard', title: 'OC Cliente', width: "300px" },
        { key: 'DocDate', title: 'Fecha emisión', cellTemplate: this.frmFechaDocumento },
        { key: 'DocDueDate', title: 'Fecha entrega', cellTemplate: this.frmFechaEntrega },
        { key: 'FolioNum_GD', title: 'GD', width: "100px" },
        { key: 'DocNum_FAC', title: 'FAC', width: "100px" },
        { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

      ];

      await data.objeto.forEach(element => {
        if (element.NOMBRE == "seguimientoOrdenDeCompra") {
          objeto = element.CONSULTA;
        }
      });
      dato = await this._api.GetNotFileQuery(objeto);
      this.data = dato.objeto;
    }

    //this.columns = this.columnsCopy;

    //this.columnsCopy.forEach(element => {
    //  this.checked.add(element.key);
    //});

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };

    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }



  vendedorCodigoATexto(slpCode) {
    var SlpName = "";
    this.vendedores.forEach(function (a) {
      if (a) {
        if (a.SlpCode == slpCode) {
          SlpName = a.SlpName;
        }
      }

    })
    return SlpName;
  }


  public clicked: string;
  eventEmitted($event: { event: string; value: any }): void {
    //this.clicked = JSON.stringify($event);
    //console.log('$event', $event);
    //console.log($event);

    if ($event.event == "onRowDrop") {
      //this.moverIdiceTabla($event.value.currentIndex, $event.value.previousIndex);

    }
  }


  async EnviaCoreo(data) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      //modalDialogClass:variableGlobal.CLASS_MODAL_BG,
      scrollable: true
    };
    console.log("DATA:" + Object.values(data));
    const modalRef = this.modalService.open(envioCorreoSeguimientoaComponent, ngbModalOptions);
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.origen = this.tipoSeguimiento;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }
  validateEmail(email) {
    //console.log(email);
    //const re = /^(([^<>()[\]\\.,;:\s@@"]+(\.[^<>()[\]\\.,;:\s@@"]+)*)|(".+"))@@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //return re.test(String(email).toLowerCase());
    try {
      let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
      let valid = pattern.test(email);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }


  async CerrarDoc(row) {
    var ObjType = "17";
    var cerrada = "cerrada";
    if (this.tipoSeguimiento == "COT") {
      ObjType = "23";
    }
    else if (this.tipoSeguimiento == "OFECOM") {
      ObjType = "540000006";
      cerrada = "cerrado";
    }
    else if (this.tipoSeguimiento == "COMPRA") {
      ObjType = "22";
      cerrada = "cerrado";
    }

    var DocEntry = row.DocEntry;
    var DocNum = row.DocNum;

    Swal.fire({
      title: "¿Cerrar " + this.nombrePrincipal + " " + DocNum + " ?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: "No",

    }).then(async (result) => {

      if (result.isConfirmed) {


        Swal.fire({
          title: 'Espere por favor!',
          html: 'Se está cerrado el documento',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        });


        var datos: any = await this._apiVenta.CerrarDocumento(DocEntry, ObjType);
        console.log(datos);
        if (datos.estado) {
          row.DocStatus = "C";
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: this.nombrePrincipal + " " + cerrada,
            showConfirmButton: false,
            timer: 6000
          })

        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 6000
          })
        }


      }
      else if (result.isDenied) {
        //Swal.fire('Documento no alterado, puede continuar', '', 'info')
      }
    })

  }

  async generarNV(row) {
    Swal.fire({
      title: "¿Estas seguro que quieres crear la Nota de venta de la Cotización " + row.DocNum + " ?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: "No",

    }).then(async (result) => {

      if (result.isConfirmed) {


        Swal.fire({
          title: 'Espere por favor!',
          html: 'Se está creando el documento',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        });


        var datos: any = await this._apiVenta.CotizacionANV(row.DocEntry);
        console.log(datos);
        if (datos.estado) {
          row.DocStatus = "C";
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 6000
          })

        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 6000
          })
        }


      }
      else if (result.isDenied) {
        Swal.fire('Documento no alterado, puede continuar', '', 'info')
      }
    })
  }
  async generarOC(row) {
    Swal.fire({
      title: "¿Estas seguro que quieres crear Pedido en transito de la Oferta de compra " + row.DocNum + " ?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: "No",

    }).then(async (result) => {

      if (result.isConfirmed) {


        Swal.fire({
          title: 'Espere por favor!',
          html: 'Se está creando el documento',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        });


        var datos: any = await this._apiVenta.OfertaAOC(row.DocEntry);
        console.log(datos);
        if (datos.estado) {
          row.DocStatus = "C";
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 6000
          })

        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 6000
          })
        }


      }
      else if (result.isDenied) {
        Swal.fire('Documento no alterado, puede continuar', '', 'info')
      }
    })
  }

  abrirLayout(item) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      scrollable: true
    };

    var urlIframe = environment.RUTA_API + "/WEB/viewLayault?ObjType=" + this.ObjTypeDoc + "&DocEntry=" + item.DocEntry;
    var urlImprimir = environment.RUTA_API + "/WEB/viewLayoultPDF?ObjType=" + this.ObjTypeDoc + "&DocEntry=" + item.DocEntry;
    console.log(urlIframe);
    const modalRef = this.modalService.open(VentaLayaultModelComponent, ngbModalOptions);
    modalRef.componentInstance.ObjType = this.ObjTypeDoc;
    modalRef.componentInstance.DocEntry = item.DocEntry;
    modalRef.componentInstance.urllayout = urlIframe;
    modalRef.componentInstance.urlImprimir = urlImprimir;


    modalRef.componentInstance.DocNum = item.DocNum;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }

}
