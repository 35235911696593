import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-descargar-informe-venta',
  templateUrl: './descargar-informe-venta.component.html',
  styleUrls: ['./descargar-informe-venta.component.sass']
})
export class DescargarInformeVentaComponent implements OnInit {

  constructor() { }

  nombreExcel:any;

  ngOnInit(): void {
  }

}
