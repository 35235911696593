import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessPartner, direccion, personaContacto } from 'src/app/model/BusinessPartnerModel';
import {IntranetoldService} from '../../../api/intranetold.service'
import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router'
import { stringify } from 'querystring';
import { SocioNegocioComponent } from './socio-negocio.component';
import {SapService} from "../../../api/sap.service";
import { variableGlobal} from '../../../global.enum';



@Component({
  selector: 'app-editaSocioNegocio',
  templateUrl: './editaSocioNegocio.html',
  styleUrls: ['./socio-negocio.component.sass']
})
export class editaSocioNegocioComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _activeRoute: ActivatedRoute,
    private _sap: SapService,
    private _api: IntranetoldService
  ) { }

	onSelected(value:string): void {
		this.socio.BillingAddress.Country = value.substring(value.indexOf(" ")+1, value.length);
    this.traerRegionPais();
	}

  vendedorNombre: string;
  onSelectedVendedor(event:any): void {
    var substrValue = (event.target.value).substring(0, (event.target.value).indexOf(":"))
    let substrVendedor = parseInt(((event.target.value).substring((event.target.value).indexOf(":")+1)).trim())
    // console.log("substr: "+substrValue);
    // console.log(event.target.value);
    var optionSelected = event.path[0][substrValue];
    this.vendedorNombre = optionSelected.text;
    this.socio.Vendedor = substrVendedor;
  }

  CardCode: string;
  desdeNotaVenta: boolean;

  nuevo:boolean = true;
  data:any = [];
  query:string;
  titulo:string;
  socio = {
    'Nombre': null,
    'Codigo': null,
    'Rut': null,
    'CardType':null,
    'Telefono': null,
    'Correo': null,
    'Vendedor':null,
    'VendedorNombre': null,
    'GroupCode':null,
    'U_IND_origenLead':null,
    //SE AGREGA OCRD.NOTES
    'Notes':null,
    //Giro = OCRD.NOTES
    // SE AGREGA DIRECCIÓN FACTURACIÓN
    'BillingAddress': {
        'Address': null,
        'Street': null,
        'County': null,
        'City': null,
        'Country': null,
        'State': null,
    },
    // END DIRECCION FACTURACIÓN
    'ContactPersons1': {
        'Name': null,
        'FirstName': null,
        'Cargo': null,
        'tel': null,
        'correo': null,
    },
    "Origen": null
  };

  socioCBox = {
    'Name': null
  }

  grupoSocio:any = [];
  vendedores:any = [];
  // DIRECCIÓN FACTURACIÓN
  paises:any = [];
  regiones:any = [];
  // END DIRECCIÓN FACTURACIÓN
  personaContacto:any = [];
  personaContactoDatos:any = [];
  direccionFacturacion: any = [];
  direccionFacturacionDatos: any = [];
  btnMostrar:string;

  // socio_app: any;

  ngOnInit(): void {
    // this.titulo = "Mantenedor Cliente/Lead";
    this.socioCopia = {...this.socio};
    this.socioCBox.Name = this.socioCopia.ContactPersons1.Name;
    this.cargaInicial();
    console.log(this.socio);
    // console.log("name pasado: "+this.socioCBox.Name);
  }

  // Funciones aparte de carga inicial
  async traerRegionPais() {
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "regionSegunPais") {
        query = element.CONSULTA;
      }
    });
    query = query.replace("TEXTO_REEMPLAZA", this.socio.BillingAddress.Country);
    var dato:any = await this._api.GetNotFileQuery(query);
    this.regiones = [...dato.objeto];
  }

  async traerDatosPersonaContacto(){
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaContactoSocioReemplazaName") {
        query = element.CONSULTA;
      }
    });
    query = query.replace("TEXTO_REEMPLAZA", this.socioCBox.Name);
    var dato:any = await this._api.GetNotFileQuery(query);
    this.personaContactoDatos = [...dato.objeto];
    this.personaContactoDatos .forEach(element => {
      this.socio.ContactPersons1 = {
        'Name': element.Name,
        'FirstName': element.FirstName,
        'Cargo': element.Position,
        'tel': element.Tel1,
        'correo': element.E_MailL,
      };
    });
  }

  async traerDatosDireccionFacturacion(){
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaDireccionSocioReemplaza") {
        query = element.CONSULTA;
      }
    });
    console.log(this.socio.BillingAddress.Address);
    query = query.replace("TEXTO_REEMPLAZA", this.socio.BillingAddress.Address);
    query = query.replace("TEXTO_REEMPLAZA1", this.socio.Codigo);
    var dato:any = await this._api.GetNotFileQuery(query);
    this.direccionFacturacionDatos = [...dato.objeto];
    this.direccionFacturacionDatos .forEach(element => {
      this.socio.BillingAddress = {
        'Address': element.Address,
        'Street': element.Street,
        'County': element.County,
        'City': element.City,
        'Country': element.Country,
        'State': element.State,
      };
    });
  }
  // END Funciones aparte de carga inicial



  async cargaInicial()
  {

    this.desdeNotaVentaCarga();

    var data: any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "grupoDeClientes") {
        query = element.CONSULTA;
        // console.log("origen: "+this.socio.Origen);
        if(this.socio.Origen != null && this.socio.Origen == "Compra") {
          query = query.replace("[GroupType] = 'C'","[GroupType] = 'S'");
        }

      }
    });
    var dato:any = await this._api.GetNotFileQuery(query);
    this.grupoSocio = [...dato.objeto];
    //
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaVendedor") {
        query = element.CONSULTA;
      }
    });
    dato = await this._api.GetNotFileQuery(query);
    this.vendedores = [...dato.objeto];

    // Llenar combobox de País
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "Pais") {
        query = element.CONSULTA;
      }
    });
    dato = await this._api.GetNotFileQuery(query);
    this.paises = [...dato.objeto];
    // END Llenar combobox de País

    // Llenar cbox región con todas las regiones
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "REGIONES") {
        query = element.CONSULTA;
      }
    });
    dato = await this._api.GetNotFileQuery(query);
    this.regiones = [...dato.objeto];
    // END Llenar cbox región

    // Cargar contactos socio negocio en combobox
    if (!this.nuevo || this.desdeNotaVenta) {
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "listaContactoSocio") {
          query = element.CONSULTA;
        }
      });
      // console.log("Código cargado: "+this.socio.Codigo);
      query = query.replace("TEXTO_REEMPLAZA", this.socio.Codigo);
      dato = await this._api.GetNotFileQuery(query);
      this.personaContacto = [...dato.objeto];
      // console.log("personaContacto!!! : "+Object.values(this.personaContacto[0]));
      if (this.desdeNotaVenta) {
        this.socioCBox.Name = this.socio.ContactPersons1.Name;
      }
    }
    // for (let index = 0; index < this.personaContacto.length; index++) {
    //   console.log("Contactos: "+Object.values(this.personaContacto[index]));
    // }

    // END Cargar contactos socio negocio

    // Cargar direccion facturacion datos socio negocio
    if (!this.nuevo || this.desdeNotaVenta) {
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "listaDireccionFacturacionSocio") {
          query = element.CONSULTA;
        }
      });
      // console.log("SOCIO CODIGO: "+this.socio.Codigo);
      query = query.replace("TEXTO_REEMPLAZA", this.socio.Codigo);
      dato = await this._api.GetNotFileQuery(query);
      this.direccionFacturacion = [...dato.objeto];
    }
    // dejar por defecto la última facturación al editar
    var lastAddressFacturacion = "";
    for (let index = 0; index < this.direccionFacturacion.length; index++) {
      // console.log("Facturaciones: "+Object.values(this.direccionFacturacion[index]));

      var substrAddrFix = this.direccionFacturacion[index].Address.substring(0, this.direccionFacturacion[index].Address.length-1);

      if (substrAddrFix == "FACTURACIÓN") {
        lastAddressFacturacion = this.direccionFacturacion[index].Address;

        var substrValue = lastAddressFacturacion;
        this.socio.BillingAddress.Address = substrValue;
        // console.log("facturacionConNumero: "+this.facturacionConNumero);
      }
    //END dejar por defecto la última facturación al editar
    }

    // END Cargar direccion facturacion datos socio negocio

    if (this.socio.Codigo) {
      this.btnMostrar = "actualizaContacto";
    }
    else
    {
      this.btnMostrar = "socioNuevo";
    }

    // console.log("contacto persona: "+this.socio.ContactPersons1.Name);
  }
  queryContactos:string;
  async cargaQuery(query:string)
  {
    var dato:any = await this._api.GetNotFileQuery(query);
    return dato.objeto;
  }

  cerrar() {
    if (this.copiaLimpiaJson.LicTradNum != null) {
      // console.log("not null");
      this.activeModal.close(this.copiaLimpiaJson);
      // console.log("JSON data"+Object.values(this.copiaLimpiaJson));
    } else {
      // console.log("null");
      this.activeModal.close(null);
    }
  }

  socioCopia:any = {};
  limpiaSocio()
  {
    this.socio = {...this.socioCopia};
  }

  contactoNuevo:boolean=false;
  contactoInicial:boolean=true;
  definirPersonaContacto: boolean=false;

  personaName: string;
  cambiaPersonaContacto(): void
  {
    if (this.socioCBox.Name.length == 0) {

      this.socio.ContactPersons1 = {
          'Name': "",
          'FirstName': null,
          'Cargo': null,
          'tel': null,
          'correo': null,
      };
      console.log("LengTH: "+this.socioCBox.Name.length);
      console.log("Contact persons NAME: "+this.socio.ContactPersons1.Name);
      this.contactoNuevo = true;
      this.contactoInicial = false;
      this.definirPersonaContacto = true;
      if (this.nuevo){
        this.btnMostrar = "socioNuevo";
      }
      else{
        this.btnMostrar = "actualizaContacto";
      }
    }
    else
    {
      this.contactoNuevo = false;
      this.contactoInicial = false;
      this.definirPersonaContacto = true;
      console.log("CBOX NAME: "+this.socioCBox.Name);
      this.traerDatosPersonaContacto();
      if (this.nuevo){
        this.btnMostrar = "socioNuevo";
      }
      else{
        this.btnMostrar = "actualizaContacto";
      }
      //this.cargaInicial();
    }
  }

  facturacionInicial:boolean=true;
  definirDireccionFacturacion: boolean=false;
  cambiaDireccionFacturacion(): void
  {
    if (this.socio.BillingAddress.Address == "")
    {
      this.socio.BillingAddress = {
          'Address': "",
          'Street': null,
          'County': null,
          'City': null,
          'State': null,
          'Country': null,
      };
      this.contactoNuevo = true;
      this.facturacionInicial = false;
      this.definirDireccionFacturacion = true;
      if (this.nuevo){
        this.btnMostrar = "socioNuevo";
      }
      else{
        this.btnMostrar = "actualizaContacto";
      }
    }
    else
    {
      this.contactoNuevo = false;
      this.contactoInicial = false;
      this.definirDireccionFacturacion = false;
      this.traerDatosDireccionFacturacion();
      if (this.nuevo){
        this.btnMostrar = "socioNuevo";
      }
      else{
        this.btnMostrar = "actualizaContacto";
      }
    }
    console.log("PASO");
  }

  socioModelApi = {
    "priceList": null,
    "GroupCode": 0,
    "CardType": null,
    "Nombre": null,
    "Codigo": null,
    "Rut": null,
    "Telefono": null,
    "Correo": null,
    "Vendedor": 0,
    'VendedorNombre': null,
    "U_IND_origenLead":null,
    "Notes":null,
    // SE AGREGA DIRECCIÓN FACTURACIÓN
    'BillingAddress': {
      'Address': null,
      'Street': null,
      'County': null,
      'City': null,
      'Country': null,
      'State': null,
    },
    // END DIRECCION FACTURACIÓN
    "ContactPersons1": {
      "ContactID": null,
      "FirstName": null,
      "Correo": null,
      "Address": null,
      "Cargo": null,
      "Name": null,
      "Tel": null,
      "CntctCode": 0
    },
    "Origen": null
  }

  contactoModel = {
    "CardCode": null,
    "ContactID": null,
    "FirstName": null,
    "Correo": null,
    "Address": null,
    "Cargo": null,
    "Name": null,
    "CntctCode": null,
    "Tel": null
  }

  async nuevoSocio(accion: string)
  {
    document.getElementById('')
    var copiaLimpia = {...this.socioModelApi};
    var PriceList = 1;
    if (this.socio.Origen == "Venta") {
      PriceList = 2
    }
    this.grupoSocio.forEach(element => {
      if (this.socio.GroupCode == element.GroupCode) {
        if (element.PriceList) {
          PriceList = element.PriceList;
        }
      }
    });

    if (this.validarObligatorios(this.socio.Rut, this.socio.Nombre, this.socio.Correo))
    {
      copiaLimpia.Nombre = this.socio.Nombre;
      copiaLimpia.CardType = this.socio.CardType;
      copiaLimpia.priceList = PriceList;
      copiaLimpia.Codigo = this.socio.Codigo;
      copiaLimpia.Rut = this.socio.Rut.toUpperCase();
      copiaLimpia.Telefono = this.socio.Telefono;
      copiaLimpia.Correo = this.socio.Correo;
      if (this.socio.Vendedor == null)
      {
        copiaLimpia.Vendedor = -1;
      }
      else
      {
        copiaLimpia.Vendedor = this.socio.Vendedor;
      }
      if (this.socio.VendedorNombre == null)
      {
        copiaLimpia.VendedorNombre = this.vendedorNombre;
      }
      else
      {
        copiaLimpia.VendedorNombre = this.socio.VendedorNombre;
      }
      if (this.socio.GroupCode == null)
      {
        copiaLimpia.GroupCode = -1;
      }
      else
      {
        copiaLimpia.GroupCode = this.socio.GroupCode;
      }
      copiaLimpia.U_IND_origenLead = this.socio.U_IND_origenLead;
      copiaLimpia.Notes = this.socio.Notes;
      if (this.definirDireccionFacturacion)
      {
        copiaLimpia.BillingAddress.Address = "c.FACTURACIÓN"
      }
      else if(!this.definirDireccionFacturacion && this.socio.BillingAddress.Address != null)
      {
        copiaLimpia.BillingAddress.Address = "u."+this.socio.BillingAddress.Address
      }
      else
      {
        copiaLimpia.BillingAddress.Address = null;
      }
      copiaLimpia.BillingAddress.Street = this.socio.BillingAddress.Street;
      copiaLimpia.BillingAddress.County = this.socio.BillingAddress.County;
      copiaLimpia.BillingAddress.City = this.socio.BillingAddress.City;
      copiaLimpia.BillingAddress.Country = this.socio.BillingAddress.Country;
      copiaLimpia.BillingAddress.State = this.socio.BillingAddress.State;
      copiaLimpia.ContactPersons1.Name = this.socio.ContactPersons1.Name;
      copiaLimpia.ContactPersons1.FirstName = this.socio.ContactPersons1.FirstName;
      copiaLimpia.ContactPersons1.Cargo = this.socio.ContactPersons1.Cargo;
      copiaLimpia.ContactPersons1.Tel = this.socio.ContactPersons1.tel;
      copiaLimpia.ContactPersons1.Correo = this.socio.ContactPersons1.correo;
      console.log("ORIGEN: "+this.socio.Origen);
      copiaLimpia.Origen = this.socio.Origen;

      var datos:any;
      if (accion === "crear") {
        console.log("Crear Persona");
        datos = await this._api.generarBusinessPartnerModelCrear(copiaLimpia);
      }
      else if (accion === "actualizar") {
        console.log("Actualizar Persona");
        datos = await this._api.generarBusinessPartnerModel(copiaLimpia);
      }
      //
      if (datos.estado && accion === "crear") {
        this.nuevo = false;
        this.btnMostrar = "";
        this.socio.Codigo = datos.mensaje;

        this.agregarDatosTabla(copiaLimpia, Object.values(datos)[0]);
        Swal.fire("Mensaje", "Cuenta creada con exito.!!", "success");
      }
      else if (datos.estado && accion === "actualizar") {
        this.nuevo = false;
        this.btnMostrar = "";
        this.socio.Codigo = datos.mensaje;

        this.actualizarDatosTabla(copiaLimpia, Object.values(datos)[0]);
        Swal.fire("Mensaje", "Cuenta actualizada con exito.!!", "success");
      }
      else
      {
        Swal.fire("Mensaje", datos.mensaje, "error");
      }

    }
    else
    {
      Swal.fire("Mensaje", "Debes llenar todos los campos requeridos", "warning");
    }
  }

  async creaActualizaContacto(contactoNuevo:boolean, nuevo:boolean)
  {
    var copiaModelo = {...this.contactoModel};

    var CntctCode = 0;
    if (!contactoNuevo) {
      this.personaContacto.forEach(element => {
        if (this.socio.ContactPersons1.Name == element.Name) {
          CntctCode = element.CntctCode;
        }
      });
    }
    copiaModelo.CardCode = this.socio.Codigo;
    copiaModelo.Name = this.socio.ContactPersons1.Name;
    copiaModelo.FirstName = this.socio.ContactPersons1.FirstName;
    copiaModelo.Cargo = this.socio.ContactPersons1.Cargo;
    copiaModelo.Tel = this.socio.ContactPersons1.tel;
    copiaModelo.Correo = this.socio.ContactPersons1.correo;
    copiaModelo.CntctCode = CntctCode;

    var datos:any;
    if (contactoNuevo || !nuevo) {
      datos = await this._api.AgregarContactoModelo(copiaModelo);
    }
    else
    {
      datos = await this._api.ActualizarContactoModelo(copiaModelo);
    }

    if (datos.estado) {
      // this.personaContacto = this.cargaQuery(this.queryContactos);
      this.contactoNuevo = false;
      this.btnMostrar = "actualizaContacto"
      this.cargaInicial();
      Swal.fire("Mensaje", datos.mensaje, "success");
    }
    else
    {
      Swal.fire("Mensaje", datos.mensaje, "error");
    }
  }

  validarObligatorios(rut, nombre, correo): boolean{
    if ((rut != null) && (nombre != null) && (correo != null)) {
      return true;
    }
    else
    {
      return false;
    }
  }

  copiaLimpiaJson = {
    "GroupCode": 0, //0
    "CardType": null, //1
    "CardName": null, //2
    "CardCode": null, //3
    "LicTradNum": null, //4
    "Phone1": null, //5
    "E_Mail": null, //6
    "SlpName": null, //7
    "U_IND_origenLead": null, //8
    "Notes": null, //9
    "StrrtB": null, //10
    // SE AGREGA DIRECCIÓN FACTURACIÓN
    'BillingAddress': { //11
      'Address': null,
      'Street': null,
      'County': null,
      'City': null,
      'Country': null,
      'State': null,
    },
    // END DIRECCION FACTURACIÓN
    "ContactPersons1": { //12
      "ContactID": null,
      "FirstName": null,
      "Correo": null,
      "Address": null,
      "Cargo": null,
      "Name": null,
      "Tel": null,
      "CntctCode": 0,
    },
    "SlpCode": null //13
  }

  facturacionConNumero:string = "";
  contadorFacturaciones:number = 0;
  actualizarDatosTabla(copiaLimpia, cardCode) {
    this.copiaLimpiaJson.CardName = copiaLimpia.Nombre;
    this.copiaLimpiaJson.CardType = copiaLimpia.CardType;
    this.copiaLimpiaJson.CardCode = cardCode;
    this.copiaLimpiaJson.LicTradNum = copiaLimpia.Rut;
    this.copiaLimpiaJson.Phone1 = copiaLimpia.Telefono;
    this.copiaLimpiaJson.SlpName = copiaLimpia.VendedorNombre;
    this.copiaLimpiaJson.SlpCode = copiaLimpia.Vendedor;
    this.copiaLimpiaJson.GroupCode = copiaLimpia.GroupCode;
    this.copiaLimpiaJson.E_Mail = copiaLimpia.Correo;
    this.copiaLimpiaJson.U_IND_origenLead = copiaLimpia.U_IND_origenLead;
    this.copiaLimpiaJson.Notes = copiaLimpia.Notes;
    this.copiaLimpiaJson.BillingAddress.Address = copiaLimpia.BillingAddress.Address;
    this.copiaLimpiaJson.BillingAddress.Street = copiaLimpia.BillingAddress.Street;
    // this.copiaLimpiaJson.StrrtB = copiaLimpia.BillingAddress.Street;
    this.copiaLimpiaJson.BillingAddress.County = copiaLimpia.BillingAddress.County;
    this.copiaLimpiaJson.BillingAddress.City = copiaLimpia.BillingAddress.City;
    this.copiaLimpiaJson.BillingAddress.Country = copiaLimpia.BillingAddress.Country;
    this.copiaLimpiaJson.BillingAddress.State = copiaLimpia.BillingAddress.State;
    this.copiaLimpiaJson.ContactPersons1.Name = copiaLimpia.ContactPersons1.Name;
    this.copiaLimpiaJson.ContactPersons1.FirstName = copiaLimpia.ContactPersons1.FirstName;
    this.copiaLimpiaJson.ContactPersons1.Cargo = copiaLimpia.ContactPersons1.Cargo;
    this.copiaLimpiaJson.ContactPersons1.Tel = copiaLimpia.ContactPersons1.Tel;
    this.copiaLimpiaJson.ContactPersons1.Correo = copiaLimpia.ContactPersons1.Correo;
  }

  agregarDatosTabla(copiaLimpia, cardCode) {
    this.copiaLimpiaJson.CardName = copiaLimpia.Nombre;
    if (this.socio.Origen == "Venta") {
      this.copiaLimpiaJson.CardType = "Lead";
    }
    else if (this.socio.Origen == "Compra") {
      this.copiaLimpiaJson.CardType = "Proveedor";
    }

    this.copiaLimpiaJson.CardCode = cardCode;
    this.copiaLimpiaJson.LicTradNum = copiaLimpia.Rut;

    if (copiaLimpia.Telefono != null) {
      this.copiaLimpiaJson.Phone1 = copiaLimpia.Telefono;
    } else {
      this.copiaLimpiaJson.Phone1 = null;
    }

    if (copiaLimpia.Vendedor > -1) {
      this.copiaLimpiaJson.SlpCode = copiaLimpia.Vendedor;
      this.copiaLimpiaJson.SlpName = copiaLimpia.VendedorNombre;
    } else {
      this.copiaLimpiaJson.SlpCode = -1;
      this.copiaLimpiaJson.SlpName = '-Ningún empleado del departamento de ventas-'
    }

    if (copiaLimpia.GroupCode > -1) {
      this.copiaLimpiaJson.GroupCode = copiaLimpia.GroupCode;
    } else {
      this.copiaLimpiaJson.GroupCode = 100;
    }

    this.copiaLimpiaJson.E_Mail = copiaLimpia.Correo;

    if (copiaLimpia.U_IND_origenLead != null) {
      this.copiaLimpiaJson.U_IND_origenLead = copiaLimpia.U_IND_origenLead;
    } else {
      this.copiaLimpiaJson.U_IND_origenLead = null;
    }

    if (copiaLimpia.Notes != null) {
      this.copiaLimpiaJson.Notes = copiaLimpia.Notes;
    } else {
      this.copiaLimpiaJson.Notes = null;
    }

    if (copiaLimpia.BillingAddress.Address != null) {
      console.log("entra acá");
      this.copiaLimpiaJson.BillingAddress.Address = copiaLimpia.BillingAddress.Address;
      this.copiaLimpiaJson.BillingAddress.Street = copiaLimpia.BillingAddress.Street;
      // this.copiaLimpiaJson.StrrtB = copiaLimpia.BillingAddress.Street;
      this.copiaLimpiaJson.BillingAddress.County = copiaLimpia.BillingAddress.County;
      this.copiaLimpiaJson.BillingAddress.City = copiaLimpia.BillingAddress.City;
      this.copiaLimpiaJson.BillingAddress.Country = copiaLimpia.BillingAddress.Country;
      this.copiaLimpiaJson.BillingAddress.State = copiaLimpia.BillingAddress.State;
    }
    else {
      console.log("entra al otro lado: "+copiaLimpia.BillingAddress.Address);
      this.copiaLimpiaJson.BillingAddress.Address = null;
      this.copiaLimpiaJson.BillingAddress.Street = null;
      // this.copiaLimpiaJson.StrrtB = null;
      this.copiaLimpiaJson.BillingAddress.County = null;
      this.copiaLimpiaJson.BillingAddress.City = null;
      this.copiaLimpiaJson.BillingAddress.Country = null;
      this.copiaLimpiaJson.BillingAddress.State = null;
    }

    if (copiaLimpia.ContactPersons1.Name != null) {
      this.copiaLimpiaJson.ContactPersons1.Name = copiaLimpia.ContactPersons1.Name;
      this.copiaLimpiaJson.ContactPersons1.FirstName = copiaLimpia.ContactPersons1.FirstName;
      this.copiaLimpiaJson.ContactPersons1.Cargo = copiaLimpia.ContactPersons1.Cargo;
      this.copiaLimpiaJson.ContactPersons1.Tel = copiaLimpia.ContactPersons1.Tel;
      this.copiaLimpiaJson.ContactPersons1.Correo = copiaLimpia.ContactPersons1.Correo;
    }
    else {
      this.copiaLimpiaJson.ContactPersons1.Name = null;
    }
    // console.log("Factura: "+copiaLimpia.BillingAddress.Address);
    // console.log("Contacto: "+copiaLimpia.ContactPersons1.Name);

  }

  jsonEditarNotaVenta = {
    "Codigo": null
  }
  async desdeNotaVentaCarga() {
    if (this.desdeNotaVenta) {
      // console.log("ENTRA ACÁ: "+Object.values(this.socioCopia));
      this.jsonEditarNotaVenta.Codigo = this.CardCode;
      var data: any = await this._sap.ObtenerSocioNegocioEdita(this.jsonEditarNotaVenta);
      // console.log("DATA: "+Object.values(data.resultado));
      //
      const body =  {
        ...data.resultado,
      }
      // console.log("BODY AFTER: "+Object.values(body));
      this.nuevo = false;
      this.btnMostrar = "actualizaContacto";
      this.llenarSocioNuevo(body);
      // console.log("código: "+this.socio.Codigo);
      // var final = [];
      // for (var key in body) {
      //   console.log("KEY: "+key);
      //   if(key == "PersonasContacto") {
      //     console.log("largo key: "+body.PersonasContacto.length);
      //     for (var i = 0; i < body.PersonasContacto.length; i++) {

      //       console.log("BODY KEY NAME: "+body.PersonasContacto[i].Name);
      //       final.push(body.PersonasContacto[i].Name);
      //     }
      //   }
      // }
      // for (let i = 0; i < final.length; i++) {
      //   console.log("FINAL VALUE: "+final[i]);
      // }
    }
  }

  socioNuevo:any = {};
  llenarSocioNuevo(objeto) {
    // console.log("telefono: "+Object.values(objeto.Telefono));
    this.socioNuevo = {
      'Nombre': objeto.Nombre,
      'Codigo': objeto.Codigo,
      'Rut': objeto.Rut,
      'CardType':objeto.CardType,
      'Telefono': objeto.Telefono,
      'Correo': objeto.Correo,
      'Vendedor': objeto.Vendedor,
      'VendedorNombre': null,
      'GroupCode': objeto.GroupCode,
      'U_IND_origenLead': objeto.U_IND_origenLead,
      'Notes': objeto.Notes,
      'BillingAddress': {
          'Address': objeto.BillingAddresses[0].Address,
          'Street': objeto.BillingAddresses[0].Street,
          'County': objeto.BillingAddresses[0].County,
          'City': objeto.BillingAddresses[0].City,
          'Country': objeto.BillingAddresses[0].Country,
          'State': objeto.BillingAddresses[0].State,
      },
      'ContactPersons1': {
          'Name': objeto.PersonasContacto[0].Name,
          'FirstName': objeto.PersonasContacto[0].FirstName,
          'Cargo': objeto.PersonasContacto[0].Position,
          'tel': objeto.PersonasContacto[0].Tel,
          'correo': objeto.PersonasContacto[0].Correo,
      },
      "Origen": null
    };
    this.socio = {...this.socioNuevo};
    // console.log("Socio fixed!: "+Object.values(this.socio));
  }

}
