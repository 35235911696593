import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import {variableGlobal} from './../global.enum'
import {adjuntoCorreo,envioCorreo,evento} from "./../model/Indesad"
import { environment } from 'src/environments/environment';
 

@Injectable({
  providedIn: 'root'
})
export class IntranetNewService {

  constructor(
    private _http: HttpClient,
  ) { }


  async ObtieneMenuAccesoProcesadoAdmin(objeto:any)
  {

    const body =  
      {
        "token": variableGlobal.TOKEN,
        "objeto": objeto
      }

    
    return this._http.post(environment.RUTA_API + '/api/IntranetV2/ObtieneMenuAccesoProcesadoAdmin', body).toPromise();
  }

  async ObtenerConfiguracion()
  {

    const body =  
     {"estado": true, "TOKEN": variableGlobal.TOKEN, "mensaje": "", "resultado": null}
  

    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/IntranetV2/ObtenerConfiguracion', body).toPromise();
  }

  async enviaCorreo(objeto:envioCorreo, origen)
  {
    const body =  
    {
      ...objeto,
      origen
    }
    console.log(body);
    // console.log("RUTA: "+environment.RUTA_API);
    return this._http.post(environment.RUTA_API + '/api/IntranetV2/enviaCorreo', body).toPromise();
  }

  async ObtenerGrupoUsuarios()
  {
    const body =  "";
    return this._http.post(environment.RUTA_API + '/api/IntranetV2/GetUserGroups', body).toPromise();
  }

}
