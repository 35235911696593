<div class="modal-header" style="height: 65px">
  <h4
    class="modal-title titulo-notadeventa"
    id="modal-basic-title"
    style="color: #91ca41"
  >
    <p>Crear/Editar Meta</p>
  </h4>

  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="cerrar()"
    style="color: white"
  >
    <span aria-hidden="true" style="color: #91ca41">&times;</span>
  </button>
</div>

<div class="row" style="margin: 0px; margin-top: 10px">

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">SlpCode</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.SlpCode"
      />
    </div>
  </div>

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Concepto</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.CONCEPTO"
      />
    </div>
  </div>

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Nombre</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.NOMBRE"
      />
    </div>
  </div>

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Mes</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.MES"
      />
    </div>
  </div>

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Año</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.ANIO"
      />
    </div>
  </div>

  <div class="col-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Valor</span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder=""
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="data.VALOR"
      />
    </div>
  </div>

</div>



<div class="modal-footer" style="height: 65px">


  <button mat-raised-button color="primary" style="margin-right: 5px;" (click)="cerrar()">Cerrar</button>

  <button mat-raised-button color="primary" style="background: #91ca41;margin-right: 5px;" (click)="guardar()">Crear</button>

</div>





