import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Columns,
  Config,
  DefaultConfig,
  APIDefinition,
  API,
} from "ngx-easy-table";
import { variableGlobal } from "src/app/global.enum";


@Component({
  selector: 'app-seleccionar-serie-lote',
  templateUrl: './seleccionar-serie-lote.component.html',
  styleUrls: ['./seleccionar-serie-lote.component.css']
})

export class SeleccionarSerieLoteComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }


  searchTerm: string;
  public configuration: Config;
  public columns: Columns[];
  data:any = [];
  dataDeADies:any = [];
  
  @ViewChild("table", { static: true }) table: APIDefinition;
  @ViewChild("frmAccion", { static: true }) frmAccion: TemplateRef<any>;


  @ViewChild(MatAccordion) accordion: MatAccordion;
  ngOnInit(): void {
  
    console.log(this.data)
    for (let index = 0; index < 10; index++) {

      if (this.data.length > index) {
        this.dataDeADies.push(this.data[index]);
      }
      
      
    }



  }

  async buscarDato()
  {
    var texto = this.searchTerm.toLowerCase();
    var filtro = await this.data.filter(item => item["CodigoLoteSerie"].toLowerCase().includes(texto));
    console.log(filtro);

    this.dataDeADies = [];
    for (let index = 0; index < 10; index++) {

      if (filtro.length > index) {
        console.log(filtro[index]);
        this.dataDeADies.push(filtro[index]);
      }
      
      
    }
    
  }



  select(item) {
    this.activeModal.close(item);
  }

  cerrar() {
    this.activeModal.close();
  }


}
