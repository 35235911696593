import { Component, OnInit } from '@angular/core';
import {variableGlobal} from './../../global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CambioPwComponent } from 'src/app/login/cambio-pw/cambio-pw.component';
import { IntranetoldService } from 'src/app/api/intranetold.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  constructor(
    private _dbService: NgxIndexedDBService,
    private _cookieService: CookieService,
    private modalService: NgbModal,
    private _api: IntranetoldService
  ) { }


  general = variableGlobal;
  bandera:string;
  CompnyName:any
  dato
  async ngOnInit(): Promise<void> {

    let query = "SELECT * FROM OADM";
    this.dato = await this._api.GetNotFileQuery(query);
    this.dato = this.dato.objeto[0];

    this.CompnyName = this.dato.CompnyName;
    const CmpnyAddrF: string = this.dato.CmpnyAddrF.toLowerCase();
    console.log("OADM",this.dato)
    if (CmpnyAddrF.includes('peru')) {
      this.bandera = 'peru';
    }
    if (CmpnyAddrF.includes('chile')) {
      this.bandera = 'chile';
    }

    console.log("BANDERA",this.bandera)

  }

  async cerrarSession()
  {

    this._cookieService.deleteAll();
    await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).toPromise();
    await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu).toPromise();
    await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).toPromise();
    await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_perfiles).toPromise();

    window.location.href = "/login";
  }

  loginData: any;
  cambioContrasena(){

    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(lg => {
      this.loginData = lg;
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size:"xs",
        modalDialogClass:variableGlobal.CLASS_MODAL_BG
      };
      // console.log("data:",this.loginData.datos.login.RUT);
      const modalRef = this.modalService.open(CambioPwComponent, ngbModalOptions);
      modalRef.componentInstance.datos.RUT = this.loginData.datos.login.RUT;
      modalRef.result.then(result => {
        this.cerrarSession();
      });
    });

  }

  refresh() {
    location.reload();
}


}
