<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Modelo aprobación</h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">

        
      <ngx-table #tabla [configuration]="configuration"
        [data]="data.APROBACION"
        [columns]="columns">
      </ngx-table>
      <ng-template #frmAccion let-row let-rowIndex="rowIndex">
        <button class="btn btn-primary" (click)="verAprobadores(row)">Ver aprobadores</button>
      </ng-template> 
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger btn-sm" (click)="aprobarRechazar(false)" *ngIf="muestraBotonApruebRechaza()">Rechazar</button>
  <button class="btn btn-success btn-sm" (click)="aprobarRechazar(true)" *ngIf="muestraBotonApruebRechaza()">Aprobar</button>
  <button class="btn btn-success btn-sm" (click)="generarDocumento()" *ngIf="muestraGenerarDoc()">Generar documento</button>
  <button class="btn btn-info btn-sm" (click)="cerrar()">Cerrar</button>
  <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
</div> 