<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     

    <div class="row">
        <div class="col-6">
            <label for="StreetB">Calle</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="data.Street" name="Street" />
        </div>
    </div>
    <!--<div class="row">
        <div class="col-6">
            <label>Numero de la calle</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="StreetNoB" onchange="LoadCustomFactura()" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Colonia</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="BlockB" onchange="LoadCustomFactura()" />
        </div>
    </div>-->

    <div class="row">
        <div class="col-6">
            <label>Ciudad</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="data.City" name="City" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Codigo postal</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="data.ZipCode" name="ZipCode"  />

        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Comuna</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="data.County" name="County" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>País</label>
        </div>
        <div class="col-6">
            <select class="form-control" [(ngModel)]="data.Country" name="Country" disabled>
                <option [ngValue]="null" disabled>Selecciona pais</option>
                <option *ngFor="let row of OCRY" [ngValue]="row.Code">{{row.Name}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Comuna</label>
        </div>
        <div class="col-6">
            <select class="form-control" [(ngModel)]="data.State" name="State">
                <option [ngValue]="null" disabled>Selecciona comuna</option>
                <option *ngFor="let row of OCST" [ngValue]="row.Code">{{row.Name}}</option>
            </select>
        </div>
    </div>
    
    <!--<div class="row">
        <div class="col-6">
            <label>Edificio/Planta/Sala</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="BuildingB" onchange="LoadCustomFactura()" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Nombre de dirección 2</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="Address2B" onchange="LoadCustomFactura()" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>Nombre de dirección 3</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="Address3B" onchange="LoadCustomFactura()" />
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label>RUT Asociado</label>
        </div>
        <div class="col-6">
            <input type="text" class="form-control" id="GlblLocNumB" onchange="LoadCustomFactura()" />
        </div>
    </div>-->


  </div>
  <div class="modal-footer">
    <button (click)="Seleccionar()" type="button" class="btn btn-success">Guardar</button>
  </div> 