import { Component, OnInit,ViewChild, TemplateRef } from '@angular/core';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { DocumentoVencido,SaldoCliente } from 'src/app/model/ventaModel';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { variableGlobal } from 'src/app/global.enum';


@Component({
  selector: 'app-cuentas-socio',
  templateUrl: './cuentas-socio.component.html',
  styleUrls: ['./cuentas-socio.component.sass']
})
export class CuentasSocioComponent implements OnInit {
  public configurationVencidos: Config;
  public configurationSaldos: Config;  
  public columnsVencidos: Columns[];
  public columnsSaldos: Columns[];
  public dataVencidos: DocumentoVencido[] = [];
  public dataSaldos: SaldoCliente[] = [];
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('table2', { static: true }) table2: APIDefinition;
  @ViewChild('frmDebit', { static: true }) frmDebit: TemplateRef<any>;
  @ViewChild('frmCredit', { static: true }) frmCredit: TemplateRef<any>;
  @ViewChild('frmBalDueDeb', { static: true }) frmBalDueDeb: TemplateRef<any>;
  @ViewChild('frmBalDueCred', { static: true }) frmBalDueCred: TemplateRef<any>;
  @ViewChild('frmRefDate', { static: true }) frmRefDate: TemplateRef<any>;
  @ViewChild('frmAbonos', { static: true }) frmAbonos: TemplateRef<any>;
  @ViewChild('frmFechaemision', { static: true }) frmFechaemision: TemplateRef<any>;
  @ViewChild('frmFechavenc', { static: true }) frmFechavenc: TemplateRef<any>;
  @ViewChild('frmTotalFactura', { static: true }) frmTotalFactura: TemplateRef<any>;
  @ViewChild('frm15dias', { static: true }) frm15dias: TemplateRef<any>;
  @ViewChild('frm31dias', { static: true }) frm31dias: TemplateRef<any>;
  @ViewChild('frm61dias', { static: true }) frm61dias: TemplateRef<any>;
  @ViewChild('frm91dias', { static: true }) frm91dias: TemplateRef<any>;
  @ViewChild('frm121dias', { static: true }) frm121dias: TemplateRef<any>;
  @ViewChild('frm151dias', { static: true }) frm151dias: TemplateRef<any>;
  @ViewChild('frm181dias', { static: true }) frm181dias: TemplateRef<any>;
  
  CardCode:string;
  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService,
    private modalService: NgbModal,) { }

  async ngOnInit(): Promise<void> {

  const d = new Date();
  let fecha:string = d.getFullYear().toString()  + (d.getMonth() +1).toString().padStart(2, "0")  + d.getDate().toString().padStart(2, "0") ;
  
  console.log("ccode: "+this.CardCode);
  console.log("fecha: "+fecha);
  // (await query).replace("FECHA",day);
  let query:string = await this.obtieneQueryMongo("CUENTASPENDIENTES");
  for (let index = 0; index < 20; index++) {
    query = query.replace("INPUTCLIENTE",this.CardCode);
    query = query.replace("INPUTFECHA",fecha);
  }
  
  this.columnsVencidos=[
      { key: 'Tipo Trans', title: "TipoTrans"},
      { key: 'TransType', title: 'TransType' },
      { key: 'N Asiento', title: 'NAsiento' },
      { key: 'Número Factura', title: 'NúmeroFactura' },
      { key: 'Prefijo DTE', title: 'PrefijoDTE' },
      { key: 'Código SN', title: 'CódigoSN'},
      { key: 'RUT', title: 'RUT'},
      { key: 'Cliente', title: 'Cliente' },
      { key: 'Fechaemision', title: 'Fecha Emision',cellTemplate:this.frmFechaemision },
      { key: 'Fechavenc', title: 'Fecha Venc',cellTemplate:this.frmFechaemision  },
      { key: 'Dias Calle', title: 'Días Calle' },
      { key: 'Dias Atraso', title: 'Dias Atraso' },
      { key: 'TotalFactura', title: 'Total Factura',cellTemplate:this.frmTotalFactura },
      { key: 'Abonos', title: 'Abonos' },
      { key: 'SaldoPorPagar', title: 'Saldo Por Pagar' },
      { key: 'CondicionesPago', title: 'Condiciones Pago' },
      { key: 'LineaDeCrédito', title: 'Linea De Crédito' },
      { key: 'Vendedor', title: 'Vendedor' },
      { key: 'Comentarios', title: 'Comentarios' },
      { key: 'Teléfono', title: 'Teléfono' },
      { key: 'Persona Contacto', title: 'Persona Contacto' },
      { key: 'Mail', title: 'Mail' },
      { key: 'PorVencer', title: 'Por Vencer' },
      { key: 'V1_15', title: 'V 1-15',cellTemplate:this.frm15dias  },
      { key: 'V16_30', title: 'V 16-30',cellTemplate:this.frm61dias  },
      { key: 'V31_60', title: 'V 31-60',cellTemplate:this.frm31dias  },
      { key: 'V61_90', title: 'V 61-90' ,cellTemplate:this.frm61dias },
      { key: 'V91_120', title: 'V 91-120',cellTemplate:this.frm91dias  },
      { key: 'V121_150', title: 'V 121-150',cellTemplate:this.frm121dias  },
      { key: 'V151_180', title: 'V 151-180',cellTemplate:this.frm151dias  },
      { key: 'V_180', title: 'V Más De 180',cellTemplate:this.frm181dias  }

  ];
  let objeto:any = await this._api.GetNotFileQuery(query);
    // console.log(objeto.objeto);
    
    this.dataVencidos = objeto.objeto;
    this.configurationVencidos = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled:true,
      horizontalScroll: true,
      resizeColumn: true,
      orderEnabled: false,
      rowReorder:false,
      columnReorder:false,
      fixedColumnWidth: true,
   };

   //SALDOS
   query="";
  query = await this.obtieneQueryMongo("SALDOCUENTA");
  query = query.replace("ClienteInput",this.CardCode);
  query = query.replace("FECHA",fecha);
  this.columnsSaldos=[
    { key: 'TransId', title: "TransId"},
    { key: 'RefDate', title: "RefDate", cellTemplate: this.frmRefDate},
    { key: 'TransType', title: "TransType"},
    { key: 'BaseRef', title: "BaseRef"},
    { key: 'ShortName', title: "ShortName"},
    { key: 'CardName', title: "CardName"},
    { key: 'Account', title: "Account"},
    { key: 'ContraAct', title: "ContraAct"},
    { key: 'CreatedBy', title: "CreatedBy"},
    { key: 'Debit', title: "Debit", cellTemplate: this.frmDebit},
    { key: 'Credit', title: "Credit", cellTemplate: this.frmCredit},
    { key: 'BalDueDeb', title: "BalDueDeb", cellTemplate: this.frmBalDueDeb},
    { key: 'BalDueCred', title: "BalDueCred", cellTemplate: this.frmBalDueCred},
    { key: 'LineMemo', title: "LineMemo"},    
  ];  
  objeto = await this._api.GetNotFileQuery(query);
  
  this.dataSaldos = objeto.objeto;
  this.configurationSaldos = { 
    ...DefaultConfig,
    searchEnabled: true,
    detailsTemplate: true,
    paginationRangeEnabled: true,
    paginationEnabled:true,
    horizontalScroll: true,
    resizeColumn: true,
    orderEnabled: false,
    rowReorder:false,
    columnReorder:false,
    fixedColumnWidth: true,
   };

   if(variableGlobal.ESTILO_EASY_TABLE)
    this.setClass(variableGlobal.ESTILO_EASY_TABLE);
  }
  cerrar() {
    this.activeModal.close();
  }
  async replaceAll(str : string, find:string, replace:string) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace).toString();
}
  
  async obtieneQueryMongo(nombre)
  {
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
    this.table2.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  
 
}
