import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {IntranetoldService} from '../../../app/api/intranetold.service';

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.css']
})
export class GraficosComponent implements OnInit {

  constructor(
    private _api: IntranetoldService
  ) { }

  ngOnInit(): void {
    // Create chart instance
    am4core.useTheme(am4themes_animated);

    this.cargaInicial();

    // Add data
    

    
    // Add data
    // chart.data = this.itemsCategoria;

    // chart.data = ;
    // for (var row of this.itemsCategoria) {
    //   var jsonData = {
    //     "grupo": row.ItmsGrpNam,
    //     "cantidad": row.Cantidad
    //   }
    //   chart.data.push(jsonData);
    // }
    // console.log("CHARTDATA:" +chart.data);
    // chart.data = [
    // {
    //   "country": "Lithuania",
    //   "litres": 501.9
    // }, {
    //   "country": "Czech Republic",
    //   "litres": 301.9
    // }, {
    //   "country": "Ireland",
    //   "litres": 201.1
    // }, {
    //   "country": "Germany",
    //   "litres": 165.8
    // }, {
    //   "country": "Australia",
    //   "litres": 139.9
    // }, {
    //   "country": "Austria",
    //   "litres": 128.3
    // }, {
    //   "country": "UK",
    //   "litres": 99
    // }, {
    //   "country": "Belgium",
    //   "litres": 60
    // }, {
    //   "country": "The Netherlands",
    //   "litres": 50
    // }];


    

  }


  costoNeto: any;
  itemsCategoria: any;
  dataChartPie: any;
  queryVendedorCantidad: any;
  queryVendedorCantidadNumero: any;
  queryVentasPorVendedor: any;

  async cargaInicial() {
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "queryCostos1") {
        query = element.CONSULTA;
      }
    });
    var dato:any = await this._api.GetNotFileQuery(query);
    this.costoNeto = [...dato.objeto];
    this.costoNeto = Object.values(this.costoNeto[0]);
    // console.log(this.costoNeto);

    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "queryOITMTest") {
        query = element.CONSULTA;
      }
    });
    var dato:any = await this._api.GetNotFileQuery(query);
    this.itemsCategoria = [...dato.objeto];
    this.itemsCategoria = Object.values(this.itemsCategoria);


    data = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "queryVendedorCantidad") {
        query = element.CONSULTA;
      }
    });
    dato = await this._api.GetNotFileQuery(query);
    this.queryVendedorCantidad = [...dato.objeto];
    this.queryVendedorCantidad = Object.values(this.queryVendedorCantidad);
    console.log(this.queryVendedorCantidad);


    data = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "QueryVentasPorVendedor") {
        query = element.CONSULTA;
      }
    });
    dato = await this._api.GetNotFileQuery(query);
    this.queryVentasPorVendedor = [...dato.objeto];
    this.queryVentasPorVendedor = Object.values(this.queryVentasPorVendedor);
    console.log(this.queryVentasPorVendedor);

    
    
    var charPie = am4core.create("chartdiv", am4charts.PieChart);

    charPie.data = [];
    for (var row of this.itemsCategoria) {
      var jsonPie = {
        "grupo": row.ItmsGrpNam,
        "cantidad": row.Cantidad
      }
      charPie.data.push(jsonPie);
    }
    var pieSeries = charPie.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "cantidad";
    pieSeries.dataFields.category = "grupo";
    pieSeries.dataFields.hidden = "hidden";
    pieSeries.slices.template.propertyFields.fill = "color";
    // Let's cut a hole in our Pie chart the size of 40% the radius
    charPie.innerRadius = am4core.percent(40);

    // Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = false;
    pieSeries.slices.template.stroke = am4core.color("#0F121A");

    // Disable tooltips
    pieSeries.slices.template.tooltipText = "";

    // Add a legend
    charPie.legend = new am4charts.Legend();
    charPie.legend.position = "right";
    charPie.legend.scrollable = true;


    var chartBar = am4core.create("chartBar", am4charts.XYChart3D);

    chartBar.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];

    // Create axes
    var categoryAxis = chartBar.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";

    var valueAxis = chartBar.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    // Create series
    var series = chartBar.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
    series.name = "Sales";
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    var series2 = chartBar.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "units";
    series2.dataFields.categoryX = "country";
    series2.name = "Units";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";

    // Add cursor
    chartBar.cursor = new am4charts.XYCursor();
  
    // await data.objeto.forEach(element => {
    //   if (element.NOMBRE == "queryIngresos1") {
    //     query = element.CONSULTA;
    //   }
    // });
    // dato = await this._api.GetNotFileQuery(query);
    // this.ingresosTotal = [...dato.objeto];
    // this.ingresosTotal = Object.values(this.ingresosTotal[0]);

  }
}
