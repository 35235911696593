import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MongoService } from "./../../../api/mongo.service"
import { DOCUMENTO_MARKETING_PRELIMINAL } from "./../../../model/Mongo"
import { variableGlobal } from 'src/app/global.enum';
import {DetalleDocumentoPreliminalComponent} from "./detalleDocumentoPreliminal/detalleDocumentoPreliminal.component"
import {VerAprobacionComponent} from "./verAprobacion/verAprobacion.component"

@Component({
  selector: 'app-documentoPreliminal',
  templateUrl: './documentoPreliminal.component.html',
  styleUrls: ['./documentoPreliminal.component.sass']
})
export class DocumentoPreliminalComponent implements OnInit {


  constructor(
    private _apiMongo: MongoService,
    private modalService: NgbModal,
  ) { }
  public configuration: Config;
  public columns: Columns[];
  data: [DOCUMENTO_MARKETING_PRELIMINAL];
  objetosSAP:any;
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmObjetoSAP', { static: true }) frmObjetoSAP: TemplateRef<any>;
  @ViewChild('frmNumerointerno', { static: true }) frmNumerointerno: TemplateRef<any>;

  @ViewChild('frmFechaCreacion', { static: true }) frmFechaCreacion: TemplateRef<any>;
  @ViewChild('frmEstado', { static: true }) frmEstado: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;

  ngOnInit() {
    this.cargaInicial();

  }

  async cargaInicial() {
    this.columns = [
      { key: 'ObjType', title: "Tipo", cellTemplate: this.frmObjetoSAP },
      { key: 'numeracionINDESAD', title: 'N° Interno', cellTemplate: this.frmNumerointerno },
      { key: 'Documento.CardName', title: 'Cliente' },
      { key: 'FECHA', title: 'Fecha creación', cellTemplate: this.frmFechaCreacion },
      { key: 'usuario.NOMBRE', title: 'Usuario' },
      { key: 'APROBADO', title: 'Estado', cellTemplate: this.frmEstado },
      //{ key: '', title: 'Pen. por aprobar' },
      //{ key: '', title: 'Modelo aprobación' },
      //{ key: '', title: 'Aprobador' },
      { key: '', title: 'Acción', cellTemplate: this.frmAccion }
    ];

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
    };


    var datos: any = await this._apiMongo.GetDocumentosPreliminares();
    console.log("DATOS");

    console.log(datos)
    this.data = datos.resultado;

    var datos:any = await this._apiMongo.getObjetosSAP();
    this.objetosSAP = datos.resultado;
    console.log(this.objetosSAP);





    console.log(this.data);
    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  buscaObjetoSAP(row:any)
  {
    var objtype2:string = row.ObjType;
    //console.log(objtype2);
    var filtro = this.objetosSAP.filter(({ObjType}) => ObjType == objtype2.toString());
    //console.log(filtro[0].DESCRIPCION);
    var respuesta:string = filtro[0].DESCRIPCION;
    //var respuesta:string = "oals";
    return respuesta;
  }
  async AbrirDetalle(row)
  {


    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "lg",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(DetalleDocumentoPreliminalComponent, ngbModalOptions);
    modalRef.componentInstance.documento = row.Documento;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((datos) => {

    }, (reason) => {
      //this.editar(item);
    });
  }
  EditarDocumento(row)
  {
    var objtype2:string = row.ObjType;
    var filtro = this.objetosSAP.filter(({ObjType}) => ObjType == objtype2.toString());
    var ORIGEN:string = filtro[0].ORIGEN;
    var url = "/Venta/";
    if(ORIGEN == "COMPRA")
    {
      url = "/Compra/";
    }
    var obtetoDraf = "9000" + objtype2;
    url = url + obtetoDraf + "/" + row.numeracionINDESAD;
    window.location.href = url;
  }
  async VerAprobacion(row)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "lg",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(VerAprobacionComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((datos) => {

    }, (reason) => {
      //this.editar(item);
    });
  }
  verEstado(row)
  {
    var respuesta:string = "Pendiente";
    if (row.APROBADO) {
      respuesta = "Aprobado";
    }
    if (row.RECHAZADO) {
      respuesta = "Rechazado";
    }
    //console.log(row);
    return respuesta;
  }
}
