import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Template, variableGlobal} from './global.enum'
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import Swal from 'sweetalert2'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CambioPwComponent } from './login/cambio-pw/cambio-pw.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})



export class AppComponent implements OnInit {

  constructor(

    private _cookieService: CookieService,
    private _route: Router,
    private Location:Location,
    private _dbService: NgxIndexedDBService,
    private modalService: NgbModal
  ) {


  }


  //title = 'intranet';
  login = false;


    template = Template;
  _template = "";
  _templateV2 = "";
  general = variableGlobal;
  ngOnInit(): void {

    var currentURL = this.Location.path();

    this._templateV2 = Template.NORMAL;

    if (currentURL.includes("/Layoult/Venta") ) {
      this._template = Template.LOGIN;
    }
    else if (currentURL.includes("/iframe") ) {
      //this._templateV2 = "IFRAME";
    }
    else if (currentURL.includes("/WMSEntregable") ) {
      //this._templateV2 = "IFRAME";
      this._template = Template.LOGIN;
    }
    else
    {

      if (this._cookieService.check("template")) {
        this._template = this._cookieService.get("template");
      }
      else
      {
        this._cookieService.set("template", Template.LOGIN);
        this._template = Template.LOGIN;
      }



      if (this._template == Template.LOGIN) {
        this._route.navigate(["/login"]);
      }
      else if (this._template == Template.NORMAL) {


      }
      if (!currentURL || currentURL == "/login") {
        //
        this._cookieService.set("template", Template.LOGIN);
        this._template = Template.LOGIN;
      }
    }



    //this.limitaAccesoPaginaUsuario();



  }

  async limitaAccesoPaginaUsuario()
  {
    var currentURL = this.Location.path();

    var login:any;
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      loginx => {
        login = loginx;

      });





    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).subscribe(
      menu => {
        var admin = login.datos.login.ADMIN;
        if (!admin) {

        var manux:any = menu;
        var bloqueaPagina = true;
        manux.datos.forEach(element => {
          element.HIJOS.forEach(element2 => {
            if (currentURL.includes(element2.URL)) {
              bloqueaPagina = false;
            }

          });
        });


        if (!currentURL.includes("Layoult")) {
          if (!currentURL.includes("inicio")) {


            if (bloqueaPagina && !admin) {

              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Usted no tiene permisos para trabajar en esta pagina, sera redirigido',
                showConfirmButton: false,
                timer: 3000
              })

              setTimeout(() => {

                this._route.navigate(["/inicio"]);
              }, 3000);

            }
          }

        }
      }

      }
    );






  }

}

