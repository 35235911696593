import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MongoService } from 'src/app/api/mongo.service';
import { variableGlobal } from 'src/app/global.enum';
import { CrearEditarMetaComponent } from './crear-editar-meta/crear-editar-meta.component';


@Component({
  selector: 'app-metas-crud',
  templateUrl: './metas-crud.component.html',
  styleUrls: ['./metas-crud.component.css']
})
export class MetasCRUDComponent implements OnInit {

  constructor(private modalService: NgbModal,private _apiMongo: MongoService) { }

  jsonMetas:any;
  mes:any;
  anio:any;
  metas:any;
  allmetas:any;

  async CargaInicial(){

    this.allmetas = await this._apiMongo.getAllMetas();
    console.log("ALL METAS",this.allmetas);

  }
  ngOnInit() {

    this.CargaInicial();

    this.metas = [
      {
        SlpCode:"15",
        Concepto:"META",
        Nombre:"Alan Cerda",
        Mes:"9",
        Anio:"2022",
        Valor:"123123"
      },
      {
        SlpCode:"15",
        Concepto:"META",
        Nombre:"Leo Carreras",
        Mes:"9",
        Anio:"2022",
        Valor:"123123"
      },
      {
        SlpCode:"15",
        Concepto:"META",
        Nombre:"Francisco Bernales",
        Mes:"9",
        Anio:"2022",
        Valor:"123123"
      },
    ]


  }



  async crearMeta() {

    let body = {
      SlpCode:"",
      Concepto:"",
      Nombre:"",
      Mes:"",
      Anio:"",
      Valor:""
    }

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };



    const modalRef = this.modalService.open(CrearEditarMetaComponent, ngbModalOptions);
    modalRef.componentInstance.data = body;
    modalRef.componentInstance.modalReference = modalRef;



  }

  async editarMeta(row) {
    console.log(row);

    let body = {
      _id:row._id,
      SlpCode:row.SlpCode,
      CONCEPTO:row.CONCEPTO,
      NOMBRE:row.NOMBRE,
      MES:row.MES,
      ANIO:row.ANIO,
      VALOR:row.VALOR
    }

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };



    const modalRef = this.modalService.open(CrearEditarMetaComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;



  }

  async eliminarMeta(row){

    await this._apiMongo.eliminarMeta(row);
    window.location.reload();

  }




}
