<div class="modal-header">
  <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
    <p>Detalle OV ({{detalleov}})</p>
  </h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="row container-botton">


<div class="col-12">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Código</th>
        <th scope="col">Cantidad Solicitada</th>
        <th scope="col">Cantidad Pickeada</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{data.ItemCode}}</td>
        <td>{{data.OpenQuantity}}</td>
        <td>{{data.Pickeada}}</td>
      </tr>
    </tbody>
  </table>
</div>


  <div class="col-12 detalleov-comentario">
    <p class="negrita">Comentario:</p>
    <p>reservar no despachar aún</p>
  </div>

  <div class="col-4" *ngIf="TituloAccion == 'WMSCompra'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-upc-scan"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
            />
          </svg>
        </span>
      </div>
      <input
        type="text"
        class="form-control"
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
        style="
          opacity: 1;
          position: initial;
          font-family: 'Roboto';
          font-size: 27px;
          height: 100%;
        "
        [(ngModel)]="data.ItemCode"
        (keyup.enter)="keyFunc($event)"
        disabled

      />
    </div>
  </div>
  
  <div class="col-4" *ngIf="TituloAccion == 'WMSCompra'">
    <div style="display: flex;">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <i class="fa-solid fa-location-dot" style="font-size: 29px;"></i>
        </span>
      </div>

      <select [(ngModel)]="ubicompra" style="width: 100%;">
        <option disabled selected [value]="undefined">Selecciona una ubicación</option>
        <option *ngFor="let item of ubicaciones" value={{item}}>{{item.BinCode}}</option>

      </select>
      

    </div>

  </div>

  <div class="col-4" style="display: flex;justify-content: center;" *ngIf="TituloAccion == 'WMSCompra'">

    <div class="form-check">
      <input (click)="loteseriechecked('lote')" [(ngModel)]="lotechecked" class="form-check-input checkbox-input" type="checkbox">
      <label class="form-check-label checkbox-text" for="flexCheckDefault">
        LOTE
      </label>
    </div>

    <div class="form-check">
      <input (click)="loteseriechecked('serie')" [(ngModel)]="seriechecked" class="form-check-input checkbox-input" type="checkbox">
      <label class="form-check-label checkbox-text" >
        SERIE
      </label>
    </div>

  </div>



  <!-- LOTE -->


  <div class="col-4" *ngIf="lotechecked && TituloAccion == 'WMSCompra'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-box-seam-fill"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
            />
          </svg>
        </span>
      </div>
      <input
        [(ngModel)]="lotecompraobj.CodigoLoteSerie"
        type="text"
        id="pickingQuantity"
        min="1"
        step="0.01"
        class="form-control"
        autocomplete="off"
        placeholder="Código Lote"
        style=
        "
          opacity: 1;
          position: initial;
          font-family: 'Roboto';
          font-size: 27px;
          height: 100%">

    </div>
  </div>


  <div class="col-4" *ngIf="lotechecked && TituloAccion == 'WMSCompra'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-box-seam-fill"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
            />
          </svg>
        </span>
      </div>
      <input
        [(ngModel)]="data.Pickeada"
        type="text"
        id="pickingQuantity"
        min="1"
        step="0.01"
        class="form-control"
        autocomplete="off"
        placeholder="Cantidad"
        style="
          opacity: 1;
          position: initial;
          font-family: 'Roboto';
          font-size: 27px;
          height: 100%
        "          
        >

    </div>
  </div>


  <!-- LOTE FIN-->


  <!-- SERIE -->

  <div class="col-4" *ngIf="seriechecked && TituloAccion == 'WMSCompra'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-box-seam-fill"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
            />
          </svg>
        </span>
      </div>
      <input
      [(ngModel)]="seriecompraobj.CodigoLoteSerie"
        type="text"
        id="pickingQuantity"
        min="1"
        step="0.01"
        class="form-control"
        autocomplete="off"
        placeholder="Código Serie"
        style="
          opacity: 1;
          position: initial;
          font-family: 'Roboto';
          font-size: 27px;
          height: 100%
        "          
        >

    </div>
  </div>

  <div class="col-4" *ngIf="seriechecked && TituloAccion == 'WMSCompra'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-box-seam-fill"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"
            />
          </svg>
        </span>
      </div>
      <input

        [(ngModel)]="seriecompraobj.Pickeada"
        type="text"
        id="pickingQuantity"
        min="1"
        step="0.01"
        class="form-control"
        autocomplete="off"
        value="1"
        disabled
        style="
          opacity: 1;
          position: initial;
          font-family: 'Roboto';
          font-size: 27px;
          height: 100%
        "          
        >

    </div>
  </div>


  <!-- SERIE FIN-->

  <div class="col-12" style="display: flex;justify-content: center; margin-bottom: 10px;" *ngIf="TituloAccion == 'WMSCompra'">

    <button (click)="PickearCompra()" type="button" class="btn btn-danger" style="background: #73ac66;border: 1px solid #73ac66;margin-left: 5px;">Pickear
    </button>

  </div>








  <div class="col-6 responsive" *ngIf="this.data.BinLocationActive && TituloAccion != 'WMSCompra'">


    <mat-expansion-panel >
      <mat-expansion-panel-header>

        <mat-panel-title>
          Ubicaciones disponibles
        </mat-panel-title>

      </mat-expansion-panel-header>
      
      <table class="table">

        <thead>
          <tr>
            <th scope="col" *ngIf="this.data.ManBtchNum">Lote</th>
            <th scope="col" *ngIf="this.data.ManSerNum">Serie</th>
            <th scope="col">Ubicación</th>
            <th scope="col">Cantidad</th>
            <th scope="col" *ngIf="!this.data.ManBtchNum && !this.data.ManSerNum">Pickear</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let ubi of data.UBICACION_OnHand">
            <td *ngIf="this.data.ManBtchNum || this.data.ManSerNum">
              <div class="boton-scanner" (click)="pistoleoubicacion(ubi)">

                  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="30" fill="#fff" class="bi bi-geo-alt" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                </div>
            </td>
            <td>{{ubi.BinCode}}</td>
            <td>{{ubi.Quantity}}</td>
            <td *ngIf="!this.data.ManBtchNum && !this.data.ManSerNum">
              <input [(ngModel)]="ubi.Pickeada" (blur)="pickeoServicio()" type="number" style="width: 50px;">
            </td>
          </tr>
        </tbody>

      </table>
      
    </mat-expansion-panel>
  </div>

  <div class="col-6 responsive"  *ngIf="this.data.BinLocationActive && TituloAccion != 'WMSCompra'">

    <div style="width: 100%;display: flex;justify-content: center;margin-top: 5px;">
      <p style="font-size: 30px; font-weight: bold;">Pickeado</p>
    </div>

    <div style="width: 100%;display: flex">

      <table class="table">

        <thead>
          <tr>
            <th scope="col">Ubicación
              <input type="text" placeholder="filtrar" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()" style="width:50%;border-radius: 5px;">
            </th>
            <th scope="col">Cantidad</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of data.UBICACION">
            <td>{{row.BinCode}}</td>
            <td>{{row.Pickeada}}</td>

          </tr>
        </tbody>

      </table>
    </div>
  </div>

  <div class="col-6 responsive"  *ngIf="!this.data.BinLocationActive && (this.data.ManBtchNum || this.data.ManSerNum) && TituloAccion != 'WMSCompra'">
      <mat-expansion-panel >
        <mat-expansion-panel-header>

          <mat-panel-title>
            Titulo Tabla
          </mat-panel-title>
        </mat-expansion-panel-header>

        <table class="table">

          <thead>
            <tr>
              <th scope="col" *ngIf="this.data.ManSerNum">Pickear</th>
              <th scope="col" *ngIf="this.data.ManBtchNum">LOTE</th>
              <th scope="col" *ngIf="this.data.ManSerNum">Serie</th>
              <th scope="col">Cantidad</th>
              <th scope="col" *ngIf="this.data.ManBtchNum">Pickeando</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let ubi of data.LOTE_OnHand">
              <td>
                {{ubi.CodigoLoteSerie}}
              </td>
              <td>{{ubi.Quantity}}</td>
              <td>
                <input [(ngModel)]="ubi.Pickeada" (blur)="pickeoServicio()" type="number" style="width: 50px;">
              </td>
            </tr>
            <tr *ngFor="let ubi of data.SERIE_OnHand">
              <td>
                {{ubi.CodigoLoteSerie}}
              </td>
              <td>
                <input type="checkbox" class="form-control" [(ngModel)]="ubi.Checked" (change)="PasarSeriesLote()">
              </td>
              <td>{{ubi.Quantity}}</td>
            </tr>
          </tbody>

        </table>
        
      </mat-expansion-panel>


  </div>

  <div class="col-6 responsive" *ngIf="!this.data.BinLocationActive && (this.data.ManBtchNum || this.data.ManSerNum) && TituloAccion != 'WMSCompra'">

    <div style="width: 100%;display: flex;justify-content: center;margin-top: 5px;">
      <p style="font-size: 30px; font-weight: bold;">Pickeado</p>
    </div>

    <div style="width: 100%;display: flex">

      <table class="table">

        <thead>
          <tr>
            <th scope="col">Código de LOTE 
              <input type="text" placeholder="filtrar" [(ngModel)]="searchCodigoLoteSerie" (keyup)="search()" style="width:50%;border-radius: 5px;">
            </th>
            <th scope="col">Cantidad</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of this.data.LOTE">
            <td>{{row.BatchNumber}}</td>
            <td>{{row.Pickeada}}</td>

          </tr>
        </tbody>

      </table>


    </div>



  </div>

  <div class="col-12 responsive " *ngIf="TituloAccion == 'WMSCompra'">

    <mat-expansion-panel style="width: 100%;margin-bottom: 10px;">
      <mat-expansion-panel-header>

        <mat-panel-title>
          Pickeado
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table class="table">

        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Tipo</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Ubicación</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              x
            </td>
            <td>
              x
            </td>
            <td>
              x
            </td>
            <td>
              x
            </td>
          </tr>

        </tbody>

      </table>
      
    </mat-expansion-panel>


  </div>








</div>