<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/duyplus/fontawesome-pro/css/all.min.css">

<div class="alert alert-success" *ngIf="mostrarAlerta">
  <i class="fa-regular fa-circle-check" style="color: #ffffff;font-size: 24px;padding-right: 10px;"></i>
  <strong>{{mensajeAlerta}}</strong>
</div>

<div class="modal-header">
  <h4 class="modal-title titulo-notadeventa" id="modal-basic-title">
    <p>Detalle picking OV({{ DocNum }})</p>
  </h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row" style="margin-top: 46px">

  <div class="col-12 titulo-pickingv2">
    <p>Productos para picking</p>
  </div>

  <mat-drawer-container class="example-container" autosize style="height: 100vh">

    <mat-drawer #drawer class="example-sidenav mat-drawer-opened" mode="side" style="height: 100vh;padding-left: 0px;padding-right: 0px;">

      <div class="row">
        <div class="col-12 header-close">
          <button (click)="togglerModal()" type="button" class="btn btn-danger" style="background: #293F68;border: 1px solid #293F68;margin-left: 5px;">Volver</button>
        </div>


        <div class="col-12 pistoleo-rapido">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" (click)="elijeLinea()" >
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-regular fa-scanner-gun icono-gr-input"></i>
              </span>
            </div>
            <input [(ngModel)]="codigoMultiple" (blur)="capturaCualquierCodigo()" placeholder="Código/Ubicacón/Lote/Serie" type="text" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
              style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
              ng-reflect-is-disabled="" ng-reflect-model="INDESAD">
          </div>
        </div>

        <div *ngIf="this.detalleSeleccionado" style="width: 100%;">

        <div class="col-12 pistoleo-rapido" *ngIf="this.detalleSeleccionado">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-duotone fa-barcode-read icono-gr-input"></i>
              </span>
            </div>
            <input [(ngModel)]="ItemCode" (focus)="limpiaItem()"   placeholder="Código articulo" type="text" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
              style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
              ng-reflect-is-disabled="" ng-reflect-model="INDESAD">
          </div>
        </div>

        <div class="col-12 pistoleo-rapido" *ngIf="this.detalleSeleccionado.BinLocationActive">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" (click)="elijeUbicacion()">
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-duotone fa-location-dot icono-gr-input"></i>
              </span>
            </div>
            <input [(ngModel)]="Ubicacion" (blur)="comparaUbicacion()" (focus)="limpiUbicacion()" placeholder="Ubicación" type="text" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
              style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
              ng-reflect-is-disabled="" ng-reflect-model="INDESAD">
          </div>
        </div>

        <div class="col-12 pistoleo-rapido" *ngIf="this.detalleSeleccionado.ManSerNum">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" (click)="seleccionarSerieLote()">
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-duotone fa-barcode icono-gr-input"></i>
              </span>
            </div>
            <input [(ngModel)]="Serie" (blur)="compraValidaLoteSerie('SERIE')" (focus)="limpiaSerie()" placeholder="Serie" type="text" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
              style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
              ng-reflect-is-disabled="" ng-reflect-model="INDESAD">
          </div>
        </div>
        <div class="col-12 pistoleo-rapido" (focus)="limpiaLote()" *ngIf="this.detalleSeleccionado.ManBtchNum">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" (click)="seleccionarSerieLote()">
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-regular fa-scanner-gun icono-gr-input"></i>
              </span>
            </div>

            <input [(ngModel)]="Lote" (blur)="compraValidaLoteSerie('LOTE')" placeholder="Lote" type="text" aria-label="Small"
            aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
            style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
            ng-reflect-is-disabled="" ng-reflect-model="INDESAD">


          </div>
        </div>

        <div class="col-12 pistoleo-rapido" *ngIf="this.detalleSeleccionado && !this.detalleSeleccionado.ManSerNum">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span id="inputGroup-sizing-sm" class="input-group-text" style="background: #293F68;">
                <i class="fa-duotone fa-boxes-stacked icono-gr-input"></i>
              </span>
            </div>
            <input [(ngModel)]="Cantidad" (focus)="limpiaCantidad()" (blur)="pickeaCantidad()"  placeholder="Ingrese cantidad a pickear" type="number" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" class="form-control ng-untouched ng-pristine"
              style="opacity: 1; position: initial; font-family: Roboto; font-size: 27px; height: 100%;background: white !important;"
              ng-reflect-is-disabled="" ng-reflect-model="INDESAD">
          </div>
        </div>

      </div>

        <div class="col-12 pistoleo-rapido" *ngIf="this.detalleSeleccionado">
          <mat-list>
            <mat-list-item>
              <i class="fa-duotone fa-barcode icono-enlista"></i>
              Código: {{this.detalleSeleccionado.ItemCode}}
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
              <i class="fa-duotone fa-file-lines icono-enlista"></i>
              Descripción: {{this.detalleSeleccionado.ItemName}}
            </mat-list-item>
            <mat-divider *ngIf="WMSaccion != 'WMSCompra'"></mat-divider>
            <mat-list-item *ngIf="WMSaccion != 'WMSCompra'">
              <i class="fa-duotone fa-shelves icono-enlista"></i>
              Stock: {{this.detalleSeleccionado.Quantity}}
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-divider *ngIf="this.existeLote"></mat-divider>
            <mat-list-item  *ngIf="this.existeLote" >
              <div *ngFor="let row of this.detalleSeleccionado.LOTE_OnHand">
                <div *ngIf="row.CodigoLoteSerie == this.Lote">
                  <i class="fa-duotone fa-shelves icono-enlista"></i>Stock en Lote: {{row.Quantity}}
                </div>
              </div>

            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-divider *ngIf="this.existeLote"></mat-divider>
            <mat-list-item  *ngIf="this.existeLote" >
              <div *ngFor="let row of this.detalleSeleccionado.LOTE">
                <div *ngIf="row.CodigoLoteSerie == this.Lote">
                  <i class="fa-duotone fa-shelves icono-enlista"></i>Stock pickeado en lote: {{row.Pickeada}}
                </div>
              </div>

            </mat-list-item>
            <mat-list-item>
              <i class="fa-duotone fa-list-ul icono-enlista"></i>
              Cantidad Solicitada: {{this.detalleSeleccionado.OpenQuantity}}
            </mat-list-item>

            <mat-divider></mat-divider>
            <mat-list-item>
              <i class="fa-duotone fa-list-check icono-enlista"></i>
              Cantidad Pickeada: {{this.detalleSeleccionado.Pickeada}}

              <div class="picking-estado-rapido">
                <i (click)="infopicking(this.detalleSeleccionado)" *ngIf="this.detalleSeleccionado.Pickeada > 0 && this.detalleSeleccionado.Pickeada < this.detalleSeleccionado.OpenQuantity" class="fa-solid fa-circle-exclamation picking-falta"></i>
                <i (click)="infopicking(this.detalleSeleccionado)" *ngIf="this.detalleSeleccionado.Pickeada == this.detalleSeleccionado.OpenQuantity" class="fa-solid fa-circle-check picking-check"></i>
                <i (click)="infopicking(this.detalleSeleccionado)" *ngIf="this.detalleSeleccionado.Pickeada > this.detalleSeleccionado.OpenQuantity" class="fa-solid fa-bell fa-shake picking-demas"></i>
              </div>

            </mat-list-item>
          </mat-list>
        </div>

        <div class="col-12 header-close">

          <button (click)="limpiar()" type="boton-pistoleo-rapido" class="btn btn-danger" style="background: #293F68;border: 1px solid #293F68;margin-left: 5px;margin-top: 5px;margin-bottom: 5px;">
            <i class="fa-duotone fa-box-check"></i>
            limpiar
          </button>

        </div>

      </div>

    </mat-drawer>

    <div class="example-sidenav-content">


      <div class="row">

        <div class="col-12">
          <button *ngIf="!Detalle" type="carga" class="btn btn-danger" style="background: #293F68;border: 1px solid #293F68;margin-left: 5px;margin-top: 5px;margin-bottom: 5px;">
            <i class="fal fa-spinner fa-fw fa-xl margin-right-md fa-spin"></i>
          </button>
        </div>

        <div class="col-12">

          <button *ngIf="Detalle" (click)="togglerModal()" type="boton-pistoleo-rapido" class="btn btn-danger boton-pistoleo-rapido" style="display: flex;align-items: center;padding-right: 5px;">
            <i class="fa-regular fa-scanner-gun" style="font-size: 37px;margin-right: 25px;"></i>
            Pistoleo Rápido
          </button>

        </div>

        <div class="col-12" style="display: flex; margin-bottom: 5px" *ngFor="let row of Detalle">


          <!--

          <div class="boton-scanner" (click)="Pistoleo(row)">

          -->
          <div class="boton-scanner">
            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" fill="#fff" class="bi bi-upc-scan"
              viewBox="0 0 16 16">
              <path
                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
            </svg>
          </div>

          <mat-accordion class="example-headers-align" multi style="width: 100%">

            <div class="picking-estado">


              <i (click)="infopicking(row)" *ngIf="row.Pickeada > 0 && row.Pickeada < row.OpenQuantity" class="fa-solid fa-circle-exclamation picking-falta"></i>
              <i (click)="infopicking(row)" *ngIf="row.Pickeada == row.OpenQuantity" class="fa-solid fa-circle-check picking-check"></i>
              <i (click)="infopicking(row)" *ngIf="row.Pickeada > row.OpenQuantity" class="fa-solid fa-bell fa-shake picking-demas"></i>
            </div>

            <mat-expansion-panel style="color: black !important;">

              <mat-expansion-panel-header>
                <mat-panel-title>
                  Articulo: {{ row.ItemCode }}
                </mat-panel-title>
                <mat-panel-description>
                  <!--Descripción!-->
                  {{ row.ItemName }}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row columna-no-responsive">
                <div class="col columna">
                  <div class="columna-titulo">Artículo</div>
                </div>

                <div class="col columna">
                  <div class="columna-titulo">Descripción</div>
                </div>

                <div class="col columna">
                  <div class="columna-titulo">Bodega</div>
                </div>

                <div class="col columna">
                  <div class="columna-titulo">Stock</div>
                </div>

                <div class="col columna">
                  <div class="columna-titulo">Cantidad Solicitada</div>
                </div>

                <div class="col columna">
                  <div class="columna-titulo">Cantidad Pickeada</div>
                </div>
              </div>

              <div class="row columna-no-responsive">
                <div class="col columna">
                  <div class="columna-descripcion">{{ row.ItemCode }}</div>
                </div>

                <div class="col columna">
                  <div class="columna-descripcion">{{ row.ItemName }}</div>
                </div>

                <div class="col columna">
                  <div class="columna-descripcion">{{ row.WhsCode }}</div>
                </div>

                <div class="col columna">
                  <div class="columna-descripcion">{{ row.OnHand }}</div>
                </div>

                <div class="col columna">
                  <div class="columna-descripcion">{{ row.OpenQuantity }}</div>
                </div>

                <div class="col columna">
                  <div class="columna-descripcion">{{ row.Pickeada }}</div>
                </div>
              </div>

              <div class="row columna-responsive">
                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Artículo</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">{{ row.ItemCode }}</div>
                  </div>
                </div>

                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Descripción</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">{{ row.Dscription }}</div>
                  </div>
                </div>

                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Bodega</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">{{ row.WhsCode }}</div>
                  </div>
                </div>

                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Stock</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">{{ row.OnHand }}</div>
                  </div>
                </div>

                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Cantidad Solicitada</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">
                      {{ row.OpenQuantity }}
                    </div>
                  </div>
                </div>

                <div class="col-12 border-buttom" style="display: flex">
                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-titulo">Cantidad Pickeada</div>
                  </div>

                  <div style="width: 50%; display: flex; justify-content: center">
                    <div class="columna-descripcion">{{ row.Pickeada }}</div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>




        </div>
        <div class="col-12" style="display: flex;justify-content: end;">


          <button (click)="cerrar()" type="button" class="btn btn-danger" style="background: #293F68;border: 1px solid #293F68;margin-left: 5px;">Cerrar</button>

          <button (click)="guardarPicking()" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">Guardar
            <i class="fa-solid fa-plus"></i>
          </button>

          <button *ngIf="ESTADO_WMS == 'AS'" (click)="enAprobacion()" type="button" class="btn btn-danger" style="background: #66ac92;border: 1px solid #66ac92;margin-left: 5px;">En Aprobación
            <i class="fa-solid fa-check"></i>
          </button>


        </div>


      </div>
    </div>
  </mat-drawer-container>




</div>
